import StoredEntry from "../StoredEntry"
import EntryHelper from "../EntryHelper"
import Property, { BoolProperty, StringProperty } from "../Property"

export default class Counterparty extends StoredEntry {
  static MODEL = 'y7xn'

  static VAL_NAME = 'ww6c'
  static VAL_TODELETE = 'a3yq'

  static PROPS: Property[] = StoredEntry.PROPS.concat([
    new StringProperty(this.VAL_NAME), new BoolProperty(this.VAL_TODELETE)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get name(): string {return this.getValue(Counterparty.VAL_NAME) }
  get toDelete(): boolean { return this.getValue(Counterparty.VAL_TODELETE) }

  static new(value: any) {
    return EntryHelper.fillEntry(new Counterparty(), value)
  }
}