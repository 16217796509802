
import { defineComponent, ref, watch, computed, nextTick, onMounted } from 'vue'
import UIPopupController from './h/UIPopupController'

export default defineComponent({
  props: { 
    elementId: String,
    readonly: Boolean,
    alignment: String,
    autocomplete: String,
    spellcheck: Boolean,
    value: Object,
    variants: Function,
    decorator: Function,
  },
  emits: ['update:value'],
  setup(props, { emit }) {

    // General

    const alignmentStyle = computed(() => `text-align: ${props.alignment};`)
    const fieldStyle = ref('')

    const updateFieldStyle = () => {
      if (props.decorator) {
        fieldStyle.value = props.decorator(currentValue.value)
      }
    }

    // Value

    const currentValue = ref(props.value)
    const valueText = ref((props.value && props.value.text) || '')

    const setCurrentValue = (value: any, notify: boolean) => {
      currentValue.value = value
      valueText.value = (value) ? value.text : ''
      if (notify) {
        if (value !== props.value) {
          emit('update:value', value)
        }
      }
    }

    const defineCurrentValue = () => {

      const curText = (currentValue.value) ? currentValue.value.text : ''
      const newText = valueText.value || ''
      if (newText === curText) {
        return
      }

      const found = pickerAllItems.value.find(it => it.text === newText)
      if (found) {
        setCurrentValue(found, true)
      } else {
        setCurrentValue({ text: newText }, true)
      }
    }

    watch(() => props.value, newValue => {
      setCurrentValue(newValue, false)
      updateFieldStyle()
    })

    // Picker

    const pickerPopupRef = ref({ show: false })
    const showPickerElement = () => { UIPopupController.showPopup(pickerPopupRef.value) }
    const hidePickerElement = () => { UIPopupController.hidePopup(pickerPopupRef.value) }

    const pickerVisible = computed(() => pickerPopupRef.value.show)
    const pickerIsLoading = ref(false)
    const pickerAllItems = ref<any[]>([])
    const pickerFilter = ref('')

    const pickerFiltredItems = computed(() => {
      if (pickerFilter.value) {
        let lcFilter = pickerFilter.value.toLowerCase()
        return pickerAllItems.value.filter(it => it.text.toLowerCase().includes(lcFilter))
      } else {
        return pickerAllItems.value
      }
    })

    const pickerSelectedIndex = ref(-1)

    const showPicker = () => {
      if (!props.variants) return

      pickerFilter.value = ''
      pickerSelectedIndex.value = -1
      pickerIsLoading.value = true
      showPickerElement()

      props.variants((vars: any[]) => {
        pickerAllItems.value = vars
        pickerIsLoading.value = false
      })
    }

    const hidePicker = () => {
      hidePickerElement()
    }

    const pickerScrollIntoView = () => {
      nextTick(() => {
        const element = document.querySelectorAll('.picker .picker-item')[pickerSelectedIndex.value]
        if (element) {
          element.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
        }
      })
    }

    // Event

    const handleOnGetFocus = () => {
      if (!pickerVisible.value) {
        showPicker()
      }
    }

    const handleOnLostFocus = () => {
      defineCurrentValue()
      hidePicker()
    }

    const handleOnMouseDown = () => {
      if (pickerVisible.value) {
        hidePicker()
      } else {
        showPicker()
      }
    }

    const handleOnMouseSelect = (item: any) => {
      setCurrentValue(item, true)
      hidePicker()
    }

    const handleOnInputText = () => {
      pickerFilter.value = valueText.value
    }

    const handleOnKeyboardDown = () => {
      if (pickerSelectedIndex.value < pickerFiltredItems.value.length - 1) {
        pickerSelectedIndex.value++
        pickerScrollIntoView()
      }
    }

    const handleOnKeyboardUp = () => {
      if (pickerSelectedIndex.value > 0) {
        pickerSelectedIndex.value--
        pickerScrollIntoView()
      }
    }

    const handleOnKeyboardEnter = () => {
      if (pickerSelectedIndex.value >= 0 && pickerSelectedIndex.value < pickerFiltredItems.value.length) {
        setCurrentValue(pickerFiltredItems.value[pickerSelectedIndex.value], true)
        hidePicker()
      }
    }

    const handleOnKeyboardEcs = () => {
      hidePicker()
    }

    // Other

    onMounted(() => {
      setCurrentValue(props.value, false)
      updateFieldStyle()
    })

    return {
      alignmentStyle,
      valueText,
      fieldStyle,
      pickerVisible,
      pickerIsLoading,
      pickerItems: pickerFiltredItems,
      pickerSelectedIndex,
      onFocus: handleOnGetFocus,
      onBlur: handleOnLostFocus,
      onMouseDown: handleOnMouseDown,
      onMouseSelect: handleOnMouseSelect,
      onInputText: handleOnInputText,
      onKeyboardDown: handleOnKeyboardDown,
      onKeyboardUp: handleOnKeyboardUp,
      onKeyboardEnter: handleOnKeyboardEnter,
      onKeyboardEsc: handleOnKeyboardEcs,
    }
  }
})
