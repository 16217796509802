import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "link-disable"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.enable)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: "link-enable",
        to: _ctx.path
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.name), 1)
        ]),
        _: 1
      }, 8, ["to"]))
    : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.name), 1))
}