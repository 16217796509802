import RegistryRecord from "./RegistryRecord"
import EntryHelper from "../EntryHelper"

// Удалить
export default class StorageRecord extends RegistryRecord {
  static MODEL = 'fx0x'

  static VAL_DOC_MODEL = 'z1yj'
  static VAL_DOC_ID = 'rm3x'
  static VAL_STORAGE_ID = 'bq8g'
  static VAL_INVENTORY_ID = 'o2df'
  static VAL_MEASURE_ID = 'ax3p'
  static VAL_AMOUNT = 's0ho'
  static VAL_SUM = 'st6u'
  static VAL_ORDER_ID = 't8fu'

  constructor(data?: any) {
    super(data)
  }

  get docModel(): string { return this.getValue(StorageRecord.VAL_DOC_MODEL) }
  get docId(): string { return this.getValue(StorageRecord.VAL_DOC_ID) }
  get storageId(): string { return this.getValue(StorageRecord.VAL_STORAGE_ID) }
  get inventoryId(): string { return this.getValue(StorageRecord.VAL_INVENTORY_ID) }
  get measureId(): string { return this.getValue(StorageRecord.VAL_MEASURE_ID) }
  get amount(): number { return this.getValue(StorageRecord.VAL_AMOUNT) }
  get sum(): number { return this.getValue(StorageRecord.VAL_SUM) }
  get orderId(): string { return this.getValue(StorageRecord.VAL_ORDER_ID) }

  static new(value: any): StorageRecord {
    return EntryHelper.fillEntry(new StorageRecord(), value)
  }
}