import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-709457a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions-table" }
const _hoisted_2 = { class: "action-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_UIPanel = _resolveComponent("UIPanel")!
  const _component_DefaultPage = _resolveComponent("DefaultPage")!

  return (_openBlock(), _createBlock(_component_DefaultPage, null, {
    default: _withCtx(() => [
      _createVNode(_component_UIPanel, { title: "Операции" }, {
        default: _withCtx(() => [
          _createElementVNode("table", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.operations, (op) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: op.name,
                class: "artion-row"
              }, [
                _createElementVNode("td", _hoisted_2, _toDisplayString(op.name), 1),
                _createElementVNode("td", null, [
                  _createVNode(_component_UIButton, {
                    onClick: ($event: any) => (_ctx.performOperation(op.key)),
                    disabled: _ctx.states.has(op.key)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Выполнить")
                    ]),
                    _: 2
                  }, 1032, ["onClick", "disabled"])
                ]),
                _createElementVNode("td", null, _toDisplayString(_ctx.states.get(op.key)), 1)
              ]))
            }), 128))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logs, (log) => {
            return (_openBlock(), _createElementBlock("div", {
              key: log.id,
              class: "log-row"
            }, [
              _createElementVNode("div", {
                class: _normalizeClass((log.warning) ? 'log-item-warning' : '')
              }, _toDisplayString(log.text), 3)
            ]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}