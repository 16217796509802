
export const COUNTERPARTY_EDIT = 'q2pm'
export const EMPLOYEE_EDIT = 'o4rj'
export const EQUIPMENT_EDIT = 'ov0r'
export const INVENTORY_EDIT = 'x9cn'
export const MEASURE_EDIT = 'yu7g'
export const PLACE_EDIT = 'p9qp'
export const STORAGE_EDIT = 'yj5d'

export const PURCHASE_ORDER_EDIT = 'd0mu'
export const CLOSING_ORDER_EDIT = 'hf2f'
export const DELIVERY_INVENTORY_EDIT = 'e4sn'
export const TRANSFER_INVENTORY_EDIT = 'eo5d'
export const WRITEOFF_INVENTORY_EDIT = 'h6zp'

export const CHANGE_ORDER_STATUS_AGREED = 'j6om'
export const CHANGE_ORDER_STATUS_APPROVED = 'vr8o'
export const CHANGE_ORDER_STATUS_CANCELED = 'pk0p'

export const SETTINGS = 'kwd5'
export const OPERATIONS = 'lb1p'

export const ADMINISTRATOR = 'e2ej'
