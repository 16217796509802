
import { defineComponent, ref, computed, onBeforeUnmount } from 'vue'
import UIButton from './UIButton.vue'
import UIPopupController from './h/UIPopupController'

export default defineComponent({
  components: { UIButton },
  props: {
    title: {
      type: String,
      required: true
    },
    buttonStyle: {
      type: String,
      default: 'bordered',
      validator: (value: string) => ['plain', 'bordered', 'submit'].includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  setup() {

    const pickerPopupRef = ref({ show: false })

    const showPicker = () => { 
      UIPopupController.showPopup(pickerPopupRef.value)
      document.addEventListener('click', handleGeneralClick)
    }

    const hidePicker = () => { 
      UIPopupController.hidePopup(pickerPopupRef.value)
      document.removeEventListener('click', handleGeneralClick)
    }

    const pickerVisible = computed(() => pickerPopupRef.value.show)

    const handleOnClick = () => {
      if (pickerVisible.value) {
        hidePicker()
      } else {
        showPicker()
      }
    }

    const component = ref<HTMLElement | null>(null)
    
    const handleGeneralClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (component.value && !component.value.contains(target)) { // Нажатие за пределами элемента
        hidePicker()
      }
      if (target.tagName === 'LI' && target.closest('.picker')) { // Нажатие на элемент списка
        hidePicker()
      }
    }
    
    onBeforeUnmount(() => {
      document.removeEventListener('click', handleGeneralClick)
    })

    return {
      component,
      pickerVisible,
      onClick: handleOnClick
    }
  }
})
