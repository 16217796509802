import DataManager from '../data/DataManager';
import { Employee } from '../model/Types';
import Cookies from '@/lib/cookie/Cookies';

export default class SafeManager {

  private _dm: DataManager
  private _emp?: Employee = undefined

  constructor(dataManager: DataManager) {
    this._dm = dataManager
  }

  login(login: string, password: string, callback: (_: boolean) => void) {
    this._dm.getEntriesWhere(Employee.MODEL, [[ Employee.VAL_LOGIN, '==', login.toLowerCase() ]])
      .then(list => {
        const emp = list.find(() => true)
        if (emp && emp.password === password) {
          this._emp = emp
          Cookies.set('empid', emp.id, 30)
          callback(true)
        } else {
          callback(false)
        }
      })
      .catch(() => callback(false))
  }
  
  logout() {
    this._emp = undefined
    Cookies.del('empid')
  }
  
  isLoggedIn(): boolean {
    return (this.getCurrentEmployeeId()) ? true : false
  }

  getCurrentEmployeeId(): string | undefined {
    return Cookies.get('empid')
  }

  getCurrentEmployee(callback: (_: Employee | undefined) => void) {
    const empId = this.getCurrentEmployeeId()
    if (empId) {
      if (this._emp && this._emp.id === empId) {
        callback(this._emp)
      } else {
        this._dm.getEntryById(Employee.MODEL, empId)
          .then(emp => {
            this._emp = emp
            callback(emp)
          })
      }
    } else {
      callback(undefined)
    }
  }

  getCurrentPermissions(callback: (_: string[]) => void) {
    this.getCurrentEmployee(emp => callback(emp ? emp.permissions : []))
  }
}