import AppContext from '@/core/AppContext';
import { Employee, Equipment, HistoryRecord, OrderRecord, Place, PurchaseOrder, PurchaseOrderRow, PurchaseOrderStatus } from "@/core/model/Types";
import DataRequest from "@/core/data/DataRequest";
import HistoryBuilder from "@/lib/data/HistoryBuilder";
import PurchaseOrderStatusM from '@/lib/util/PurchaseOrderStatusM';
import EntryMap from '@/lib/data/EntryMap';
import DataManager from '@/core/data/DataManager';
import { DateUtils } from '@/lib/util/Utils';

export default class PurchaseOrderHelper {

  private static get dm(): DataManager { return AppContext.getDataManager() }

  static save(doc: PurchaseOrder, rows: PurchaseOrderRow[], entryMap: EntryMap): Promise<boolean> {
    return this.hold(doc, rows, entryMap)
  }

  private static hold(doc: PurchaseOrder, rows: PurchaseOrderRow[], entryMap: EntryMap): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const emptyValues = []

      if (DateUtils.isEmpty(doc.date)) emptyValues.push('Дата')
      if (!doc.number) emptyValues.push('Номер')        
      if (!doc.placeId) emptyValues.push('Участок')
      if (!doc.declarerId) emptyValues.push('Заявитель')
      if (!doc.status) emptyValues.push('Статус')

      rows.forEach((row, index) => {
        if (!row.inventoryName) emptyValues.push(`Наименование (Строка ${index + 1})`)
        if (!row.equipmentId) emptyValues.push(`Оборудование (Строка ${index + 1})`)
        if (!row.measure) emptyValues.push(`Ед. (Строка ${index + 1})`)
        if (Number(row.amount) <= 0) emptyValues.push(`Количество (Строка ${index + 1})`)
      })

      if (emptyValues.length > 0) {
        reject(`Не указаны значения: "${emptyValues.join('", "')}"`)
        return
      }

      this.holdPrepare(doc, rows, entryMap)
        .then(v => resolve(v))
        .catch(msg => reject(msg))
    })
  }

  private static async holdPrepare(doc: PurchaseOrder, rows: PurchaseOrderRow[], entryMap: EntryMap): Promise<boolean> {

    const employeeId = AppContext.getSafeManager().getCurrentEmployeeId()!
    const history = new HistoryBuilder(employeeId, PurchaseOrder.MODEL, doc.id)

    const request = new DataRequest()
    request.addGetEntriesByIds(PurchaseOrder.MODEL, [doc.id], 'doc')
    request.addGetEntriesWhere(PurchaseOrderRow.MODEL, [
      [ PurchaseOrderRow.VAL_DOC_ID, '==', doc.id ]
    ], 'rows')

    const results = await this.dm.send(request)

    const oldDoc = results.getValue('doc').find(() => true) as PurchaseOrder | undefined
    const oldRows = ((results.getValue('rows') || []) as PurchaseOrderRow[]).sort((a, b) => a.index - b.index)
    
    // Создадим историю изменений

    let changeStatus = false

    if (oldDoc) {

      if (DateUtils.getTime(oldDoc.date) !== DateUtils.getTime(doc.date)) {
        history.pushChange('Дата', DateUtils.formatedDate(oldDoc.date), DateUtils.formatedDate(doc.date))
      }
      if ((oldDoc.number || '') !== (doc.number || '')) {
        history.pushChange('Номер', oldDoc.number, doc.number)
      }
      if ((oldDoc.placeId || '') !== (doc.placeId || '')) {
        history.pushChange('Участок', entryMap.getDisplayName(Place.MODEL, oldDoc.placeId), entryMap.getDisplayName(Place.MODEL, doc.placeId))
      }
      if ((oldDoc.declarerId || '') !== (doc.declarerId || '')) {
        history.pushChange('Заявитель', entryMap.getDisplayName(Employee.MODEL, oldDoc.declarerId), entryMap.getDisplayName(Employee.MODEL, doc.declarerId))
      }
      if ((oldDoc.executorId || '') !== (doc.executorId || '')) {
        history.pushChange('Исполнитель', entryMap.getDisplayName(Employee.MODEL, oldDoc.executorId), entryMap.getDisplayName(Employee.MODEL, doc.executorId))
      }
      if (DateUtils.getTime(oldDoc.executorDate) !== DateUtils.getTime(doc.executorDate)) {
        history.pushChange('Дата исполнителя', DateUtils.formatedDate(oldDoc.executorDate), DateUtils.formatedDate(doc.executorDate))
      }
      if ((oldDoc.status || '') !== (doc.status || '')) {
        history.pushChange('Статус', PurchaseOrderStatusM.getName(oldDoc.status)!, PurchaseOrderStatusM.getName(doc.status)!)
        changeStatus = true
      }
      if ((oldDoc.comment || '') !== (doc.comment || '')) {
        history.pushChange('Коментарий', oldDoc.comment, doc.comment)
      }

      const rowsCount = Math.max(oldRows.length, rows.length)
      for (let i = 0; i < rowsCount; i++) {
        const oldRow = (i < oldRows.length) ? oldRows[i] : undefined
        const newRow = (i < rows.length) ? rows[i] : undefined
        history.pushTest(`Строка ${i + 1}`, this.toHistoryString(oldRow, entryMap), this.toHistoryString(newRow, entryMap))
      }
    } 
    else {
      history.pushDetails('Создание документа')
    }

    // Поправим данные документа

    rows.forEach((row, index) => {
      row.setValue(PurchaseOrderRow.VAL_DOC_ID, doc.id)
      row.setValue(PurchaseOrderRow.VAL_INDEX, index)
    })

    if (changeStatus) {
      doc.setValue(PurchaseOrder.VAL_STATUS_AUTHOR_ID, employeeId)
    } else {
      doc.setValue(PurchaseOrder.VAL_STATUS_AUTHOR_ID, oldDoc?.statusAuthorId || employeeId)
    }

    if (history.isEmpty()) {
      doc.setValue(PurchaseOrder.VAL_AUTHOR_ID, oldDoc?.authorId || employeeId)
    } else {
      doc.setValue(PurchaseOrder.VAL_AUTHOR_ID, employeeId)
    }

    // Запишем в базу данных

    return this.holdSave(doc, rows, history)
  }

  private static async holdSave(doc: PurchaseOrder, rows: PurchaseOrderRow[], history: HistoryBuilder): Promise<boolean> {

    // Обработка

    const createActions = !doc.toDelete
    const request = new DataRequest()

    // Сам документ

    request.addPutEntries(PurchaseOrder.MODEL, [doc])
    
    request.addDelEntriesWhere(PurchaseOrderRow.MODEL, [
      [ PurchaseOrderRow.VAL_DOC_ID, '==', doc.id ]
    ])
    request.addPutEntries(PurchaseOrderRow.MODEL, rows)

    // Регистр заявок
    
    request.addDelEntriesWhere(OrderRecord.MODEL, [
      [ OrderRecord.VAL_DOC_MODEL, '==', PurchaseOrder.MODEL ],
      [ OrderRecord.VAL_DOC_ID, '==', doc.id ]
    ])
    if (createActions && doc.status === PurchaseOrderStatus.APPROVED) {
      request.addPutEntries(OrderRecord.MODEL, rows.map(row => OrderRecord.new({
        [OrderRecord.VAL_DATE]: doc.date,
        [OrderRecord.VAL_DOC_MODEL]: PurchaseOrder.MODEL,
        [OrderRecord.VAL_DOC_ID]: doc.id,
        [OrderRecord.VAL_ORDER_ID]: doc.id,
        [OrderRecord.VAL_ORDER_ROW_ID]: row.id,
        [OrderRecord.VAL_AMOUNT]: row.amount,
      })))
    }
    
    // История

    request.addPutEntries(HistoryRecord.MODEL, history.getList())

    // Запись
    
    await this.dm.send(request)
    return true
  }

  private static toHistoryString(row: PurchaseOrderRow | undefined, entryMap: EntryMap) {
    if (row) {
      const vals = []
      vals.push(row.inventoryName || '')
      vals.push(row.inventoryDetails || '')
      vals.push(entryMap.getDisplayName(Equipment.MODEL, row.equipmentId))
      vals.push(row.measure)
      vals.push(row.amount || 0)
      vals.push(row.comment || '')
      return vals.join(', ')
    } else {
      return ''
    }
  }
}