
export default class UIWidth {

  static fixed(v: number): UIFixedWidth { return new UIFixedWidth(v) }
  static pct(v: number): UIPercentWidth { return new UIPercentWidth(v) }
  static flex(v: number) : UIFlexWidth { return new UIFlexWidth(v) }

  static getTableWidths(widths: UIWidth[], tableWidth: number): string[] {

    // Вычтем fixed и percent значения
    
    let leftTableWidth = tableWidth
    for (const w of widths) {
      if (w instanceof UIFixedWidth) {
        leftTableWidth -= w.value
      } else if (w instanceof UIPercentWidth) {
        leftTableWidth -= tableWidth * (w.value / 100)
      }
    }

    // Посчитаем elastic

    let elasticSum = 0

    for (const w of widths) {
      if (w instanceof UIFlexWidth) {
        elasticSum += w.value
      }
    }

    const elasticWidth = Math.max(0, leftTableWidth / elasticSum)

    // Получим результат

    return widths.map(w => {
      if (w instanceof UIFixedWidth) {
        return `${w.value}px`
      } else if (w instanceof UIPercentWidth) {
        return `${tableWidth * (w.value / 100)}px`
      } else if (w instanceof UIFlexWidth) {
        return `${elasticWidth * w.value}px`
      } else {
        return '0px'
      }
    })
  }
}

export class UIFixedWidth extends UIWidth {

  readonly value: number

  constructor(v: number) {
    super()
    this.value = v
  }
}

export class UIPercentWidth extends UIWidth {

  readonly value: number

  constructor(v: number) {
    super()
    this.value = v
  }
}

export class UIFlexWidth extends UIWidth {

  readonly value: number

  constructor(v: number) {
    super()
    this.value = v
  }
}