import DocumentRow from "./DocumentRow"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, NumberProperty } from "../Property"
import Party from "../unit/Party"

export default class TransferInventoryRow extends DocumentRow {
  static MODEL = 's9ok'

  static VAL_PARTY_ID = 'a6gd'
  static VAL_AMOUNT = 'ju2q'
  // static VAL_INVENTORY_ID = 'd3vg' // Удалить
  // static VAL_MEASURE_ID = 'tr4e' // Удалить
  // static VAL_SUM = 'rj7i' // Удалить
  // static VAL_ORDER_ID = 'jo3q' // Удалить

  static PROPS: Property[] = DocumentRow.PROPS.concat([
    new IDProperty(this.VAL_PARTY_ID, Party.MODEL), new NumberProperty(this.VAL_AMOUNT),
    // new IDProperty(this.VAL_INVENTORY_ID, Inventory.MODEL), new IDProperty(this.VAL_MEASURE_ID, Measure.MODEL),
    // new NumberProperty(this.VAL_SUM), new IDProperty(this.VAL_ORDER_ID, PurchaseOrder.MODEL)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get partyId(): string { return this.getValue(TransferInventoryRow.VAL_PARTY_ID) }
  get amount(): number { return this.getValue(TransferInventoryRow.VAL_AMOUNT) }
  // get inventoryId(): string { return this.getValue(TransferInventoryRow.VAL_INVENTORY_ID) }
  // get measureId(): string { return this.getValue(TransferInventoryRow.VAL_MEASURE_ID) }
  // get sum(): number { return this.getValue(TransferInventoryRow.VAL_SUM) }
  // get orderId(): string { return this.getValue(TransferInventoryRow.VAL_ORDER_ID) }

  static new(value: any): TransferInventoryRow {
    return EntryHelper.fillEntry(new TransferInventoryRow(), value)
  }
}