
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    handleOverlayClick() {
      this.$emit('onOverlayClick')
    },
    handleContentClick() {
      // Ничего не делаем
    }
  }
})
