import AppContext from '@/core/AppContext';
import { Counterparty, DeliveryInventory, DeliveryInventoryRow, HistoryRecord, Party, PartyRecord, PurchaseOrder, Storage } from "@/core/model/Types";
import DataRequest from "@/core/data/DataRequest";
import HistoryBuilder from "@/lib/data/HistoryBuilder";
import EntryMap from '@/lib/data/EntryMap';
import DataManager from '@/core/data/DataManager';
import { DateUtils } from '@/lib/util/Utils';

export default class DeliveryInventoryHelper {

  private static get dm(): DataManager { return AppContext.getDataManager() }

  static save(doc: DeliveryInventory, rows: DeliveryInventoryRow[], slaveObjects: { [name: string]: any[] }, entryMap: EntryMap): Promise<boolean> {
    return this.hold(doc, rows, slaveObjects, entryMap)
  }

  private static hold(doc: DeliveryInventory, rows: DeliveryInventoryRow[], slaveObjects: { [name: string]: any[] }, entryMap: EntryMap): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const emptyValues = []

      if (DateUtils.isEmpty(doc.date)) emptyValues.push('Дата')
      if (!doc.number) emptyValues.push('Номер')
      if (!doc.storageId) emptyValues.push('Склад')
      
      rows.map((row, index) => {
        if (!row.inventory) emptyValues.push(`Наименование (Строка ${index + 1})`)
        if (!row.measure) emptyValues.push(`Ед. (Строка ${index + 1})`)
        if (Number(row.amount) <= 0) emptyValues.push(`Количество (Строка ${index + 1})`)
      })
  
      if (emptyValues.length > 0) {
        reject(`Не указаны значения: "${emptyValues.join('", "')}"`)
        return
      }

      this.holdPrepare(doc, rows, slaveObjects, entryMap)
        .then(v => resolve(v))
        .catch(msg => reject(msg))
    })
  }

  private static async holdPrepare(doc: DeliveryInventory, rows: DeliveryInventoryRow[], slaveObjects: { [name: string]: any[] }, entryMap: EntryMap): Promise<boolean> {

    const employeeId = AppContext.getSafeManager().getCurrentEmployeeId()!
    const history = new HistoryBuilder(employeeId, DeliveryInventory.MODEL, doc.id)

    const request = new DataRequest()
    request.addGetEntriesByIds(DeliveryInventory.MODEL, [doc.id], 'doc')
    request.addGetEntriesWhere(DeliveryInventoryRow.MODEL, [
      [ DeliveryInventoryRow.VAL_DOC_ID, '==', doc.id ]
    ], 'rows')

    const results = await this.dm.send(request)

    const oldDoc = results.getValue('doc').find(() => true) as DeliveryInventory | undefined
    const oldRows = ((results.getValue('rows') || []) as DeliveryInventoryRow[]).sort((a, b) => a.index - b.index)

    // Создадим историю изменений

    if (oldDoc) {

      if (DateUtils.getTime(oldDoc.date) !== DateUtils.getTime(doc.date)) {
        history.pushChange('Дата', DateUtils.formatedDate(oldDoc.date), DateUtils.formatedDate(doc.date))
      }
      if ((oldDoc.number || '') !== (doc.number || '')) {
        history.pushChange('Номер', oldDoc.number, doc.number)
      }
      if ((oldDoc.storageId || '') !== (doc.storageId || '')) {
        history.pushChange('Склад', entryMap.getDisplayName(Storage.MODEL, oldDoc.storageId), entryMap.getDisplayName(Storage.MODEL, doc.storageId))
      }
      if ((oldDoc.providerId || '') !== (doc.providerId || '')) {
        history.pushChange('Поставщик', entryMap.getDisplayName(Counterparty.MODEL, oldDoc.providerId), this.getDisplayName(slaveObjects, entryMap, Counterparty.MODEL, doc.providerId))
      }
      if ((oldDoc.comment || '') !== (doc.comment || '')) {
        history.pushChange('Коментарий', oldDoc.comment, doc.comment)
      }

      const rowsCount = Math.max(oldRows.length, rows.length)
      for (let i = 0; i < rowsCount; i++) {
        const oldRow = (i < oldRows.length) ? oldRows[i] : undefined
        const newRow = (i < rows.length) ? rows[i] : undefined
        history.pushTest(`Строка ${i + 1}`, this.toHistoryString(oldRow, slaveObjects, entryMap), this.toHistoryString(newRow, slaveObjects, entryMap))
      }
    } 
    else {
      history.pushDetails('Создание документа')
    }

    // Поправим данные документа

    rows.forEach((row, index) => {
      row.setValue(DeliveryInventoryRow.VAL_DOC_ID, doc.id)
      row.setValue(DeliveryInventoryRow.VAL_INDEX, index)
    })

    const total = rows.reduce((s, r) => s + (r.sum || 0), 0)
    doc.setValue(DeliveryInventory.VAL_TOTAL, total)

    if (history.isEmpty()) {
      doc.setValue(DeliveryInventory.VAL_AUTHOR_ID, oldDoc?.authorId || employeeId)
    } else {
      doc.setValue(DeliveryInventory.VAL_AUTHOR_ID, employeeId)
    }

    // Запишем в базу данных

    return this.holdSave(doc, rows, slaveObjects, history, entryMap)
  }

  private static async holdSave(doc: DeliveryInventory, rows: DeliveryInventoryRow[], slaveObjects: { [name: string]: any[] }, history: HistoryBuilder, _entryMap: EntryMap): Promise<boolean> {

    // Обработка

    const createActions = !doc.toDelete
    const request = new DataRequest()

    // Сам документ

    request.addPutEntries(DeliveryInventory.MODEL, [doc])

    request.addDelEntriesWhere(DeliveryInventoryRow.MODEL, [
      [ DeliveryInventoryRow.VAL_DOC_ID, '==', doc.id ]
    ])
    request.addPutEntries(DeliveryInventoryRow.MODEL, rows)

    // Партии

    const parties = rows.map(row => Party.new({
      [Party.VAL_ID]: row.id, // id партии == id row (для сохранения связей при обновлении документа)
      [Party.VAL_DOC_MODEL]: DeliveryInventory.MODEL,
      [Party.VAL_DOC_ID]: doc.id,
      [Party.VAL_INVENTORY]: row.inventory,
      [Party.VAL_MEASURE]: row.measure,
      [Party.VAL_AMOUNT]: row.amount,
      [Party.VAL_SUM]: row.sum,
      [Party.VAL_ORDER_ID]: row.orderId,
    }))

    request.addDelEntriesWhere(Party.MODEL, [
      [ Party.VAL_DOC_MODEL, '==', DeliveryInventory.MODEL ],
      [ Party.VAL_DOC_ID, '==', doc.id ]
    ])
    if (createActions) {
      request.addPutEntries(Party.MODEL, parties)
    }

    // Регистр партий

    request.addDelEntriesWhere(PartyRecord.MODEL, [
      [ PartyRecord.VAL_DOC_MODEL, '==', DeliveryInventory.MODEL ],
      [ PartyRecord.VAL_DOC_ID, '==', doc.id ]
    ])
    if (createActions) {
      request.addPutEntries(PartyRecord.MODEL, parties.map(party => PartyRecord.new({
        [PartyRecord.VAL_DATE]: doc.date,
        [PartyRecord.VAL_DOC_MODEL]: DeliveryInventory.MODEL,
        [PartyRecord.VAL_DOC_ID]: doc.id,
        [PartyRecord.VAL_STORAGE_ID]: doc.storageId,
        [PartyRecord.VAL_PARTY_ID]: party.id,
        [PartyRecord.VAL_AMOUNT]: party.amount
      })))
    }

    // История

    request.addPutEntries(HistoryRecord.MODEL, history.getList())

    // Связанные объекты
    
    for (const model in slaveObjects) {
      request.addPutEntries(model, slaveObjects[model])
    }

    // Запись

    await this.dm.send(request)
    return true
  }

  private static toHistoryString(row: DeliveryInventoryRow | undefined, slaveObjects: { [name: string]: any[] }, entryMap: EntryMap) {
    if (row) {
      const vals = []
      vals.push(row.inventory)
      vals.push(row.measure)
      vals.push(row.amount || 0)
      vals.push(row.sum || 0)
      vals.push(entryMap.getDisplayName(PurchaseOrder.MODEL, row.orderId))
      return vals.join(', ')
    } else {
      return ''
    }
  }

  private static getDisplayName(slaveObjects: { [name: string]: any[] }, entryMap: EntryMap, model: string, id: string): any {
    if (id) {
      const slaveObject = slaveObjects[model]?.find(it => it.id === id)
      if (slaveObject) return slaveObject.name
      const storedEntry = entryMap.getEntryById(model, id)
      if (storedEntry) return storedEntry.name
      return `<(${model}:${id})>`
    } else {
      return ''
    }
  }
}