
import { defineComponent } from 'vue';
import DefaultPage from '@/use/components/DefaultPage.vue';
import { UIPanel, UIToolbar, UIButton, UIMultipleButton, UIDateField, UITextField, UIEntryField, UITextArea, UIAlertView } from '@/use/base/Types';
import { UITable, UITableRow, UITableCell, UITextCell, UIEntryCell, UIImageButton } from '@/use/base/Types';
import ToDeleteButton from '@/use/components/ToDeleteButton.vue';
import AppContext from '@/core/AppContext';
import * as Permission from '@/core/safe/Permissions'
import * as SettingsKey from '@/core/settings/SettingsKeys'
import { Party, PartyRecord, PurchaseOrder, Storage } from '@/core/model/Types';
import { TransferInventory, TransferInventoryRow } from '@/core/model/Types';
import DataRequest from '@/core/data/DataRequest';
import ArrayMaster from '@/lib/util/ArrayMaster'
import { PDFBuilder, PDFTable } from '@/lib/pdf/PDFTypes';
import TransferInventoryHelper from './TransferInventoryHelper';
import EntryLoader from '@/lib/data/EntryLoader';
import EntryMap from '@/lib/data/EntryMap';
import { DateUtils, EntryUtils, NumberUtils, StringUtils, TableUtils } from '@/lib/util/Utils';

export default defineComponent({
  components: {
    DefaultPage, 
    UIPanel, UIToolbar, UIButton, UIMultipleButton, UIDateField, UITextField, UIEntryField, UITextArea, UIAlertView,
    UITable, UITableRow, UITableCell, UITextCell, UIEntryCell, UIImageButton,
    ToDeleteButton
  },
  data() {
    return {
      grantedEdit: false,
      loader: new EntryLoader(),
      docId: '',
      date: new Date(),
      number: '',
      sourceStorage: undefined,
      targetStorage: undefined,
      comment: '',
      tableWidths: TableUtils.headers(m => [m.INDEX, m.flex(1), m.MEASURE, m.AMOUNT, m.ACTION]),
      tableHeaders: ['№', 'Партия', 'Ед.', 'Кол-во', 'X'],
      tableRows: new Array<any>(),
      toDelete: false,
      isLoading: false,
      alert: undefined as any,
    }
  },
  watch: {
    tableRows: {
      deep: true,
      handler(newValue) {
        if (!TableUtils.isContainsEmptyRow(newValue)) {
          this.tableRows.push({ _key: StringUtils.getUUID() })
        }
      },
    }
  },
  computed: {
  },
  methods: {
    table_getMeasure(row: any): string {
      const partyId = EntryUtils.getId(row.party)
      if (!partyId) return ''
      const party = this.loader.data.getEntryById(Party.MODEL, partyId)
      if (!party) return ''
      return party.measure
    },
    table_handleRemoveRow(rowIndex: number) {
      this.tableRows.splice(rowIndex, 1)
    },
    getPartyDisplayItem(id: string): any {
      if (id) {
        const party = this.loader.data.getEntryById(Party.MODEL, id) as Party | undefined
        if (party) {
          const orderName = this.loader.data.getDisplayName(PurchaseOrder.MODEL, party.orderId)
          return { id: id, name: `${party.inventory} / ${orderName} / ${party.amount} ${party.measure}` }
        } else {
          return { id: id, name: this.loader.data.getDisplayName(Party.MODEL, id) } // Будет возвращен ошибочный указатель
        }
      } else {
        return undefined
      }
    },
    configureView(doc: TransferInventory | undefined, rows: TransferInventoryRow[]) {
      this.date = (doc) ? doc.date : new Date()
      this.number = (doc) ? doc.number : AppContext.getSettingsManager().getNextNumber(TransferInventory.MODEL)
      this.sourceStorage = (doc) ? this.loader.data.getDisplayItem(Storage.MODEL, doc.sourceStorageId) : undefined
      this.targetStorage = (doc) ? this.loader.data.getDisplayItem(Storage.MODEL, doc.targetStorageId) : undefined
      this.comment = (doc) ? doc.comment : ''
      this.tableRows = rows.sort((a, b) => a.index - b.index).map(row => {
        return {
          _key: StringUtils.getUUID(),
          id: row.id,
          party: this.getPartyDisplayItem(row.partyId),
          amount: `${row.amount || 0}`,
        }
      })
      this.toDelete = (doc) ? Boolean(doc.toDelete) : false
    },
    reloadView() {

      AppContext.getSafeManager().getCurrentPermissions(perms => {
        this.grantedEdit = perms.includes(Permission.TRANSFER_INVENTORY_EDIT)
      })

      this.isLoading = true

      const request = new DataRequest()
      request.addGetAllEntries(Storage.MODEL, Storage.MODEL)

      this.loader.load(request)
        .then(() => {

          if (this.docId === 'new') {
            this.configureView(undefined, [])
          }
          else {
          
            const request = new DataRequest()
            request.addGetEntriesByIds(TransferInventory.MODEL, [this.docId], 'doc')
            request.addGetEntriesWhere(TransferInventoryRow.MODEL, [
              [ TransferInventoryRow.VAL_DOC_ID, '==', this.docId ]
            ], 'rows')

            return this.$dm.send(request)
              .then(results => {

                const doc = results.getValue('doc').find(() => true)
                if (!doc) throw new Error(`Документ (${this.docId}) не существует!`)

                const rows = (results.getValue('rows') || []) as TransferInventoryRow[]

                return this.loader.loadDetails(rows, [
                  { key: TransferInventoryRow.VAL_PARTY_ID, model: Party.MODEL },
                ])
                  .then(map => { 
                    return this.loader.loadDetails(map.getEntries(Party.MODEL), [
                      { key: Party.VAL_ORDER_ID, model: PurchaseOrder.MODEL }
                    ])
                      .then(() => { this.configureView(doc, rows) })
                  })
              })
          }
        })
        .catch(msg => {
          console.error(msg)
          this.$sm.goBack()
        })
        .finally(() => this.isLoading = false)
    },
    tableVariantsGetter(key: string, rowIndex: number, callback: any) { return this.getVariantsGetter(key, rowIndex, callback) },
    storageVariantsGetter(callback: any) { return this.getVariantsGetter('storage', 0, callback) },
    getVariantsGetter(key: string, rowIndex: number, callback: any) {
      return this.getVariantsPromise(key, rowIndex)
        .then(value => callback(value))
        .catch(() => callback(undefined))
    },
    getPromise(value: any): Promise<any> {
      return new Promise((resolve) => { resolve(value) })
    },
    getVariantsPromise(key: string, rowIndex: number): Promise<any> {

      if (key === 'storage') {
        return this.getPromise(this.loader.data.getDisplayItems(Storage.MODEL))
      }
      else if (key === 'party') {

        if (EntryUtils.isEmpty(this.sourceStorage)) return this.getPromise(undefined)

        return this.$dm.getEntriesWhere(PartyRecord.MODEL, [
          [ PartyRecord.VAL_DATE, '<', this.date ],
          [ PartyRecord.VAL_DOC_ID, '!=', this.docId ],
          [ PartyRecord.VAL_STORAGE_ID, '==', EntryUtils.getId(this.sourceStorage) ]
        ])
          .then(records => {
            const partyIds = new ArrayMaster(records)
              .map(it => { return { partyId: it.partyId, amount: it.amount }})
              .group(['partyId'], ['amount'])
              .filter(gr => gr.amount > 0)
              .map(gr => gr.partyId).get()

            return this.loader.loadEntriesByIds<Party>(Party.MODEL, partyIds)
              .then(parties => {
                return this.loader.loadDetails(parties, [
                  { key: Party.VAL_ORDER_ID, model: PurchaseOrder.MODEL }
                ])
                  .then(() => {
                    return new ArrayMaster(parties)
                      .map(it => this.getPartyDisplayItem(it.id))
                      .sortEntries().get()
                  })
              })
          })
      }
      else if (key === 'amount') {

        if (rowIndex >= this.tableRows.length) return this.getPromise(undefined)
        const row = this.tableRows[rowIndex]

        if (EntryUtils.isEmpty(this.sourceStorage)) return this.getPromise(undefined)
        if (EntryUtils.isEmpty(row.party)) return this.getPromise(undefined)

        return this.$dm.getEntriesWhere(PartyRecord.MODEL, [
          [ PartyRecord.VAL_DATE, '<', this.date ],
          [ PartyRecord.VAL_DOC_ID, '!=', this.docId ],
          [ PartyRecord.VAL_STORAGE_ID, '==', EntryUtils.getId(this.sourceStorage) ],
          [ PartyRecord.VAL_PARTY_ID, '==', EntryUtils.getId(row.party) ],
        ])
          .then(records => {
            const amount = new ArrayMaster(records)
              .map(it => it.amount).getSum()
            return ['' + amount]
          })
      }
      else {
        return this.getPromise(undefined)
      }
    },
    prepareSaveData(): any {

      let docId = (this.docId === 'new') ? this.$dm.newEntryId() : this.docId
      
      const doc = TransferInventory.new({
        [TransferInventory.VAL_ID]: docId,
        [TransferInventory.VAL_DATE]: this.date,
        [TransferInventory.VAL_NUMBER]: this.number,
        [TransferInventory.VAL_SOURCE_STORAGE_ID]: EntryUtils.getId(this.sourceStorage),
        [TransferInventory.VAL_TARGET_STORAGE_ID]: EntryUtils.getId(this.targetStorage),
        [TransferInventory.VAL_COMMENT]: this.comment,
        [TransferInventory.VAL_TODELETE]: this.toDelete
      })
      
      const rows = TableUtils.getFilledRows(this.tableRows).map(row => {
        return TransferInventoryRow.new({
          [TransferInventoryRow.VAL_ID]: row.id,
          [TransferInventoryRow.VAL_PARTY_ID]: EntryUtils.getId(row.party),
          [TransferInventoryRow.VAL_AMOUNT]: Number(row.amount)
        })
      })

      return { doc: doc, rows: rows }
    },
    handleOkClick() {
      const d = this.prepareSaveData()
      TransferInventoryHelper.save(d.doc, d.rows, this.loader.data as EntryMap)
        .then(() => {
          AppContext.getSettingsManager().registryLastNumber(TransferInventory.MODEL, this.number)
          this.$sm.goBack()
        })
        .catch(msg => this.alert = { title: 'Ошибка', text: msg })
    },
    handleSaveClick() {
      const d = this.prepareSaveData()
      TransferInventoryHelper.save(d.doc, d.rows, this.loader.data as EntryMap)
        .then(() => {
          if (this.docId === 'new') {
            AppContext.getSettingsManager().registryLastNumber(TransferInventory.MODEL, this.number)
            // Изменим url
            this.docId = d.doc.id
            this.$sm.goToObjectEditor(TransferInventory.MODEL, d.doc.id)
          }
          this.reloadView()
          this.alert = { text: 'Сохранено!' }
        })
        .catch(msg => this.alert = { title: 'Ошибка', text: msg })
    },
    handlePrintClick() {

      const printTableHeaders = TableUtils.headers(m => [
        { name: '№', width: m.INDEX, alignment: 'right' },
        { name: 'Наименование', width: m.flex(1.5) },
        { name: 'Заявка', width: m.flex(1) },
        { name: 'Ед.', width: m.MEASURE },
        { name: 'Кол-во', width: m.AMOUNT, alignment: 'right' },
      ])

      const table = new PDFTable()
      table.setColumnWidths(printTableHeaders.map(it => it.width))
      table.setColumnAligment(printTableHeaders.map(it => it.alignment || 'left'))
      table.setHeaders(printTableHeaders.map(it => it.name))
      TableUtils.getFilledRows(this.tableRows).forEach((row, i) => {
        const party = this.loader.data.getEntryById(Party.MODEL, EntryUtils.getId(row.party) || '')
        table.addRow([
          `${i + 1}`,
          party?.inventory,
          this.loader.data.getDisplayName(PurchaseOrder.MODEL, party?.orderId),
          party?.measure,
          NumberUtils.formatedNumber(row.amount),
        ])
      })

      const builder = new PDFBuilder('landscape')
      builder.addTitle(
        [ 'ПЕРЕМЕЩЕНИЕ', `№ ${this.number} от ${DateUtils.formatedDate(this.date)}` ],
        { margin: [ 0, 0, 0, 20 ] }
      )
      builder.addTable(table)
      builder.build()
    },
    handlePrint2Click() {

      const sm = AppContext.getSettingsManager()
      const printTableHeaders = TableUtils.headers(m => [
        { name: '№', width: m.INDEX, alignment: 'right' },
        { name: 'Наименование', width: m.flex(1.5) },
        { name: 'Заявка', width: m.flex(1) },
        { name: 'Ед.', width: m.MEASURE },
        { name: 'Кол-во', width: m.AMOUNT, alignment: 'right' },
      ])

      const table = new PDFTable()
      table.setColumnWidths(printTableHeaders.map(it => it.width))
      table.setColumnAligment(printTableHeaders.map(it => it.alignment || 'left'))
      table.setHeaders(printTableHeaders.map(it => it.name))
      TableUtils.getFilledRows(this.tableRows).forEach((row, i) => {
        const party = this.loader.data.getEntryById(Party.MODEL, EntryUtils.getId(row.party) || '')
        table.addRow([
          `${i + 1}`,
          party?.inventory,
          this.loader.data.getDisplayName(PurchaseOrder.MODEL, party?.orderId),
          party?.measure,
          NumberUtils.formatedNumber(row.amount)
        ])
      })

      const builder = new PDFBuilder('landscape')
      builder.addOppositeColumns(
        [ sm.getParam(SettingsKey.COMPANY_FULL_NAME), 'г.Павлодар' ],
        [ sm.getParam(SettingsKey.COMPANY_FULL_NAME), 'п.Майкаин' ],
        { margin: [ 0, 0, 0, 20 ] }
      )
      builder.addTitle(
        [ 'НАКЛАДНАЯ', 'НА ПЕРЕМЕЩЕНИЕ', `№ ${this.number} от ${DateUtils.formatedDate(this.date)}` ],
        { margin: [ 0, 0, 0, 20 ] }
      )
      builder.addTable(table, { margin: [ 0, 0, 0, 40 ] })
      builder.addOppositeColumns(
        'Водитель ___________________ ',
        'Зав. складом ___________________ '
      )
      builder.build()
    },
    handleRecordsClick() {
      this.$sm.goToDocRecords(TransferInventory.MODEL, this.docId)
    },
    handleHistoryClick() {
      this.$sm.goToDocHistory(TransferInventory.MODEL, this.docId)
    },
  },
  created() {
    this.docId = this.$sm.getCurrentParam('id')
    this.reloadView()
  }
})
