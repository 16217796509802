import AppContext from "@/core/AppContext"
import DataRequest from "@/core/data/DataRequest"
import DataResults from "@/core/data/DataResults"
import DocumentEntry from "@/core/model/doc/DocumentEntry"
import StoredEntry from "@/core/model/StoredEntry"
import Property, { IDProperty } from "@/core/model/Property"
import { ClosingOrder, ClosingOrderRow, DeliveryInventory, DeliveryInventoryRow, HistoryRecord, PurchaseOrder, PurchaseOrderRow } from "@/core/model/Types"
import { TransferInventory, TransferInventoryRow, WriteOffInventory, WriteOffInventoryRow } from "@/core/model/Types"
import { Counterparty, Employee, Equipment, Measure, Place, Storage } from "@/core/model/Types"

class Relation {
  source: StoredEntry
  targets: StoredEntry[]
  constructor(source: StoredEntry, targets: StoredEntry[]) {
    this.source = source
    this.targets = targets
  }
}

export default class Operation {

  private dm = AppContext.getDataManager()

  // CHANGING STATE

  private _onChangeStateCallback: Function | undefined

  // eslint-disable-next-line no-unused-vars
  onChangeState(callback: (state: string) => void) {
    this._onChangeStateCallback = callback
  }
  
  private registryState(value: string) {
    if (this._onChangeStateCallback) this._onChangeStateCallback(value)
  }

  // DELETION

  private _onDeletionCallback: Function | undefined

  // eslint-disable-next-line no-unused-vars
  onDeletion(callback: (entry: StoredEntry) => void) {
    this._onDeletionCallback = callback
  }

  private registryDeletion(entry: StoredEntry) {
    if (this._onDeletionCallback) this._onDeletionCallback(entry)
  }

  // CANCEL DELETION

  private _onCancelDeletionCallback: Function | undefined

  // eslint-disable-next-line no-unused-vars
  onCancelDeletion(callback: (entry: StoredEntry, links: StoredEntry[]) => void) {
    this._onCancelDeletionCallback = callback
  }

  private registryCancelDeletion(entry: StoredEntry, links: StoredEntry[]) {
    if (this._onCancelDeletionCallback) this._onCancelDeletionCallback(entry, links)
  }

  // PERFORM

  async perform(vue: any, callback: (_: boolean) => void) {

    const baseRels: Relation[] = []

    this.registryState("Анализ списаний ...")
    baseRels.push(... await this.getRelations(WriteOffInventory.MODEL, WriteOffInventory.VAL_TODELETE))
    this.registryState("Анализ перемещений ...")
    baseRels.push(... await this.getRelations(TransferInventory.MODEL, TransferInventory.VAL_TODELETE))
    this.registryState("Анализ поступлений ...")
    baseRels.push(... await this.getRelations(DeliveryInventory.MODEL, DeliveryInventory.VAL_TODELETE))
    this.registryState("Анализ закрытий заявок ...")
    baseRels.push(... await this.getRelations(ClosingOrder.MODEL, ClosingOrder.VAL_TODELETE))
    this.registryState("Анализ заявок ...")
    baseRels.push(... await this.getRelations(PurchaseOrder.MODEL, PurchaseOrder.VAL_TODELETE))
    this.registryState("Анализ контрагентов ...")
    baseRels.push(... await this.getRelations(Counterparty.MODEL, Counterparty.VAL_TODELETE))
    this.registryState("Анализ сотрудников ...")
    baseRels.push(... await this.getRelations(Employee.MODEL, Employee.VAL_TODELETE))
    this.registryState("Анализ оборудования ...")
    baseRels.push(... await this.getRelations(Equipment.MODEL, Equipment.VAL_TODELETE))
    this.registryState("Анализ единиц измерения ...")
    baseRels.push(... await this.getRelations(Measure.MODEL, Measure.VAL_TODELETE))
    this.registryState("Анализ участков ...")
    baseRels.push(... await this.getRelations(Place.MODEL, Place.VAL_TODELETE))
    this.registryState("Анализ складов ...")
    baseRels.push(... await this.getRelations(Storage.MODEL, Storage.VAL_TODELETE))

    this.registryState("Удаление ...")

    let lastRels = baseRels
    let entries: StoredEntry[] = []
    do {
      entries = await this.performDeletion(lastRels)
      if (entries.length > 0) {
        lastRels = this.removeEntries(lastRels, entries)
      }
    }
    while (entries.length > 0)
    
    for (const rel of lastRels) {
      this.registryCancelDeletion(rel.source, rel.targets)
    }

    this.registryState("Завешено")
    callback(false)
  }

  private async performDeletion(rels: Relation[]): Promise<StoredEntry[]> {

    if (rels.length == 0) {
      return []
    }

    // Выберем элементы без ссылок

    const toDelete: StoredEntry[] = []
    for (const rel of rels) {
      if (rel.targets.length == 0) {
        toDelete.push(rel.source)
      }
    }

    if (toDelete.length == 0) {
      return []
    }

    // Удалим отобранные объекты

    for (const entry of toDelete) {
      this.registryDeletion(entry)
      await this.performDeletionEntry(entry)
    }

    return toDelete
  }

  private async performDeletionEntry(entry: StoredEntry): Promise<void> {

    const request = new DataRequest()

    if (entry instanceof DocumentEntry) {
      if (entry instanceof ClosingOrder) {
        request.addDelEntriesByIds(ClosingOrder.MODEL, [entry.id])
        request.addDelEntriesWhere(ClosingOrderRow.MODEL, [
          [ ClosingOrderRow.VAL_DOC_ID, '==', entry.id ]
        ])
      } else if (entry instanceof DeliveryInventory) {
        request.addDelEntriesByIds(DeliveryInventory.MODEL, [entry.id])
        request.addDelEntriesWhere(DeliveryInventoryRow.MODEL, [
          [ DeliveryInventoryRow.VAL_DOC_ID, '==', entry.id ]
        ])
      } else if (entry instanceof PurchaseOrder) {
        request.addDelEntriesByIds(PurchaseOrder.MODEL, [entry.id])
        request.addDelEntriesWhere(PurchaseOrderRow.MODEL, [
          [ PurchaseOrderRow.VAL_DOC_ID, '==', entry.id ]
        ])
      } else if (entry instanceof TransferInventory) {
        request.addDelEntriesByIds(TransferInventory.MODEL, [entry.id])
        request.addDelEntriesWhere(TransferInventoryRow.MODEL, [
          [ TransferInventoryRow.VAL_DOC_ID, '==', entry.id ]
        ])
      } else if (entry instanceof WriteOffInventory) {
        request.addDelEntriesByIds(WriteOffInventory.MODEL, [entry.id])
        request.addDelEntriesWhere(WriteOffInventoryRow.MODEL, [
          [ WriteOffInventoryRow.VAL_DOC_ID, '==', entry.id ]
        ])
      } else {
        throw new Error('j8tq, ' + entry.model)
      }
    } else { // Справочники
      request.addDelEntriesByIds(entry.model, [entry.id])
    }

    request.addDelEntriesWhere(HistoryRecord.MODEL, [
      [ HistoryRecord.VAL_OBJECT_MODEL, '==', entry.model ],
      [ HistoryRecord.VAL_OBJECT_ID, '==', entry.id ]
    ])

    await this.dm.send(request)
  }

  private removeEntries(rels: Relation[], entries: StoredEntry[]): Relation[] {
    const result: Relation[] = []
    const ids = entries.map(it => it.id)
    for (const rel of rels) {
      if (!ids.includes(rel.source.id)) {
        const links = rel.targets.filter(it => !ids.includes(it.id))
        result.push(new Relation(rel.source, links))
      }
    }
    return result
  }
  
  private async getRelations(model: string, toDeleteKey: string): Promise<Relation[]> {

    const entries = await this.dm.getEntriesWhere(model, [[ toDeleteKey, '==', 1 ]])
    if (entries.length === 0) {
      return []
    }

    const rels: Relation[] = []

    for (const entry of entries) {
      const results = await this.getLinks(model, entry.id)

      const links: StoredEntry[] = []
      for (const linkModel of results.keys) {
        for (const linkEntry of results.getValue(linkModel)) {
          links.push(await this.getRootEntry(linkEntry))
        }
      }

      rels.push(new Relation(entry, links))
    }

    return rels
  }

  private async getRootEntry(entry: any): Promise<any> {
    if (entry instanceof ClosingOrderRow) {
      return await this.dm.getEntryById(ClosingOrder.MODEL, entry.docId)
    } else if (entry instanceof DeliveryInventoryRow) {
      return await this.dm.getEntryById(DeliveryInventory.MODEL, entry.docId)
    } else if (entry instanceof PurchaseOrderRow) {
      return await this.dm.getEntryById(PurchaseOrder.MODEL, entry.docId)
    } else if (entry instanceof TransferInventoryRow) {
      return await this.dm.getEntryById(TransferInventory.MODEL, entry.docId)
    } else if (entry instanceof WriteOffInventoryRow) {
      return await this.dm.getEntryById(WriteOffInventory.MODEL, entry.docId)
    } else {
      return entry
    }
  }

  private async getLinks(model: string, id: string): Promise<DataResults> {

    const request = new DataRequest()

    this.prepareLinksRequest(request, model, id, ClosingOrder.MODEL, ClosingOrder.PROPS)
    this.prepareLinksRequest(request, model, id, ClosingOrderRow.MODEL, ClosingOrderRow.PROPS)
    this.prepareLinksRequest(request, model, id, DeliveryInventory.MODEL, DeliveryInventory.PROPS)
    this.prepareLinksRequest(request, model, id, DeliveryInventoryRow.MODEL, DeliveryInventoryRow.PROPS)
    this.prepareLinksRequest(request, model, id, PurchaseOrder.MODEL, PurchaseOrder.PROPS)
    this.prepareLinksRequest(request, model, id, PurchaseOrderRow.MODEL, PurchaseOrderRow.PROPS)
    this.prepareLinksRequest(request, model, id, TransferInventory.MODEL, TransferInventory.PROPS)
    this.prepareLinksRequest(request, model, id, TransferInventoryRow.MODEL, TransferInventoryRow.PROPS)
    this.prepareLinksRequest(request, model, id, WriteOffInventory.MODEL, WriteOffInventory.PROPS)
    this.prepareLinksRequest(request, model, id, WriteOffInventoryRow.MODEL, WriteOffInventoryRow.PROPS)
    this.prepareLinksRequest(request, model, id, Counterparty.MODEL, Counterparty.PROPS)
    this.prepareLinksRequest(request, model, id, Employee.MODEL, Employee.PROPS)
    this.prepareLinksRequest(request, model, id, Equipment.MODEL, Equipment.PROPS)
    this.prepareLinksRequest(request, model, id, Measure.MODEL, Measure.PROPS)
    this.prepareLinksRequest(request, model, id, Place.MODEL, Place.PROPS)
    this.prepareLinksRequest(request, model, id, Storage.MODEL, Storage.PROPS)

    return await this.dm.send(request)
  }

  private prepareLinksRequest(request: DataRequest, sourceModel: string, sourceId: string, targetModel: string, targetProps: Property[]) {
    for (const property of targetProps) {
      if (property instanceof IDProperty) {
        if (property.model === sourceModel) {
          request.addGetEntriesWhere(targetModel, [[ property.key, '==', sourceId ]], targetModel)
        }
      }
    }
  }
}