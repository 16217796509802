import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UniversalDocsPage = _resolveComponent("UniversalDocsPage")!

  return (_openBlock(), _createBlock(_component_UniversalDocsPage, {
    "panel-title": "Заявки",
    model: _ctx.docModel,
    "table-headers": _ctx.tableHeaders,
    "load-config": _ctx.loadConfig,
    "edit-permission": _ctx.editPermission,
    "date-key": _ctx.dateKey,
    "cache-key": "ne6j"
  }, null, 8, ["model", "table-headers", "load-config", "edit-permission", "date-key"]))
}