
import { PropType, defineComponent } from 'vue';
import DefaultPage from "@/use/components/DefaultPage.vue";
import { UIPanel, UIToolbar, UIButton, UISearchField } from "@/use/base/Types";
import { UITable, UITableRow, UITableCell } from '@/use/base/Types';
import AppContext from '@/core/AppContext';
import { PDFBuilder, PDFTable } from '@/lib/pdf/PDFTypes';
import EntryLoader from '@/lib/data/EntryLoader';
import StoredEntry from '@/core/model/StoredEntry';
import EntryMap from '@/lib/data/EntryMap';

export default defineComponent({
  components: {
    DefaultPage, 
    UIPanel, UIToolbar, UIButton, UISearchField,
    UITable, UITableRow, UITableCell,
  },
  props: { 
    model: { type: String, required: true },
    panelTitle: String,
    tableHeaders: { type: Array as PropType<any[]>, required: true },
    loadConfig: { type: Array as PropType<any[]>, required: true },
    editPermission: String,
  },
  data() {
    return {
      grantedCreate: false,
      loader: new EntryLoader(),
      tableRows: new Array<any>(),
      filter: '',
      isLoading: false,
    }
  },
  computed: {
    internalTableHeaders(): string[] { return this.tableHeaders.map(it => it.name) },
    internalTableWidths(): any[] { return this.tableHeaders.map(it => it.width) },
    displayTableRows() {
      if (this.filter) {
        let correctText = this.filter.toLowerCase()
        let arr = []
        for (let i = 0; i < this.tableRows.length; i++) {
          const item = this.tableRows[i]
          for (let h = 0; h < this.tableHeaders.length; h++) {
            const value = item[this.tableHeaders[h].key]
            if (value && value.toLowerCase().includes(correctText)) {
              arr.push(item)
              break
            }
          }
        }
        return arr
      } else {
        return this.tableRows
      }
    },
  },
  methods: {
    handleCreateClick() {
      let newId = this.$dm.newEntryId()
      this.$sm.goToObjectEditor(this.model, newId)
    },
    handlePrintClick() {

      const table = new PDFTable('lightHorizontalLines')
      table.setColumnWidths(this.tableHeaders.map(it => it.width))
      table.setColumnAligment(this.tableHeaders.map(it => it.alignment || 'left'))
      table.setHeaders(this.tableHeaders.map(it => it.name))
      for (const row of this.tableRows) {
        table.addRow(this.tableHeaders.map(it => row[it.key]))
      }

      const builder = new PDFBuilder('portrait')
      builder.addTitle(this.panelTitle, { margin: [ 0, 0, 0, 20 ] })
      builder.addTable(table, { margin: [ 0, 0, 0, 20 ] })
      builder.build()
    },
    handleDoubleClick(row: any) {
      this.$sm.goToObjectEditor(this.model, row.id)
    },
    createTableRow(entry: StoredEntry, config: any[], details: EntryMap) {
      const row: { [name: string]: string } = { }
      for (const c of config) {

        let value: any
        if (c.model) {
          const id = entry.getValue(c.key)
          if (id) {
            value = details?.getEntryById(c.model, id)
          } else {
            value = undefined
          }
        } else if (c.key) {
          value = entry.getValue(c.key)
        } else {
          value = undefined
        }

        if (c.handler) {
          row[c.name] = c.handler(value, entry)
        } else {
          row[c.name] = value
        }
      }
      return row
    },
    reloadData() {
      this.isLoading = true
      this.loader.loadAllEntries<StoredEntry>(this.model)
        .then(entries => {
          const options = []
          for (const c of this.loadConfig) {
            if (c.key && c.model) options.push({ key: c.key, model: c.model })
          }
          const sorted = entries.sort((a, b) => a.name.localeCompare(b.name))
          return this.loader.loadDetails(sorted, options)
            .then(details => { this.tableRows = sorted.map(it => this.createTableRow(it, this.loadConfig, details)) })
        })
        .finally(() => this.isLoading = false)
    },
  },
  created() {

    AppContext.getSafeManager().getCurrentPermissions(perms => {
      this.grantedCreate = (this.editPermission) ? perms.includes(this.editPermission) : false
    })
    
    this.reloadData()
  },
})
