
import { defineComponent } from 'vue';
import UniversalUnitsPage from "./UniversalUnitsPage.vue";
import { Counterparty } from "@/core/model/Types";
import { TableUtils } from '@/lib/util/Utils';
import * as Permission from '@/core/safe/Permissions'

export default defineComponent({
  components: { UniversalUnitsPage },
  data() {
    return {
      unitModel: Counterparty.MODEL,
      tableHeaders: TableUtils.headers(m => [
        { key: 'name', name: 'Название', width: m.flex(1) }
      ]),
      loadConfig: [
        { name: 'id', key: Counterparty.VAL_ID },
        { name: 'name', key: Counterparty.VAL_NAME },
        { name: 'toDelete', key: Counterparty.VAL_TODELETE }
      ],
      editPermission: Permission.COUNTERPARTY_EDIT,
    }
  },
})
