
import { defineComponent } from 'vue';
import BreadcrumbLink from './BreadcrumbLink.vue';

export default defineComponent({
  components: { BreadcrumbLink },
  data() {
    return {
      paths: new Array<string>()
    }
  },
  created() {
    this.paths = this.$sm.getSequencePaths()
  }
})
