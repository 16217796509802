
export default class EntryUtils {

  static correctEntry(value: any): any {
    if (value instanceof Object) {
      if (value['id'] && value['name']) {
        return value
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }

  static isEmpty(value: any): boolean {
    return this.correctEntry(value) === undefined
  }

  static getId(value: any): string | undefined {
    return this.correctEntry(value)?.id
  }

  static getName(value: any): string {
    return this.correctEntry(value)?.name || ''
  }
}