
export class RouteFamily {

  static HOME = 'pya1'
  static LOGIN = 'wx07'
  
  static LIST_OF_OBJECTS = 'd59x'
  static OBJECT_EDITOR = 'z98j'

  static DOC_HISTORY = 'n8t1'
  static DOC_RECORDS = 'k7w6'
  
  static REPORT = 'j0xr'
  static SETTINGS = 'a1oq'
  static OPERATIONS = 'g6q9'

  static NOT_FOUND = 'v36s'
}

export class ReportName {
  
  static ORDER_QUEUE = 'i4yz'
  static STORAGE_PARTIONS = 'uv0z'
  static STORAGE_INVENTORIES = 'i6kx'
  static EQUIPMENT_COST = 's0ym'
}