import DocumentEntry from "./DocumentEntry"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, NumberProperty, StringProperty } from "../Property"
import Storage from "../unit/Storage"
import Counterparty from "../unit/Counterparty"
import Employee from "../unit/Employee"

export default class DeliveryInventory extends DocumentEntry {
  static MODEL = 'vj4j'

  static VAL_STORAGE_ID = 'x2dv'
  static VAL_PROVIDER_ID = 'nw9s'
  static VAL_COMMENT = 'sk5o'
  static VAL_TOTAL = 'gp3a'
  static VAL_AUTHOR_ID = 'sq8q'

  static PROPS: Property[] = DocumentEntry.PROPS.concat([
    new IDProperty(this.VAL_STORAGE_ID, Storage.MODEL), new IDProperty(this.VAL_PROVIDER_ID, Counterparty.MODEL),
    new StringProperty(this.VAL_COMMENT), new NumberProperty(this.VAL_TOTAL),
    new IDProperty(this.VAL_AUTHOR_ID, Employee.MODEL)
  ])

  // ---

  constructor(data?: any) {
    super(data)
  }

  get name(): string { return `Поступление №${this.number} от ${EntryHelper.toDateString(this.date)}` }
  
  get storageId(): string { return this.getValue(DeliveryInventory.VAL_STORAGE_ID) }
  get providerId(): string { return this.getValue(DeliveryInventory.VAL_PROVIDER_ID) }
  get comment(): string { return this.getValue(DeliveryInventory.VAL_COMMENT) }
  get total(): number { return this.getValue(DeliveryInventory.VAL_TOTAL) }
  get authorId(): string { return this.getValue(DeliveryInventory.VAL_AUTHOR_ID) }

  static new(value: any): DeliveryInventory {
    return EntryHelper.fillEntry(new DeliveryInventory(), value)
  }
}