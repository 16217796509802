import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ff5af96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-date-field" }
const _hoisted_2 = ["id", "value"]
const _hoisted_3 = ["id", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.readonly)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          id: _ctx.elementId,
          class: "date-input",
          type: "date",
          value: _ctx.displayDate,
          readonly: true
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("input", {
          key: 1,
          id: _ctx.elementId,
          class: "date-input",
          type: "date",
          value: _ctx.displayDate,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
        }, null, 40, _hoisted_3))
  ]))
}