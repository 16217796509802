import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d488c46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-table" }
const _hoisted_2 = {
  ref: "table",
  class: "table"
}
const _hoisted_3 = { class: "head" }
const _hoisted_4 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("colgroup", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.widths, (_, wIndex) => {
          return (_openBlock(), _createElementBlock("col", {
            key: `w${wIndex}`,
            style: _normalizeStyle({ width: _ctx.tableWidths[wIndex] })
          }, null, 4))
        }), 128))
      ]),
      _createElementVNode("thead", _hoisted_3, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, hIndex) => {
            return (_openBlock(), _createElementBlock("th", {
              key: `h${hIndex}`,
              class: "header"
            }, _toDisplayString(header), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ], 512)
  ]))
}