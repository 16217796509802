import StoredEntry from "../StoredEntry"
import EntryHelper from "../EntryHelper"
import Property, { BoolProperty, StringProperty } from "../Property"

export default class Place extends StoredEntry {
  static MODEL = 'cp1h'

  static VAL_NAME = 'of3a'
  static VAL_TODELETE = 'a3yq'

  static PROPS: Property[] = StoredEntry.PROPS.concat([
    new StringProperty(this.VAL_NAME), new BoolProperty(this.VAL_TODELETE)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get name(): string { return this.getValue(Place.VAL_NAME) }
  get toDelete(): boolean { return this.getValue(Place.VAL_TODELETE) }

  static new(value: any): Place {
    return EntryHelper.fillEntry(new Place(), value)
  }
}