import StoredEntry from "../StoredEntry"
import Property, { DateProperty } from "../Property"

export default class RegistryRecord extends StoredEntry {

  static VAL_DATE = 'yd5e'

  static PROPS: Property[] = StoredEntry.PROPS.concat([
    new DateProperty(this.VAL_DATE)
  ])

  // ---

  constructor(data: any) {
    super(data)
  }

  get date(): Date { return this.getValue(RegistryRecord.VAL_DATE) }
}