import AppContext from '@/core/AppContext';
import { Counterparty, Employee, Equipment, Measure, Place, Storage } from '@/core/model/Types';
import { PurchaseOrder, ClosingOrder, DeliveryInventory, TransferInventory, WriteOffInventory } from '@/core/model/Types';
import { ReportName, RouteFamily } from './RouteKeys'

function nameOfEntry(model: string, entry: any) {
  if (entry) {
    return entry.name
  } else {
    switch (model) {
      // Units
      case Counterparty.MODEL:        return 'Новый контрагент'
      case Employee.MODEL:            return 'Новый сотрудник'
      case Equipment.MODEL:           return 'Новое оборудование'
      case Measure.MODEL:             return 'Новая Ед.'
      case Place.MODEL:               return 'Новый участок'
      case Storage.MODEL:             return 'Новый склад'
      // Docs
      case PurchaseOrder.MODEL:       return 'Новая заявка'
      case ClosingOrder.MODEL:        return 'Новое закрытие заявки'
      case DeliveryInventory.MODEL:   return 'Новое поступление'
      case TransferInventory.MODEL:   return 'Новое перемещение'
      case WriteOffInventory.MODEL:   return 'Новое списание'
      default: 
        return `<Unknown(${model})>`
    }
  }
}

function loadNameOfObject(model: string, id: string) {
  const dm = AppContext.getDataManager()
  return dm.getEntryById(model, id)
    .then(it => nameOfEntry(model, it))
}

export default class SiteManager {

  private _vue: any

  constructor(vue: any) {
    this._vue = vue
  }

  // Path

  pathFor(family: string, name: string = '', id: string = ''): string {
    
    let baseRoute
    if (name) {
      baseRoute = this._vue.$router.getRoutes().find((it: any) => it.meta.family === family && it.meta.name === name)
    } else {
      baseRoute = this._vue.$router.getRoutes().find((it: any) => it.meta.family === family)
    }
    if (!baseRoute) return ''

    if (id) {
      return baseRoute.path.replace(':id', id)
    } else {
      return baseRoute
    }
  }

  pathToHome(): string { return this.pathFor(RouteFamily.HOME) }
  pathToLogin(): string { return this.pathFor(RouteFamily.LOGIN) }
  pathToListOfObjects(model: string): string { return this.pathFor(RouteFamily.LIST_OF_OBJECTS, model) }
  pathToObjectEditor(model: string, id: string): string { return this.pathFor(RouteFamily.OBJECT_EDITOR, model, id) }
  pathToDocHistory(model: string, id: string): string { return this.pathFor(RouteFamily.DOC_HISTORY, model, id) }
  pathToDocRecords(model: string, id: string): string { return this.pathFor(RouteFamily.DOC_RECORDS, model, id) }
  pathToReport(report: string): string { return this.pathFor(RouteFamily.REPORT, report) }
  pathToSettings(): string { return this.pathFor(RouteFamily.SETTINGS) }
  pathToOperations(): string { return this.pathFor(RouteFamily.OPERATIONS) }

  getCurrentPath(): string {
    return this._vue.$route.path
  }

  getCurrentParam(name: string): string {
    return this._vue.$route.params[name]
  }

  getCurrentPathName(): string {
    return this._vue.$route.meta.name
  }

  getSequencePaths(): string[] {
    const arr = this._vue.$route.path.split('/').filter((it: any) => it.length > 0)
    const paths = ['/']
    let priorPath = ''
    for (let i = 0; i < arr.length; i++) {
      priorPath = `${priorPath}/${arr[i]}`
      paths.push(priorPath)
    }
    return paths
  }

  // GoTo

  goTo(path: string, newWindow: boolean = false) {
    if (newWindow) {
      const url = this._vue.$router.resolve(path).href
      window.open(url, '_blank')
    } else {
      this._vue.$router.push(path)
    }
  }

  goToHome(newWindow: boolean = false) { this.goTo(this.pathToHome(), newWindow) }
  goToLogin(newWindow: boolean = false) { this.goTo(this.pathToLogin(), newWindow) }
  goToListOfObjects(model: string, newWindow: boolean = false) { this.goTo(this.pathToListOfObjects(model), newWindow) }
  goToObjectEditor(model: string, id: string, newWindow: boolean = false) { this.goTo(this.pathToObjectEditor(model, id), newWindow) }
  goToDocHistory(model: string, id: string, newWindow: boolean = false) { this.goTo(this.pathToDocHistory(model, id), newWindow)}
  goToDocRecords(model: string, id: string, newWindow: boolean = false) { this.goTo(this.pathToDocRecords(model, id), newWindow)}
  goToReport(report: string, newWindow: boolean = false) { this.goTo(this.pathToReport(report), newWindow) }
  goToSettings(newWindow: boolean = false) { this.goTo(this.pathToSettings(), newWindow) }
  goToOperations(newWindow: boolean = false) { this.goTo(this.pathToOperations(), newWindow) }

  goBack() {
    const arr = this._vue.$route.path.split('/').filter((it: any) => it.length > 0)
    arr.pop() // Удаляем последний элемент
    this._vue.$router.push('/' + arr.join('/'))
  }

  // Name

  nameOfListOfObjects(model: string): string {
    switch (model) {
      // Units
      case Counterparty.MODEL:        return 'Контрагенты'
      case Employee.MODEL:            return 'Сотрудники'
      case Equipment.MODEL:           return 'Оборудования'
      case Measure.MODEL:             return 'Единицы измерений'
      case Place.MODEL:               return 'Участки'
      case Storage.MODEL:             return 'Склады'
      // Docs
      case PurchaseOrder.MODEL:       return 'Заявки'
      case ClosingOrder.MODEL:        return 'Закрытие заявок'
      case DeliveryInventory.MODEL:   return 'Поступления'
      case TransferInventory.MODEL:   return 'Перемещения'
      case WriteOffInventory.MODEL:   return 'Списания'
      default: 
        return `<Unknown(${model})>`
    }
  }

  nameOfReport(report: string): string {
    switch (report) {
      case ReportName.ORDER_QUEUE:       return 'Очередь заказов'
      case ReportName.STORAGE_PARTIONS:   return 'Остатки на складах (Партии)'
      case ReportName.STORAGE_INVENTORIES:   return 'Остатки на складах (Номенклатура)'
      case ReportName.EQUIPMENT_COST:    return 'Расходы на оборудование'
      default: 
        return `<Unknown(${report})>`
    }
  }

  nameOfPath(path: string): string {
    
    const route = this._vue.$router.resolve(path)
    if (!route) return `<Unknown(${path})>`

    switch (route.meta.family) {
      case RouteFamily.HOME: return 'Главная'
      case RouteFamily.LOGIN: return 'Вход'
      case RouteFamily.LIST_OF_OBJECTS: return this.nameOfListOfObjects(route.meta.name)
      case RouteFamily.OBJECT_EDITOR: return ''
      case RouteFamily.DOC_HISTORY: return 'История'
      case RouteFamily.DOC_RECORDS: return 'Движения'
      case RouteFamily.REPORT: return this.nameOfReport(route.meta.name)
      case RouteFamily.SETTINGS: return 'Настройки'
      case RouteFamily.OPERATIONS: return 'Операции'
      case RouteFamily.NOT_FOUND: return '404'
      default:
        return `<Unknown(${path})>`
    }
  }

  getNameOfPath(path: string, callback: (_: string) => void) {

    const route = this._vue.$router.resolve(path)
    if (!route) {
       callback(`<Unknown(${path})>`)
       return
    }

    switch (route.meta.family) {
      case RouteFamily.OBJECT_EDITOR:
        loadNameOfObject(route.meta.name, route.params['id'])
          .then(name => callback(name))
          .catch(() => callback('?'))
        break
      default:
        callback(this.nameOfPath(path))
    }
  }
}