
const mSessionValues = new Map<string, any>()

export default class SessionCache {

  static get(key: string) {
    return mSessionValues.get(key)
  }

  static extract(key: string) {
    const value = mSessionValues.get(key)
    mSessionValues.delete(key)
    return value
  }

  static put(key: string, value: any) {
    mSessionValues.set(key, value)
  }
}