import DocumentEntry from "./DocumentEntry"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, StringProperty } from "../Property"
import Storage from "../unit/Storage"
import Employee from "../unit/Employee"

export default class TransferInventory extends DocumentEntry {
  static MODEL = 'qf7i'

  static VAL_SOURCE_STORAGE_ID = 'b3pe'
  static VAL_TARGET_STORAGE_ID = 'p1pj'
  static VAL_COMMENT = 'pu4f'
  static VAL_AUTHOR_ID = 'k1it'
  // static VAL_TOTAL = 'kc7f' // Удалить

  static PROPS: Property[] = DocumentEntry.PROPS.concat([
    new IDProperty(this.VAL_SOURCE_STORAGE_ID, Storage.MODEL), new IDProperty(this.VAL_TARGET_STORAGE_ID, Storage.MODEL),
    new StringProperty(this.VAL_COMMENT), new IDProperty(this.VAL_AUTHOR_ID, Employee.MODEL),
    // new NumberProperty(this.VAL_TOTAL)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get name(): string { return `Перемещение №${this.number} от ${EntryHelper.toDateString(this.date)}` }
  
  get sourceStorageId(): string { return this.getValue(TransferInventory.VAL_SOURCE_STORAGE_ID) }
  get targetStorageId(): string { return this.getValue(TransferInventory.VAL_TARGET_STORAGE_ID) }
  get comment(): string { return this.getValue(TransferInventory.VAL_COMMENT) }
  get authorId(): string { return this.getValue(TransferInventory.VAL_AUTHOR_ID) }
  // get total(): number { return this.getValue(TransferInventory.VAL_TOTAL) }

  static new(value: any): TransferInventory {
    return EntryHelper.fillEntry(new TransferInventory(), value)
  }
}