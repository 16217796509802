import EntryMap from '@/lib/data/EntryMap';
import * as ServerKey from '../server/ServerKeys';

export default class DataResults {

  private _results: { [name: string]: any }[]

  constructor(results: { [name: string]: any }[]) {
    this._results = results || []
  }

  get keys(): string[] {
    const result: string[] = []
    this._results.forEach(it => {
      const key = it[ServerKey.OPERATION_KEY]
      if (key && !result.includes(key)) result.push(key)
    })
    return result
  }

  get length(): number {
    return this._results.length
  }

  getValues(key: string = ''): any {
    if (key) {
      return this._results.filter(it => it[ServerKey.OPERATION_KEY] === key).map(it => it[ServerKey.OPERATION_VALUE])
    } else {
      return this._results.map(it => it[ServerKey.OPERATION_VALUE])
    }
  }

  getValue(key: string = ''): any {
    if (key) {
      const result = this._results.find(it => it[ServerKey.OPERATION_KEY] === key)
      return (result) ? result[ServerKey.OPERATION_VALUE] : undefined
    } else {
      const result = this._results.find(() => true)
      return (result) ? result[ServerKey.OPERATION_VALUE] : undefined
    }
  }

  getValueAt(index: number) {
    return this._results[index][ServerKey.OPERATION_VALUE]
  }

  toEntryMap(): EntryMap {
    const map = new EntryMap()
    this._results.forEach(it => {
      const model = it[ServerKey.OPERATION_KEY]
      if (model) {
        map.addEntries(model, it[ServerKey.OPERATION_VALUE])
      }
    })
    return map
  }
}