import DocumentEntry from "./DocumentEntry"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, NumberProperty, StringProperty } from "../Property"
import Storage from "../unit/Storage"
import Employee from "../unit/Employee"

export default class WriteOffInventory extends DocumentEntry {
  static MODEL = 'oe4b'

  static VAL_STORAGE_ID = 'c5pd'
  static VAL_COMMENT = 'c7hf'
  static VAL_TOTAL = 'ur7m'
  static VAL_AUTHOR_ID = 'us8b'

  static PROPS: Property[] = DocumentEntry.PROPS.concat([
    new IDProperty(this.VAL_STORAGE_ID, Storage.MODEL), new StringProperty(this.VAL_COMMENT),
    new NumberProperty(this.VAL_TOTAL), new IDProperty(this.VAL_AUTHOR_ID, Employee.MODEL)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get name(): string { return `Списание №${this.number} от ${EntryHelper.toDateString(this.date)}` }
  
  get storageId(): string { return this.getValue(WriteOffInventory.VAL_STORAGE_ID) }
  get comment(): string { return this.getValue(WriteOffInventory.VAL_COMMENT) }
  get total(): number { return this.getValue(WriteOffInventory.VAL_TOTAL) }
  get authorId(): string { return this.getValue(WriteOffInventory.VAL_AUTHOR_ID) }

  static new(value: any): WriteOffInventory {
    return EntryHelper.fillEntry(new WriteOffInventory(), value)
  }
}