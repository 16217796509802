import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-93694052"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "breadcrumb" }
const _hoisted_2 = { class: "breadcrumb-list" }
const _hoisted_3 = {
  key: 0,
  class: "breadcrumb-separator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbLink = _resolveComponent("BreadcrumbLink")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paths, (path, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: path,
          class: "breadcrumb-item"
        }, [
          (index > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, ">"))
            : _createCommentVNode("", true),
          _createVNode(_component_BreadcrumbLink, {
            path: path,
            enable: index < _ctx.paths.length - 1
          }, null, 8, ["path", "enable"])
        ]))
      }), 128))
    ])
  ]))
}