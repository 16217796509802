import AppContext from '@/core/AppContext';
import { ClosingOrder, ClosingOrderRow, HistoryRecord, OrderRecord, PurchaseOrder, PurchaseOrderRow } from "@/core/model/Types";
import DataRequest from "@/core/data/DataRequest";
import HistoryBuilder from "@/lib/data/HistoryBuilder";
import EntryMap from '@/lib/data/EntryMap';
import DataManager from '@/core/data/DataManager';
import { DateUtils } from '@/lib/util/Utils';

export default class ClosingOrderHelper {

  private static get dm(): DataManager { return AppContext.getDataManager() }

  static save(doc: ClosingOrder, rows: ClosingOrderRow[], entryMap: EntryMap): Promise<boolean> {
    return this.hold(doc, rows, entryMap)
  }

  private static hold(doc: ClosingOrder, rows: ClosingOrderRow[], entryMap: EntryMap): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const emptyValues = []

      if (DateUtils.isEmpty(doc.date)) emptyValues.push('Дата')
      if (!doc.number) emptyValues.push('Номер')
  
      rows.forEach((row, index) => {
        if (!row.orderId) emptyValues.push(`Заявка (Строка ${index + 1})`)
        if (!row.orderRowId) emptyValues.push(`Позиция заявки (Строка ${index + 1})`)
        if (Number(row.amount) <= 0) emptyValues.push(`Количество (Строка ${index + 1})`)
      })
  
      if (emptyValues.length > 0) {
        reject(`Не указаны значения: "${emptyValues.join('", "')}"`)
        return
      }

      this.holdPrepare(doc, rows, entryMap)
        .then(v => resolve(v))
        .catch(msg => reject(msg))
    })
  }

  private static async holdPrepare(doc: ClosingOrder, rows: ClosingOrderRow[], entryMap: EntryMap): Promise<boolean> {

    const employeeId = AppContext.getSafeManager().getCurrentEmployeeId()!
    const history = new HistoryBuilder(employeeId, ClosingOrder.MODEL, doc.id)

    const request = new DataRequest()
    request.addGetEntriesByIds(ClosingOrder.MODEL, [doc.id], 'doc')
    request.addGetEntriesWhere(ClosingOrderRow.MODEL, [
      [ ClosingOrderRow.VAL_DOC_ID, '==', doc.id ]
    ], 'rows')

    const results = await this.dm.send(request)

    const oldDoc = results.getValue('doc').find(() => true) as ClosingOrder | undefined
    const oldRows = ((results.getValue('rows') || []) as ClosingOrderRow[]).sort((a, b) => a.index - b.index)

    // Создадим историю изменений

    if (oldDoc) {

      if (DateUtils.getTime(oldDoc.date) !== DateUtils.getTime(doc.date)) {
        history.pushChange('Дата', DateUtils.formatedDate(oldDoc.date), DateUtils.formatedDate(doc.date))
      }
      if ((oldDoc.number || '') !== (doc.number || '')) {
        history.pushChange('Номер', oldDoc.number, doc.number)
      }
      if ((oldDoc.comment || '') !== (doc.comment || '')) {
        history.pushChange('Коментарий', oldDoc.comment, doc.comment)
      }

      const rowsCount = Math.max(oldRows.length, rows.length)
      for (let i = 0; i < rowsCount; i++) {
        const oldRow = (i < oldRows.length) ? oldRows[i] : undefined
        const newRow = (i < rows.length) ? rows[i] : undefined
        history.pushTest(`Строка ${i + 1}`, this.toHistoryString(oldRow, entryMap), this.toHistoryString(newRow, entryMap))
      }
    } 
    else {
      history.pushDetails('Создание документа')
    }

    // Поправим данные документа

    rows.forEach((row, index) => {
      row.setValue(ClosingOrderRow.VAL_DOC_ID, doc.id)
      row.setValue(ClosingOrderRow.VAL_INDEX, index)
    })

    if (history.isEmpty()) {
      doc.setValue(ClosingOrder.VAL_AUTHOR_ID, oldDoc?.authorId || employeeId)
    } else {
      doc.setValue(ClosingOrder.VAL_AUTHOR_ID, employeeId)
    }

    // Запишем в базу данных

    return this.holdSave(doc, rows, history)
  }

  private static async holdSave(doc: ClosingOrder, rows: ClosingOrderRow[], history: HistoryBuilder): Promise<boolean> {

    // Обработка

    const createActions = !doc.toDelete
    const request = new DataRequest()

    // Сам документ

    request.addPutEntries(ClosingOrder.MODEL, [doc])

    request.addDelEntriesWhere(ClosingOrderRow.MODEL, [
      [ ClosingOrderRow.VAL_DOC_ID, '==', doc.id ]
    ])
    request.addPutEntries(ClosingOrderRow.MODEL, rows)

    // Регистр заявок

    request.addDelEntriesWhere(OrderRecord.MODEL, [
      [ OrderRecord.VAL_DOC_MODEL, '==', ClosingOrder.MODEL ],
      [ OrderRecord.VAL_DOC_ID, '==', doc.id ]
    ])
    if (createActions) {
      request.addPutEntries(OrderRecord.MODEL, rows.map(row => OrderRecord.new({
        [OrderRecord.VAL_DATE]: doc.date,
        [OrderRecord.VAL_DOC_MODEL]: ClosingOrder.MODEL,
        [OrderRecord.VAL_DOC_ID]: doc.id,
        [OrderRecord.VAL_ORDER_ID]: row.orderId,
        [OrderRecord.VAL_ORDER_ROW_ID]: row.orderRowId,
        [OrderRecord.VAL_AMOUNT]: -row.amount,
      })))
    }

    // История

    request.addPutEntries(HistoryRecord.MODEL, history.getList())

    // Запись

    await this.dm.send(request)
    return true
  }

  private static toHistoryString(row: ClosingOrderRow | undefined, entryMap: EntryMap) {
    if (row) {
      const vals = []
      vals.push(entryMap.getDisplayName(PurchaseOrder.MODEL, row.orderId))
      vals.push(entryMap.getDisplayName(PurchaseOrderRow.MODEL, row.orderRowId))
      vals.push(row.amount || 0)
      vals.push(row.comment)
      return vals.join(', ')
    } else {
      return ''
    }
  }
}