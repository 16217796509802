
import { PropType, defineComponent } from 'vue';
import DefaultPage from "@/use/components/DefaultPage.vue";
import { UIPanel, UIToolbar, UIButton, UIDateField, UISearchField } from "@/use/base/Types";
import { UITable, UITableRow, UITableCell } from '@/use/base/Types';
import AppContext from '@/core/AppContext';
import { DateUtils } from "@/lib/util/Utils"
import { PDFTable, PDFBuilder, PDFText } from '@/lib/pdf/PDFTypes';
import LocalStorageEx from '@/lib/util/LocalStorageEx'
import EntryLoader from '@/lib/data/EntryLoader';
import DocumentEntry from '@/core/model/doc/DocumentEntry';
import EntryMap from '@/lib/data/EntryMap';

export default defineComponent({
  components: {
    DefaultPage,
    UIPanel, UIToolbar, UIButton, UIDateField, UISearchField,
    UITable, UITableRow, UITableCell,
  },
  props: { 
    model: { type: String, required: true },
    panelTitle: String,
    tableHeaders: { type: Array as PropType<any[]>, required: true },
    loadConfig: { type: Array as PropType<any[]>, required: true },
    editPermission: String,
    dateKey: { type: String, required: true },
    cacheKey: String,
  },
  data() {
    return {
      grantedCreate: false,
      loader: new EntryLoader(),
      tableRows: new Array<any>(),
      dateStart: (this.cacheKey) ? LocalStorageEx.getDate(`${this.cacheKey}_ru2d`) : undefined,
      dateEnd: (this.cacheKey) ? LocalStorageEx.getDate(`${this.cacheKey}_r95a`) : undefined,
      filter: '',
      isLoading: false,
    }
  },
  computed: {
    internalTableHeaders(): string[] { return this.tableHeaders.map(it => it.name) },
    internalTableWidths(): any[] { return this.tableHeaders.map(it => it.width) },
    displayTableRows() {
      if (this.filter) {
        let correctText = this.filter.toLowerCase()
        let arr = []
        for (let i = 0; i < this.tableRows.length; i++) {
          const item = this.tableRows[i]
          for (let h = 0; h < this.tableHeaders.length; h++) {
            const value = item[this.tableHeaders[h].key]
            if (value && value.toLowerCase().includes(correctText)) {
              arr.push(item)
              break
            }
          }
        }
        return arr
      } else {
        return this.tableRows
      }
    },
  },
  watch: {
    dateStart(newValue) {
      if (this.cacheKey) LocalStorageEx.setDate(`${this.cacheKey}_ru2d`, DateUtils.correctDate(newValue))
      this.reloadData()
    },
    dateEnd(newValue) { 
      if (this.cacheKey) LocalStorageEx.setDate(`${this.cacheKey}_r95a`, DateUtils.correctDate(newValue))
      this.reloadData() 
    },
  },
  methods: {
    handleCreateClick() {
      this.$sm.goToObjectEditor(this.model, 'new')
    },
    handlePrintClick() {

      const table = new PDFTable('lightHorizontalLines')
      table.setColumnWidths(this.tableHeaders.map(it => it.width))
      table.setColumnAligment(this.tableHeaders.map(it => it.alignment || 'left'))
      table.setHeaders(this.tableHeaders.map(it => it.name))
      for (const row of this.tableRows) {
        table.addRow(this.tableHeaders.map(it => new PDFText(row[it.key], (it.alignment) ? `column_${it.alignment}` : undefined )))
      }

      const builder = new PDFBuilder('landscape')
      builder.addTitle(this.panelTitle, { margin: [ 0, 0, 0, 20 ] })
      builder.addTable(table, { margin: [ 0, 0, 0, 20 ] })
      builder.setStyle('column_left', { alignment: 'left' })
      builder.setStyle('column_center', { alignment: 'center' })
      builder.setStyle('column_right', { alignment: 'right' })
      builder.build()
    },
    handleDoubleClick(row: any) {
      this.$sm.goToObjectEditor(this.model, row.id)
    },
    createTableRow(entry: DocumentEntry, config: any[], details: EntryMap) {
      const row: { [name: string]: string } = { }
      for (const c of config) {

        let value: any
        if (c.model) {
          const id = entry.getValue(c.key)
          if (id) {
            value = details?.getEntryById(c.model, id)
          } else {
            value = undefined
          }
        } else if (c.key) {
          value = entry.getValue(c.key)
        } else {
          value = undefined
        }

        if (c.handler) {
          row[c.name] = c.handler(value, entry)
        } else {
          row[c.name] = value
        }
      }
      return row
    },
    reloadData() {

      this.isLoading = true

      let condition = []
      if (!DateUtils.isEmpty(this.dateStart)) condition.push([ this.dateKey, '>', DateUtils.startOfDay(this.dateStart!) ])
      if (!DateUtils.isEmpty(this.dateEnd)) condition.push([ this.dateKey, '<', DateUtils.endOfDay(this.dateEnd!) ])

      this.loader.loadEntriesWhere<DocumentEntry>(this.model, condition)
        .then(entries => {
          const options = []
          for (const c of this.loadConfig) {
            if (c.key && c.model) options.push({ key: c.key, model: c.model })
          }
          const sorted = entries.sort((a, b) => a.date.getTime() - b.date.getTime())
          return this.loader.loadDetails(sorted, options)
            .then(details => { this.tableRows = sorted.map(it => this.createTableRow(it, this.loadConfig, details)) })
        })
        .finally(() => this.isLoading = false)
    },
  },
  created() {

    AppContext.getSafeManager().getCurrentPermissions(perms => {
      this.grantedCreate = (this.editPermission) ? perms.includes(this.editPermission) : false
    })

    this.reloadData()
  },
})
