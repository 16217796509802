import DataManager from './data/DataManager'
import SafeManager from './safe/SafeManager'
import ServerClient from './server/ServerClient'
import SettingsManager from './settings/SettingsManager'

class AppContext {

  private __serverClient?: ServerClient = undefined
  private __dataManager?: DataManager = undefined
  private __safeManager?: SafeManager = undefined
  private __settingsManager?: SettingsManager = undefined

  getServerClient(): ServerClient {
    if (!this.__serverClient) this.__serverClient = new ServerClient()
    return this.__serverClient
  }

  getDataManager(): DataManager {
    if (!this.__dataManager) this.__dataManager = new DataManager(this.getServerClient())
    return this.__dataManager
  }

  getSafeManager(): SafeManager {
    if (!this.__safeManager) this.__safeManager = new SafeManager(this.getDataManager())
    return this.__safeManager
  }

  getSettingsManager(): SettingsManager {
    if (!this.__settingsManager) this.__settingsManager = new SettingsManager(this.getDataManager())
    return this.__settingsManager
  }
}

const sInstance = new AppContext()

// Для загрузки данных по умолчанию
sInstance.getDataManager()
// Для загрузим настроек
sInstance.getSettingsManager()

export default sInstance