
import { defineComponent } from 'vue';
import DefaultPage from '@/use/components/DefaultPage.vue';
import { UIPanel, UIToolbar, UIButton, UIDateField, UIEntryField, UITextField, UITextArea, UIAlertView } from '@/use/base/Types';
import { UITable, UITableRow, UITableCell, UITextCell, UIEntryCell, UIImageButton } from '@/use/base/Types';
import ToDeleteButton from '@/use/components/ToDeleteButton.vue';
import AppContext from '@/core/AppContext';
import * as Permission from '@/core/safe/Permissions'
import { Counterparty, Measure, OrderRecord, Party, Storage } from '@/core/model/Types';
import { DeliveryInventory, DeliveryInventoryRow } from '@/core/model/Types';
import { PurchaseOrder } from '@/core/model/Types';
import DataRequest from '@/core/data/DataRequest';
import ArrayMaster from '@/lib/util/ArrayMaster'
import { PDFBuilder, PDFTable } from '@/lib/pdf/PDFTypes';
import DeliveryInventoryHelper from './DeliveryInventoryHelper';
import EntryLoader from '@/lib/data/EntryLoader';
import EntryMap from '@/lib/data/EntryMap';
import { DateUtils, EntryUtils, NumberUtils, StringUtils, TableUtils } from '@/lib/util/Utils';

export default defineComponent({
  components: {
    DefaultPage, 
    UIPanel, UIToolbar, UIButton, UIDateField, UIEntryField, UITextField, UITextArea, UIAlertView,
    UITable, UITableRow, UITableCell, UITextCell, UIEntryCell, UIImageButton,
    ToDeleteButton
  },
  data() {
    return {
      grantedEdit: false, 
      loader: new EntryLoader(),
      docId: '',
      date: new Date(),
      number: '',
      storage: undefined,
      provider: undefined,
      comment: '',
      tableWidths: TableUtils.headers(m => [m.fixed(40), m.flex(1.5), m.MEASURE, m.AMOUNT, m.SUM, m.flex(1), m.fixed(40)]),
      tableHeaders: ['№', 'Наименование', 'Ед.', 'Кол-во', 'Сумма', 'Заявка', 'X'],
      tableRows: new Array<any>(),
      toDelete: false,
      isLoading: false,
      alert: undefined as any,
    }
  },
  watch: {
    tableRows: {
      deep: true,
      handler(newValue) {
        if (!TableUtils.isContainsEmptyRow(newValue)) {
          this.tableRows.push({ _key: StringUtils.getUUID() })
        }
      },
    }
  },
  methods: {
    table_handleRemoveRow(rowIndex: number) {
      this.tableRows.splice(rowIndex, 1)
    },
    configureView(doc: DeliveryInventory | undefined, rows: DeliveryInventoryRow[]) {
      this.date = (doc) ? doc.date : new Date()
      this.number = (doc) ? doc.number : AppContext.getSettingsManager().getNextNumber(DeliveryInventory.MODEL)
      this.storage = (doc) ? this.loader.data.getDisplayItem(Storage.MODEL, doc.storageId) : undefined
      this.provider = (doc) ? this.loader.data.getDisplayItem(Counterparty.MODEL, doc.providerId) : undefined
      this.comment = (doc) ? doc.comment : ''
      this.tableRows = rows.sort((a, b) => a.index - b.index).map(row => {
        return {
          _key: StringUtils.getUUID(),
          id: row.id,
          inventory: row.inventory,
          measure: row.measure,
          amount: `${row.amount || 0}`,
          sum: `${row.sum || 0}`,
          order: this.loader.data.getDisplayItem(PurchaseOrder.MODEL, row.orderId),
        }
      })
      this.toDelete = (doc) ? Boolean(doc.toDelete) : false
    },
    reloadView() {

      AppContext.getSafeManager().getCurrentPermissions(perms => {
        this.grantedEdit = perms.includes(Permission.DELIVERY_INVENTORY_EDIT)
      })

      this.isLoading = true

      const request = new DataRequest()
      request.addGetAllEntries(Storage.MODEL, Storage.MODEL)
      request.addGetAllEntries(Counterparty.MODEL, Counterparty.MODEL)
      request.addGetAllEntries(Measure.MODEL, Measure.MODEL)

      this.loader.load(request)
        .then(() => {

          if (this.docId === 'new') {
            this.configureView(undefined, [])
          }
          else {

            const request = new DataRequest()
            request.addGetEntriesByIds(DeliveryInventory.MODEL, [this.docId], 'doc')
            request.addGetEntriesWhere(DeliveryInventoryRow.MODEL, [
              [ DeliveryInventoryRow.VAL_DOC_ID, '==', this.docId ]
            ], 'rows')

            return this.$dm.send(request)
              .then(results => {

                const doc = results.getValue('doc').find(() => true)
                if (!doc) throw new Error(`Документ (${this.docId}) не существует!`)

                const rows = (results.getValue('rows') || []) as DeliveryInventoryRow[]

                return this.loader.loadDetails(rows, [
                  { key: DeliveryInventoryRow.VAL_ORDER_ID, model: PurchaseOrder.MODEL },
                ])
                  .then(() => { this.configureView(doc, rows) })
              })
          }
        })
        .catch(msg => {
          console.warn(msg)
          this.$sm.goBack()
        })
        .finally(() => this.isLoading = false)
    },
    tableVariantsGetter(key: string, rowIndex: number, callback: any) { return this.getVariantsGetter(key, rowIndex, callback) },
    storageVariantsGetter(callback: any) { return this.getVariantsGetter('storage', 0, callback) },
    providerVariantsGetter(callback: any) { return this.getVariantsGetter('provider', 0, callback) },
    getVariantsGetter(key: string, rowIndex: number, callback: any) {
      return this.getVariantsPromise(key, rowIndex)
        .then(value => callback(value))
        .catch(() => callback(undefined))
    },
    getPromise(value: any): Promise<any> {
      return new Promise((resolve) => { resolve(value) })
    },
    getVariantsPromise(key: string, rowIndex: number): Promise<any> {
      rowIndex // Типо использовали

      if (key === 'storage') {
        return this.getPromise(this.loader.data.getDisplayItems(Storage.MODEL))
      }
      else if (key === 'provider') {
        return this.getPromise(this.loader.data.getDisplayItems(Counterparty.MODEL))
      }
      else if (key === 'inventory') {
        return this.$dm.getAllEntries(Party.MODEL)
          .then(records => {
            const all = records.map(it => it.inventory.trim())
            return [...new Set(all)].sort()
          })
      }
      else if (key === 'measure') {
        return this.getPromise(this.loader.data.getEntries(Measure.MODEL).map( m => m.name ))
      }
      else if (key === 'order') {

        return this.$dm.getEntriesWhere(
          OrderRecord.MODEL,
          [
            [ OrderRecord.VAL_DATE, '<', this.date ]
          ]
        )
          .then(records => {
            const orderIds = new ArrayMaster(records)
              .map(it => ({ orderId: it.orderId, amount: it.amount }))
              .group(['orderId'], ['amount'])
              .filter(it => it.amount > 0)
              .map(it => it.orderId).get()

            return this.loader.loadEntriesByIds(PurchaseOrder.MODEL, orderIds)
              .then(entries => {
                return new ArrayMaster(entries)
                  .map(it => ({ id: it.id, name: it.name }))
                  .sortEntries().get()
              })
          })
      } 
      else {
        return this.getPromise(undefined)
      }
    },
    prepareSaveData(): any {

      let docId = (this.docId === 'new') ? this.$dm.newEntryId() : this.docId

      const slaveObjects: { [name: string]: any[] } = {}
      
      let providerId = EntryUtils.getId(this.provider)
      if (!providerId) {
        const providerName = EntryUtils.getName(this.provider)
        if (providerName) {
          const obj = Counterparty.new({ [Counterparty.VAL_NAME]: providerName })
          providerId = obj.id
          slaveObjects[Counterparty.MODEL] = [obj]
        }
      }

      const doc = DeliveryInventory.new({
        [DeliveryInventory.VAL_ID]: docId,
        [DeliveryInventory.VAL_DATE]: this.date,
        [DeliveryInventory.VAL_NUMBER]: this.number,
        [DeliveryInventory.VAL_STORAGE_ID]: EntryUtils.getId(this.storage),
        [DeliveryInventory.VAL_PROVIDER_ID]: providerId,
        [DeliveryInventory.VAL_COMMENT]: this.comment,
        [DeliveryInventory.VAL_TODELETE]: this.toDelete
      })

      const rows = TableUtils.getFilledRows(this.tableRows).map(row => {
        return DeliveryInventoryRow.new({
          [DeliveryInventoryRow.VAL_ID]: row.id,
          [DeliveryInventoryRow.VAL_INVENTORY]: row.inventory,
          [DeliveryInventoryRow.VAL_MEASURE]: row.measure,
          [DeliveryInventoryRow.VAL_AMOUNT]: Number(row.amount),
          [DeliveryInventoryRow.VAL_SUM]: Number(row.sum),
          [DeliveryInventoryRow.VAL_ORDER_ID]: EntryUtils.getId(row.order),
        })
      })

      return { doc: doc, rows: rows, slaveObjects: slaveObjects }
    },
    handleOkClick() {
      const d = this.prepareSaveData()
      DeliveryInventoryHelper.save(d.doc, d.rows, d.slaveObjects, this.loader.data as EntryMap)
        .then(() => {
          AppContext.getSettingsManager().registryLastNumber(DeliveryInventory.MODEL, this.number)
          this.$sm.goBack()
        })
        .catch(msg => this.alert = { title: 'Ошибка', text: msg })
    },
    handleSaveClick() {
      const d = this.prepareSaveData()
      DeliveryInventoryHelper.save(d.doc, d.rows, d.slaveObjects, this.loader.data as EntryMap)
        .then(() => {
          if (this.docId === 'new') {
            AppContext.getSettingsManager().registryLastNumber(DeliveryInventory.MODEL, this.number)
            // Изменим url
            this.docId = d.doc.id
            this.$sm.goToObjectEditor(DeliveryInventory.MODEL, d.doc.id)
          }
          this.reloadView()
          this.alert = { text: 'Сохранено!' }
        })
        .catch(msg => this.alert = { title: 'Ошибка', text: msg })
    },
    handlePrintClick() {

      const printTableHeaders = TableUtils.headers(m => [
        { name: '№', width: m.fixed(40), alignment: 'right' },
        { name: 'Наименование', width: m.flex(1.5) },
        { name: 'Ед.', width: m.MEASURE },
        { name: 'Кол-во', width: m.AMOUNT, alignment: 'right' },
        { name: 'Сумма', width: m.CURRENCY, alignment: 'right' },
        { name: 'Заявка', width: m.flex(1) },
      ])
      
      const table = new PDFTable()
      table.setColumnWidths(printTableHeaders.map(it => it.width))
      table.setColumnAligment(printTableHeaders.map(it => it.alignment || 'left'))
      table.setHeaders(printTableHeaders.map(it => it.name))
      TableUtils.getFilledRows(this.tableRows).forEach((row, i) => {
        table.addRow([
          `${i + 1}`,
          row.inventory,
          row.measure,
          NumberUtils.formatedNumber(row.amount),
          NumberUtils.formatedCurrency(row.sum),
          EntryUtils.getName(row.order)
        ])
      })

      const builder = new PDFBuilder('landscape')
      builder.addTitle(
        [ 'НАКЛАДНАЯ', `№ ${this.number} от ${DateUtils.formatedDate(this.date)}` ],
        { margin: [ 0, 0, 0, 20 ] }
      )
      builder.addTable(table)
      builder.build()
    },
    handleRecordsClick() {
      this.$sm.goToDocRecords(DeliveryInventory.MODEL, this.docId)
    },
    handleHistoryClick() {
      this.$sm.goToDocHistory(DeliveryInventory.MODEL, this.docId)
    },
  },
  created() {
    this.docId = this.$sm.getCurrentParam('id')
    this.reloadView()
  },
})
