import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3135aa2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-text-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextField = _resolveComponent("UITextField")!

  return (_openBlock(), _createElementBlock("td", _hoisted_1, [
    _createVNode(_component_UITextField, {
      "element-id": _ctx.elementId,
      "field-style": "plain",
      readonly: _ctx.readonly,
      alignment: _ctx.alignment,
      value: _ctx.inputValue,
      "onUpdate:value": _ctx.outputValue,
      variants: _ctx.variants
    }, null, 8, ["element-id", "readonly", "alignment", "value", "onUpdate:value", "variants"])
  ]))
}