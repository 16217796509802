
import { defineComponent } from 'vue';
import DefaultPage from '@/use/components/DefaultPage.vue';
import { UIPanel, UIToolbar, UIButton, UITextField, UIAlertView } from '@/use/base/Types';
import ToDeleteButton from '@/use/components/ToDeleteButton.vue';
import { Counterparty } from '@/core/model/Types';
import AppContext from '@/core/AppContext'
import * as Permission from '@/core/safe/Permissions'

export default defineComponent({
  components: {
    DefaultPage, 
    UIPanel, UIToolbar, UIButton, UITextField, UIAlertView,
    ToDeleteButton
  },
  data() {
    return {
      grantedEdit: false,
      displayName: '',
      toDelete: false,
      isLoading: false,
      alert: undefined as any,
    }
  },
  computed: {
    objId(): string { return this.$sm.getCurrentParam('id') },
  },
  methods: {
    configureView(entry: Counterparty) {
      this.displayName = entry.name
      this.toDelete = Boolean(entry.toDelete)
    },
    handleSaveClick() {

      if (!this.displayName) {
        this.alert = { title: 'Ошибка', text: ['Заполните реквизиты:', '"Название"'] }
        return
      }

      this.$dm.getEntryById(Counterparty.MODEL, this.objId)
        .then(storedEntry => {
          let entry = storedEntry || Counterparty.new(this.objId)
          // Обновляем объект
          entry.setValues({
            [Counterparty.VAL_NAME]: this.displayName,
            [Counterparty.VAL_TODELETE]: this.toDelete
          })
          // Сохраняем объект
          return this.$dm.putEntry(Counterparty.MODEL, entry)
            .then(() => this.$sm.goBack())
        })
    },
  },
  created() {

    AppContext.getSafeManager().getCurrentPermissions(perms => {
      this.grantedEdit = perms.includes(Permission.COUNTERPARTY_EDIT)
    })

    this.isLoading = true
    this.$dm.getEntryById(Counterparty.MODEL, this.objId)
      .then(storedEntry => {
        let entry = storedEntry || Counterparty.new(this.objId)
        this.configureView(entry)
      })
      .catch(() => this.$sm.goBack())
      .finally(() => this.isLoading = false)
  }
})
