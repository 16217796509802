
import { defineComponent } from 'vue';

export default defineComponent({
  props: ['path', 'enable'],
  data() {
    return {
      name: ''
    }
  },
  created() {
    this.$sm.getNameOfPath(this.path, it => this.name = it)
  }
})
