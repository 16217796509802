import AppContext from "@/core/AppContext"
import DataRequest from "@/core/data/DataRequest";
import DataResults from "@/core/data/DataResults";
import StoredEntry from "@/core/model/StoredEntry";
import EntryMap from "./EntryMap";

export default class EntryLoader {

  private mData: EntryMap

  constructor() {
    this.mData = new EntryMap()
  }

  get data(): EntryMap {
    return this.mData
  }

  // Private

  private send(request: DataRequest): Promise<DataResults> {
    return AppContext.getDataManager().send(request)
  }

  // Load

  async load(request: DataRequest): Promise<EntryMap> {
    const results = await AppContext.getDataManager().send(request)
    const map = results.toEntryMap()
    this.mData.add(map)
    return map
  }

  async loadAllEntries<T>(model: string): Promise<T[]> {

    const request = new DataRequest()
    request.addGetAllEntries(model, model)

    const map = await this.load(request)
    return map.getEntries(model)
  }

  async loadEntriesByIds<T>(model: string, ids: string[]): Promise<T[]> {

    const request = new DataRequest()
    request.addGetEntriesByIds(model, ids, model)

    const map = await this.load(request)
    return map.getEntries(model)
  }

  async loadEntriesWhere<T>(model: string, condition: any[]): Promise<T[]> {

    const request = new DataRequest()
    request.addGetEntriesWhere(model, condition, model)

    const map = await this.load(request)
    return map.getEntries(model)
  }

  async loadDetails(entries: StoredEntry[], options: any[]): Promise<EntryMap> {
    
    const entryIndexes = new Map<string, Set<string>>()
    for (const entry of entries) {
      for (const opt of options) {
        const value = entry.getValue(opt.key)
        if (value) {
          const indexes = entryIndexes.get(opt.model) || new Set<string>()
          indexes.add(value)
          entryIndexes.set(opt.model, indexes)
        }
      }
    }

    if (entryIndexes.size === 0) {
      return new Promise((resolve) => {
        resolve(new EntryMap())
      })
    } 

    const request = new DataRequest()
    for (const model of entryIndexes.keys()) {
      const indexes = Array.from<string>(entryIndexes.get(model)!)
      request.addGetEntriesByIds(model, indexes, model)
    }

    return this.load(request)
  }
}