import StoredEntry from "../StoredEntry"
import EntryHelper from "../EntryHelper"

// Удалить
export default class Inventory extends StoredEntry {
  static MODEL = 'g6jl'

  static VAL_NAME = 'zg1k'

  constructor(rawData?: any) {
    super(rawData)
  }

  get name(): string { return this.getValue(Inventory.VAL_NAME) }

  static new(value: any): Inventory {
    return EntryHelper.fillEntry(new Inventory(), value)
  }
}