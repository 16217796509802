import StoredEntry from "../StoredEntry"
import Property, { NumberProperty, StringProperty } from "../Property"

export default class DocumentRow extends StoredEntry {

  static VAL_DOC_ID = 'yg0e'
  static VAL_INDEX = 'xu6f'

  static PROPS: Property[] = StoredEntry.PROPS.concat([
    new StringProperty(this.VAL_DOC_ID), new NumberProperty(this.VAL_INDEX)
  ])

  // ---

  constructor(rawData: any) {
    super(rawData)
  }

  get name(): string { return `Строка ${(this.index || 0) + 1}` }
  
  get docId(): string { return this.getValue(DocumentRow.VAL_DOC_ID) }
  get index(): number { return this.getValue(DocumentRow.VAL_INDEX) || 0 }
}