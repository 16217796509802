import { HistoryRecord } from "@/core/model/Types"

export default class HistoryBuilder {

  private _employeeId: string
  private _objModel: string
  private _objId: string
  private _list: Array<any>

  constructor(employeeId: string, objModel: string, objId: string) {
    this._employeeId = employeeId
    this._objModel = objModel
    this._objId = objId
    this._list = []
  }

  pushDetails(details: string) {
    this._list.push(HistoryRecord.new({
      [HistoryRecord.VAL_DATE]: new Date(),
      [HistoryRecord.VAL_EMPLOYEE_ID]: this._employeeId,
      [HistoryRecord.VAL_OBJECT_MODEL]: this._objModel,
      [HistoryRecord.VAL_OBJECT_ID]: this._objId,
      [HistoryRecord.VAL_DETAILS]: details
    }))
  }

  pushChange(name: string, oldValue: string, newValue: string) {
    this.pushDetails(`${name}: "${oldValue ? oldValue : ''}" => "${newValue ? newValue : ''}"`)
  }

  pushTest(name: string, oldValue: string, newValue: string) {
    if (newValue !== oldValue) this.pushChange(name, oldValue, newValue)
  }

  isEmpty(): boolean {
    return (this._list.length == 0)
  }

  getList(): any[] {
    return this._list
  }
}