import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_UISearchField = _resolveComponent("UISearchField")!
  const _component_UIToolbar = _resolveComponent("UIToolbar")!
  const _component_UITableCell = _resolveComponent("UITableCell")!
  const _component_UITableRow = _resolveComponent("UITableRow")!
  const _component_UITable = _resolveComponent("UITable")!
  const _component_UIPanel = _resolveComponent("UIPanel")!
  const _component_DefaultPage = _resolveComponent("DefaultPage")!

  return (_openBlock(), _createBlock(_component_DefaultPage, null, {
    default: _withCtx(() => [
      _createVNode(_component_UIPanel, {
        title: _ctx.panelTitle,
        isLoading: _ctx.isLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_UIToolbar, { class: "toolbar" }, {
            left: _withCtx(() => [
              _createVNode(_component_UIButton, {
                onOnClick: _ctx.handleCreateClick,
                disabled: !_ctx.grantedCreate
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Создать")
                ]),
                _: 1
              }, 8, ["onOnClick", "disabled"]),
              _createVNode(_component_UISearchField, {
                label: "Поиск:",
                placeholder: "Фильтр по списку",
                value: _ctx.filter,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event))
              }, null, 8, ["value"])
            ]),
            right: _withCtx(() => [
              _createVNode(_component_UIButton, { onOnClick: _ctx.handlePrintClick }, {
                default: _withCtx(() => [
                  _createTextVNode("Печать")
                ]),
                _: 1
              }, 8, ["onOnClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_UITable, {
            headers: _ctx.internalTableHeaders,
            widths: _ctx.internalTableWidths
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayTableRows, (row, rowIndex) => {
                return (_openBlock(), _createBlock(_component_UITableRow, {
                  key: `r${rowIndex}`
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (col, colIndex) => {
                      return (_openBlock(), _createBlock(_component_UITableCell, {
                        key: `c${colIndex}`,
                        onDblclick: ($event: any) => (_ctx.handleDoubleClick(row))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            class: _normalizeClass((row.toDelete == true) ? 'todelete' : '')
                          }, _toDisplayString(row[col.key]), 3)
                        ]),
                        _: 2
                      }, 1032, ["onDblclick"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["headers", "widths"])
        ]),
        _: 1
      }, 8, ["title", "isLoading"])
    ]),
    _: 1
  }))
}