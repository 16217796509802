
import { defineComponent } from 'vue';
import AppContext from "@/core/AppContext";
import { UIButton } from '@/use/base/Types';

export default defineComponent({
  components: {
    UIButton,
  },
  data() {
    return {
      login: '',
      password: '',
    }
  },
  methods: {
    handleLogin() {
      let am = AppContext.getSafeManager()
      am.login(this.login, this.password, success => {
        if (success) {
          this.$sm.goToHome()
        } else {
          alert('Неверные имя пользователя или пароль')
        }
      })
    },
  },
})
