
import { Component, defineComponent } from 'vue';
import DefaultPage from '@/use/components/DefaultPage.vue';
import { UIPanel, UIButton } from '@/use/base/Types';
import DataRequest from '@/core/data/DataRequest';
import { Counterparty, DeliveryInventory, DeliveryInventoryRow, Employee, Equipment, Measure, Party, PurchaseOrder, Storage, TransferInventory, TransferInventoryRow, WriteOffInventory, WriteOffInventoryRow } from '@/core/model/Types';
import DeliveryInventoryHelper from '../doc/DeliveryInventoryHelper';
import EntryLoader from '@/lib/data/EntryLoader';
import AppContext from "@/core/AppContext"
import TransferInventoryHelper from '../doc/TransferInventoryHelper';
import WriteOffInventoryHelper from '../doc/WriteOffInventoryHelper';
import * as Permission from '@/core/safe/Permissions'

class Operation {
  
  readonly name: string
  readonly command: Function
  readonly enabled: boolean
  actived: boolean = false
  state: string = ""

  constructor(name: string, enabled: boolean, command: Function) {
    this.name = name
    this.command = command
    this.enabled = enabled
  }

  perform(vue: Component, callback: () => void) {
    this.actived = true
    this.state = "Execution ..."
    this.command(vue, (result: boolean) => {
      this.actived = false
      this.state = (result) ? "Completed!" : "Error!"
      callback()
    })
  }
}

const OPERATIONS = [
  new Operation('Перепроведение поступлений', true, 
    (vue: Component, callback: (_: boolean) => void) => {

      const loader = new EntryLoader()
      const request = new DataRequest()

      request.addGetAllEntries(DeliveryInventory.MODEL, DeliveryInventory.MODEL)
      request.addGetAllEntries(DeliveryInventoryRow.MODEL, DeliveryInventoryRow.MODEL)

      request.addGetAllEntries(Storage.MODEL, Storage.MODEL)
      request.addGetAllEntries(Counterparty.MODEL, Counterparty.MODEL)
      request.addGetAllEntries(Employee.MODEL, Employee.MODEL)
      request.addGetAllEntries(Measure.MODEL, Measure.MODEL)
      request.addGetAllEntries(PurchaseOrder.MODEL, PurchaseOrder.MODEL)

      loader.load(request)
        .then(map => {

          const allDocs = (map.getEntries(DeliveryInventory.MODEL) as DeliveryInventory[])
            .sort((a, b) => a.date.getTime() - b.date.getTime())
          const allRows = map.getEntries(DeliveryInventoryRow.MODEL) as DeliveryInventoryRow[]

          function done() {

            const doc = allDocs.shift()
            if (!doc) {
              callback(true)
              return
            }

            const rows = allRows.filter(it => it.docId === doc.id)

            DeliveryInventoryHelper.save(doc, rows, { }, map)
              .then(() => { 
                console.warn(`complete: ${doc.name}`)
                done() 
              })
              .catch(msg => {
                console.error(`error: ${doc.name}`, msg)
                callback(false)
              })
          }
          done()
        })
    }
  ),
  new Operation('Перепроведение перемещений', true, 
    (vue: Component, callback: (_: boolean) => void) => {

      const loader = new EntryLoader()
      const request = new DataRequest()

      request.addGetAllEntries(TransferInventory.MODEL, TransferInventory.MODEL)
      request.addGetAllEntries(TransferInventoryRow.MODEL, TransferInventoryRow.MODEL)

      request.addGetAllEntries(Storage.MODEL, Storage.MODEL)
      request.addGetAllEntries(Employee.MODEL, Employee.MODEL)
      request.addGetAllEntries(Party.MODEL, Party.MODEL)
      request.addGetAllEntries(Measure.MODEL, Measure.MODEL)
      request.addGetAllEntries(PurchaseOrder.MODEL, PurchaseOrder.MODEL)

      loader.load(request)
        .then(map => {

          const allDocs = (map.getEntries(TransferInventory.MODEL) as TransferInventory[])
            .sort((a, b) => a.date.getTime() - b.date.getTime())
          const allRows = map.getEntries(TransferInventoryRow.MODEL) as TransferInventoryRow[]

          function done() {

            const doc = allDocs.shift()
            if (!doc) {
              callback(true)
              return
            }

            const rows = allRows.filter(it => it.docId === doc.id)

            TransferInventoryHelper.save(doc, rows, map)
              .then(() => { 
                console.warn(`complete: ${doc.name}`)
                done() 
              })
              .catch(msg => {
                console.error(`error: ${doc.name}`, msg)
                callback(false)
              })
          }
          done()
        })
    }
  ),
  new Operation('Перепроведение списаний', true, 
    (vue: Component, callback: (_: boolean) => void) => {

      const loader = new EntryLoader()
      const request = new DataRequest()

      request.addGetAllEntries(WriteOffInventory.MODEL, WriteOffInventory.MODEL)
      request.addGetAllEntries(WriteOffInventoryRow.MODEL, WriteOffInventoryRow.MODEL)

      request.addGetAllEntries(Storage.MODEL, Storage.MODEL)
      request.addGetAllEntries(Employee.MODEL, Employee.MODEL)
      request.addGetAllEntries(Party.MODEL, Party.MODEL)
      request.addGetAllEntries(PurchaseOrder.MODEL, PurchaseOrder.MODEL)
      request.addGetAllEntries(Equipment.MODEL, Equipment.MODEL)
      request.addGetAllEntries(Measure.MODEL, Measure.MODEL)

      loader.load(request)
        .then(map => {

          const allDocs = (map.getEntries(WriteOffInventory.MODEL) as WriteOffInventory[])
            .sort((a, b) => a.date.getTime() - b.date.getTime())
          const allRows = map.getEntries(WriteOffInventoryRow.MODEL) as WriteOffInventoryRow[]

          function done() {

            const doc = allDocs.shift()
            if (!doc) {
              callback(true)
              return
            }

            const rows = allRows.filter(it => it.docId === doc.id)

            WriteOffInventoryHelper.save(doc, rows, map)
              .then(() => { 
                console.warn(`complete: ${doc.name}`)
                done() 
              })
              .catch(msg => {
                console.error(`error: ${doc.name}`, msg)
                callback(false)
              })
          }
          done()
        })
    }
  ),
]

export default defineComponent({
  components: {
    DefaultPage, 
    UIPanel, UIButton
  },
  data() {
    return {
      granted: false,
      enabled: true,
      operations: OPERATIONS
    }
  },
  methods: {
    performOperation(op: Operation) {
      console.warn(`> start operation: "${op.name}"`)
      op.perform(this, () => {
        console.warn(`> finish operation: "${op.name}"`)
      })
    },
  },
  created() {
    AppContext.getSafeManager().getCurrentPermissions(perms => {
      this.granted = perms.includes(Permission.ADMINISTRATOR)
    })
  }
})
