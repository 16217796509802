import DataManager from '../data/DataManager'
import DataRequest from '../data/DataRequest'
import * as ServerKey from '../server/ServerKeys'
import * as SettingsKey from './SettingsKeys'

export default class SettingsManager {

  private __dm: DataManager
  private __ln: any = {}
  private __pm: any = {}

  constructor(dataManager: DataManager) {
    this.__dm = dataManager
    this.__ln = {}
    this.__pm = {}
    this.__loadSettings()
  }

  private __loadSettings() {

    const request = new DataRequest()
    request.addOperation(ServerKey.OPERATION_GET_VALUE, { [ServerKey.QUERY_KEY]: SettingsKey.VAL_LAST_NUMBERS }, 'ln')
    request.addOperation(ServerKey.OPERATION_GET_VALUE, { [ServerKey.QUERY_KEY]: SettingsKey.VAL_PARAMS }, 'pm')

    this.__dm.send(request)
      .then(results => {
        try {
          this.__ln = JSON.parse(results.getValue('ln'))
        } catch (error) {
          console.warn('tbs1, ' + error)
        }
        try {
          this.__pm = JSON.parse(results.getValue('pm'))
        } catch (error) {
          console.warn('s18b, ' + error)
        }
        setTimeout(() => this.__loadSettings(), 5 * 60 * 1000)
      })
  }

  private __sendLastNumbers() {
    const request = new DataRequest()
    request.addOperation(ServerKey.OPERATION_SET_VALUE, {
      [ServerKey.QUERY_KEY]: SettingsKey.VAL_LAST_NUMBERS,
      [ServerKey.QUERY_VALUE]: JSON.stringify(this.__ln)
    })
    this.__dm.send(request)
  }

  private __sendParams() {
    const request = new DataRequest()
    request.addOperation(ServerKey.OPERATION_SET_VALUE, {
      [ServerKey.QUERY_KEY]: SettingsKey.VAL_PARAMS,
      [ServerKey.QUERY_VALUE]: JSON.stringify(this.__pm)
    })
    this.__dm.send(request)
  }

  private __getLastNumber(model: string): number {
    const info = this.__ln[model]
    if (info) {
      const lastYear = info[SettingsKey.LAST_NUMBER_YEAR]
      return (lastYear === new Date().getFullYear()) ? info[SettingsKey.LAST_NUMBER_VALUE] : 0
    } else {
      return 0
    }
  }

  private __setLastNumber(model: string, number: number) {
    this.__ln[model] = {
      [SettingsKey.LAST_NUMBER_YEAR]: new Date().getFullYear(),
      [SettingsKey.LAST_NUMBER_VALUE]: number
    }
  }

  getSettings(): any {

    if (!this.__ln || !this.__pm) {
      console.warn('Settings is undefined!')
      return {}
    }

    const lastNumbers: { [name: string]: any } = {}
    for (const model in this.__ln) {
      lastNumbers[model] = this.__ln[model][SettingsKey.LAST_NUMBER_VALUE]
    }

    const out = { ...this.__pm }
    out[SettingsKey.LAST_NUMBERS] = lastNumbers

    return out
  }

  setSettings(values: any) {

    const lastNumbers = values[SettingsKey.LAST_NUMBERS]
    if (lastNumbers) {
      for (const model in lastNumbers) {
        this.__setLastNumber(model, lastNumbers[model])
      }
    }
    this.__sendLastNumbers()

    this.__pm[SettingsKey.COMPANY_FULL_NAME] = values[SettingsKey.COMPANY_FULL_NAME]
    this.__pm[SettingsKey.DIRECTOR_SHORT_NAME] = values[SettingsKey.DIRECTOR_SHORT_NAME]
    this.__pm[SettingsKey.CHIEF_ENGINEER_SHORT_NAME] = values[SettingsKey.CHIEF_ENGINEER_SHORT_NAME]
    this.__sendParams()
  }

  getParam(key: string): any {
    if (this.__pm) {
      return this.__pm[key]
    } else {
      console.warn('Settings is undefined!')
      return undefined
    }
  }

  getLastNumber(model: string): number {
    if (this.__ln) {
      return this.__getLastNumber(model)
    } else {
      console.warn('Settings is undefined!')
      return 0
    }
  }

  setLastNumber(model: string, number: number) {
    if (this.__ln) {
      return this.__setLastNumber(model, number)
    } else {
      console.warn('Settings is undefined!')
    }
  }

  getNextNumber(model: string): string {
    
    if (!this.__ln) {
      console.warn('Settings is undefined!')
      return '0'
    }

    return '' + (this.__getLastNumber(model) + 1)
  }

  registryLastNumber(model: string, value: string) {
    
    if (!this.__ln) {
      console.warn('Settings is undefined!')
      return
    }

    const match = value.match(/\d+/)
    if (match) {
      const n = parseInt(match[0], 10)
      if (n) {
        this.__setLastNumber(model, n)
        this.__sendLastNumbers()
      }
    }
  }
}