import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIImageButton = _resolveComponent("UIImageButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.value)
      ? (_openBlock(), _createBlock(_component_UIImageButton, {
          key: 0,
          type: "submit",
          role: "destructive",
          disabled: _ctx.disabled,
          onClick: _ctx.handleClick,
          name: "restore_from_trash"
        }, null, 8, ["disabled", "onClick"]))
      : _createCommentVNode("", true),
    (!_ctx.value)
      ? (_openBlock(), _createBlock(_component_UIImageButton, {
          key: 1,
          type: "bordered",
          role: "destructive",
          disabled: _ctx.disabled,
          onClick: _ctx.handleClick,
          name: "delete"
        }, null, 8, ["disabled", "onClick"]))
      : _createCommentVNode("", true)
  ]))
}