import DocumentRow from "./DocumentRow"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, NumberProperty, StringProperty } from "../Property"
import PurchaseOrder from "./PurchaseOrder"
import PurchaseOrderRow from "./PurchaseOrderRow"

export default class ClosingOrderRow extends DocumentRow {
  static MODEL = 'bi2k'

  static VAL_ORDER_ID = 'oz4b'
  static VAL_ORDER_ROW_ID = 'w4cu'
  static VAL_AMOUNT = 'hs0f'
  static VAL_COMMENT = 'wi3p'

  static PROPS: Property[] = DocumentRow.PROPS.concat([
    new IDProperty(this.VAL_ORDER_ID, PurchaseOrder.MODEL), new IDProperty(this.VAL_ORDER_ROW_ID, PurchaseOrderRow.MODEL),
    new NumberProperty(this.VAL_AMOUNT), new StringProperty(this.VAL_COMMENT)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get orderId(): string { return this.getValue(ClosingOrderRow.VAL_ORDER_ID) }
  get orderRowId(): string { return this.getValue(ClosingOrderRow.VAL_ORDER_ROW_ID) }
  get amount(): number { return this.getValue(ClosingOrderRow.VAL_AMOUNT) }
  get comment(): string { return this.getValue(ClosingOrderRow.VAL_COMMENT) }

  static new(value: any): ClosingOrderRow {
    return EntryHelper.fillEntry(new ClosingOrderRow(), value)
  }
}