import RegistryRecord from "./RegistryRecord"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, NumberProperty, StringProperty } from "../Property"
import Equipment from "../unit/Equipment"

export default class EquipmentRecord extends RegistryRecord {
  static MODEL = 'x3jf'

  static VAL_DOC_MODEL = 'hw7j'
  static VAL_DOC_ID = 'i3vl'
  static VAL_EQUIPMENT_ID = 'k6wp'
  static VAL_INVENTORY = 'r4ss'
  static VAL_MEASURE = 'x0cv'
  static VAL_AMOUNT = 'zm9g'
  static VAL_SUM = 'a6pn'
  // static VAL_INVENTORY_ID = 'bj0c' // Удалить
  // static VAL_MEASURE_ID = 'wz8n' // Удалить

  static PROPS: Property[] = RegistryRecord.PROPS.concat([
    new StringProperty(this.VAL_DOC_MODEL), new StringProperty(this.VAL_DOC_ID),
    new IDProperty(this.VAL_EQUIPMENT_ID, Equipment.MODEL), new StringProperty(this.VAL_INVENTORY),
    new StringProperty(this.VAL_MEASURE), new NumberProperty(this.VAL_AMOUNT),
    new NumberProperty(this.VAL_SUM),
    // new IDProperty(this.VAL_INVENTORY_ID, Inventory.MODEL), new IDProperty(this.VAL_MEASURE_ID, Measure.MODEL)
  ])

  // ---

  constructor(data?: any) {
    super(data)
  }

  get docModel(): string { return this.getValue(EquipmentRecord.VAL_DOC_MODEL) }
  get docId(): string { return this.getValue(EquipmentRecord.VAL_DOC_ID) }
  get equipmentId(): string { return this.getValue(EquipmentRecord.VAL_EQUIPMENT_ID) }
  get inventory(): string { return this.getValue(EquipmentRecord.VAL_INVENTORY) }
  get measure(): string { return this.getValue(EquipmentRecord.VAL_MEASURE) }
  get amount(): number { return this.getValue(EquipmentRecord.VAL_AMOUNT) }
  get sum(): number { return this.getValue(EquipmentRecord.VAL_SUM) }
  // get inventoryId(): string { return this.getValue(EquipmentRecord.VAL_INVENTORY_ID) }
  // get measureId(): string { return this.getValue(EquipmentRecord.VAL_MEASURE_ID) }

  static new(value: any): EquipmentRecord {
    return EntryHelper.fillEntry(new EquipmentRecord(), value)
  }
}