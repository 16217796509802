
export default class LocalStorageEx {

  static setString(key: string, value: string | undefined) {
    if (value) {
      localStorage.setItem(key, value)
    } else {
      localStorage.removeItem(key)
    }
  }

  static getString(key: string): string {
    return localStorage.getItem(key) || ''
  }

  static setNumber(key: string, value: number | undefined) {
    if (value) {
      localStorage.setItem(key, '' + value)
    } else {
      localStorage.removeItem(key)
    }
  }

  static getNumber(key: string): number {
    const text = localStorage.getItem(key)
    return (text) ? Number(text) : 0
  }

  static setDate(key: string, value: Date | undefined) {
    if (value) {
      localStorage.setItem(key, '' + value.getTime())
    } else {
      localStorage.removeItem(key)
    }
  }

  static getDate(key: string): Date | undefined {
    const text = localStorage.getItem(key)
    return (text) ? new Date(Number(text)) : undefined
  }

  static setEntry(key: string, value: any) {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.removeItem(key)
    }
  }

  static getEntry(key: string): any {
    const text = localStorage.getItem(key)
    return (text) ? JSON.parse(text) : null
  }
}