import { createApp } from 'vue'
import withUUID from 'vue-uuid'
import App from './App.vue'
import router from "./router"
import SiteManager from './use/SiteManager'
import DataManager from './core/data/DataManager'
import AppContext from './core/AppContext'

const app = withUUID(createApp(App));
app.use(router);
app.mount("#app");

app.mixin({
  created() {
    this.$sm = new SiteManager(this)
    this.$dm = AppContext.getDataManager()
  },
})

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $sm: SiteManager
    $dm: DataManager
  }
}