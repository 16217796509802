
import { defineComponent } from 'vue';
import DefaultPage from "@/use/components/DefaultPage.vue";
import { UIPanel, UITable, UITableRow, UITableCell, UIButton, UIEntryField, UIDateField } from "@/use/base/Types";
import { Storage, PurchaseOrder, Place, Employee, PartyRecord, Party } from '@/core/model/Types';
import DataRequest from '@/core/data/DataRequest';
import ListOfDocsView from './ListOfDocsView.vue';
import { PDFBuilder, PDFTable, PDFText } from '@/lib/pdf/PDFTypes';
import LocalStorageEx from '@/lib/util/LocalStorageEx';
import EntryLoader from '@/lib/data/EntryLoader';
import { DateUtils, EntryUtils, NumberUtils, TableUtils } from '@/lib/util/Utils';

function group_records_by_party(records: PartyRecord[], dateS: Date) {

  const map = new Map()
  for (const r of records) {
    const key = `${r.partyId}`

    const group = map.get(key) || {
      partyId: r.partyId,
      amount0: 0,
      amountInc: 0,
      amountDec: 0,
      records: []
    }
    if (r.date < dateS) {
      group.amount0 += r.amount
    } else if (r.amount > 0) {
      group.amountInc += r.amount
      group.records.push(r)
    } else {
      group.amountDec -= r.amount
      group.records.push(r)
    }
    map.set(key, group)
  }

  const groups = new Array<any>()
  map.forEach(gr => {
    if (gr.amount0 !== 0 || gr.amountInc !== 0 || gr.amountDec !== 0) groups.push(gr)
  })

  return groups
}

function create_rows(groups: any[], data: any): any[] {
  return groups.map(gr => {
    
    const party = data.getEntryById(Party.MODEL, gr.partyId)

    let partyName: string = ''
    if (party) {
      partyName = [
        party.inventory,
        `${NumberUtils.formatedNumber(party.amount)} ${party.measure}`,
        NumberUtils.formatedCurrency(party.sum)
      ].join(' / ').trim()
    } else {
      partyName = data.getDisplayName(Party.MODEL, gr.partyId)
    }

    const order = (party) ? data.getEntryById(PurchaseOrder.MODEL, party.orderId) : undefined

    let targetName: string = ''
    if (order) {
      targetName = [
        order.name,
        data.getDisplayName(Place.MODEL, order.placeId),
        data.getDisplayName(Employee.MODEL, order.declarerId)
      ].join(' / ')
    }

    return {
      party: partyName,
      target: targetName,
      amount0: gr.amount0,
      amountInc: gr.amountInc,
      amountDec: gr.amountDec,
      records: gr.records,
      orderId: (order) ? order.id : undefined,
    }
  }).sort((a, b) => a.party.localeCompare(b.party))
}

export default defineComponent({
  components: {
    DefaultPage,
    UIPanel, UITable, UITableRow, UITableCell,
    UIButton, UIEntryField, UIDateField,
    ListOfDocsView
  },
  data() {
    return {
      loader: new EntryLoader(),
      dateStart: LocalStorageEx.getDate('lw7e'),
      dateEnd: LocalStorageEx.getDate('n4e8'),
      storage: LocalStorageEx.getEntry('wg1t'),
      tableHeaders: ['Партия', 'Остаток на начало', 'Приход', 'Расход', 'Останок на конец'],
      tableWidths: TableUtils.headers(m => [m.flex(1), m.fixed(140), m.fixed(140), m.fixed(140), m.fixed(140)]),
      tableRows: new Array<any>(),
      isLoading: false,
      popupListOfDocs: []
    }
  },
  watch: {
    dateStart(newValue) { 
      LocalStorageEx.setDate('lw7e', DateUtils.correctDate(newValue))
      this.reloadData() 
    },
    dateEnd(newValue) { 
      LocalStorageEx.setDate('n4e8', DateUtils.correctDate(newValue))
      this.reloadData() 
    },
    storage(newValue) { 
      LocalStorageEx.setEntry('wg1t', newValue)
      this.reloadData() 
    },
  },
  methods: {
    storageVariantsGetter(callback: any) {
      callback(this.loader.data.getDisplayItems(Storage.MODEL))
    },
    formatedNumber(v: number) { return (v) ? NumberUtils.formatedNumber(v) : '' },
    formatedCurrency(v: number) { return (v) ? NumberUtils.formatedCurrency(v) : '' },
    handlePrintClick() {
      
      const table = new PDFTable()
      table.setColumnWidths(this.tableWidths)
      table.setColumnAligment(['left', 'right', 'right', 'right', 'right'])
      table.setHeaders(this.tableHeaders)
      for (const row of this.tableRows) {
        table.addRow([
          [row.party, new PDFText(row.target, 'subtitle')],
          this.formatedNumber(row.amount0),
          this.formatedNumber(row.amountInc),
          this.formatedNumber(row.amountDec),
          this.formatedNumber(row.amount0 + row.amountInc - row.amountDec)
        ])
      }

      const builder = new PDFBuilder('landscape')
      builder.addTitle(
        [
          'Остатки по складу',
          EntryUtils.getName(this.storage),
          `с ${DateUtils.formatedDate(this.dateStart, '---')} по ${DateUtils.formatedDate(this.dateEnd, '---')}`
        ],
        { margin: [ 0, 0, 0, 20 ] }
      )
      builder.addTable(table)
      builder.setStyle('subtitle', { color: 'gray' })
      builder.build()
    },
    handleRowDoubleClick(row: any) {
      this.popupListOfDocs = row.records
    },
    reloadData() {

      if (DateUtils.isEmpty(this.dateStart)) return
      if (DateUtils.isEmpty(this.dateEnd)) return
      if (EntryUtils.isEmpty(this.storage)) return

      this.isLoading = true

      var conditions = [
        [ PartyRecord.VAL_DATE, '<=', this.dateEnd ],
        [ PartyRecord.VAL_STORAGE_ID, '==', EntryUtils.getId(this.storage) ]
      ]

      this.$dm.getEntriesWhere(PartyRecord.MODEL, conditions)
        .then(async records => {

          const groups = group_records_by_party(records, this.dateStart!)
          const partyIds = [ ...new Set(groups.map(it => it.partyId)) ]

          const parties = await this.loader.loadEntriesByIds<Party>(Party.MODEL, partyIds)

          const orderIds = [ ...new Set(parties.map(it => it.orderId)) ]
          await this.loader.loadEntriesByIds<PurchaseOrder>(PurchaseOrder.MODEL, orderIds)

          this.tableRows = create_rows(groups, this.loader.data)
        })
        .finally(() => this.isLoading = false)
    },
  },
  created() {
    this.isLoading = true

    const request = new DataRequest()
    request.addGetAllEntries(Storage.MODEL, Storage.MODEL)
    request.addGetAllEntries(Place.MODEL, Place.MODEL)
    request.addGetAllEntries(Employee.MODEL, Employee.MODEL)

    this.loader.load(request)
      .then(() => {
        this.reloadData()
      })
      .finally(() => this.isLoading = false)
  }
})
