
import { defineComponent, ref, watch } from 'vue'
import UIBaseInput from './UIBaseInput.vue'

export default defineComponent({
  components: { UIBaseInput },
  props: { 
    elementId: String,
    fieldStyle: {
      type: String,
      default: 'bordered',
      validator: (value: string) => ['plain', 'bordered'].includes(value)
    },
    readonly: Boolean,
    alignment: String,
    value: String,
    variants: Function,
  },
  emits: ['update:value'],
  setup(props, { emit }) {

    const toInternalValue = (it: any) => (it && { text: it }) || {}
    const toExternalValue = (it: any) => (it && it.text) || ''

    const inputValue = ref(toInternalValue(props.value))

    watch(() => props.value, newValue => {
      inputValue.value = toInternalValue(newValue)
    })

    const outputValue = (newValue: any) => {
      inputValue.value = newValue
      emit('update:value', toExternalValue(newValue))
    }

    const decorator = (_: any) => {
      if (props.fieldStyle == 'plain') {
        return undefined
      } else { // bordered
        return 'color: var(--content-primary-color); border: 1px solid var(--divider-color); border-radius: 4px;'
      }
    }

    const internalVariants = (props.variants) ? (callback: any) => {
      props.variants!((vars: string[]) => {
        callback(vars.map(it => toInternalValue(it)))
      })
    } : undefined

    return {
      inputValue,
      outputValue,
      decorator,
      internalVariants,
    }
  }
})
