
import { defineComponent } from 'vue';
import DefaultPage from "@/use/components/DefaultPage.vue";
import { UIPanel, UIReadTable, UIButton, UIDateField } from "@/use/base/Types";
import { Equipment, EquipmentRecord, Place, WriteOffInventory } from '@/core/model/Types';
import DataRequest from '@/core/data/DataRequest';
import ListOfDocsView from './ListOfDocsView.vue';
import ArrayMaster from '@/lib/util/ArrayMaster';
import { DateUtils, NumberUtils, TableUtils } from "@/lib/util/Utils"
import { PDFBuilder, PDFTable } from '@/lib/pdf/PDFTypes';
import LocalStorageEx from '@/lib/util/LocalStorageEx';
import EntryLoader from '@/lib/data/EntryLoader';

export default defineComponent({
  components: {
    DefaultPage,
    UIPanel, UIReadTable, UIButton, UIDateField,
    ListOfDocsView
  },
  data() {
    return {
      loader: new EntryLoader(),
      dateStart: LocalStorageEx.getDate('i1xd'),
      dateEnd: LocalStorageEx.getDate('sy5t'),
      tableHeaders: TableUtils.headers(m => [
        { key: 'place', name: 'Участок', width: m.flex(1) },
        { key: 'equipment', name: 'Оборудование', width: m.flex(1) }, 
        { key: 'inventory', name: 'ТМЦ', width: m.flex(1) }, 
        { key: 'sum', name: 'Сумма', width: m.CURRENCY, alignment: 'right' },
      ]),
      tableRows: new Array<any>(),
      isLoading: false,
      popupListOfDocs: []
    }
  },
  watch: {
    dateStart(newValue) { 
      LocalStorageEx.setDate('i1xd', DateUtils.correctDate(newValue))
      this.reloadData() 
    },
    dateEnd(newValue) { 
      LocalStorageEx.setDate('sy5t', DateUtils.correctDate(newValue))
      this.reloadData() 
    },
  },
  methods: {
    handlePrintClick() {

      const table = new PDFTable('lightHorizontalLines')
      table.setColumnWidths(this.tableHeaders.map(it => it.width))
      table.setColumnAligment(this.tableHeaders.map(it => it.alignment || 'left'))
      table.setHeaders(this.tableHeaders.map(it => it.name))
      for (const row of this.tableRows) {
        table.addRow(this.tableHeaders.map(it => row[it.key]))
      }

      const builder = new PDFBuilder('landscape')
      builder.addTitle(
        [
          'Расходы на оборудование',
          `с ${DateUtils.formatedDate(this.dateStart, '---')} по ${DateUtils.formatedDate(this.dateEnd, '---')}`
        ],
        { margin: [ 0, 0, 0, 20 ] }
      )
      builder.addTable(table)
      builder.build()
    },
    handleRowDoubleClick(row: any) {
      this.popupListOfDocs = row.records
    },
    reloadData() {

      const condition = new Array<any>()
      condition.push([ EquipmentRecord.VAL_DOC_MODEL, '==', WriteOffInventory.MODEL])
      if (!DateUtils.isEmpty(this.dateStart)) condition.push([ EquipmentRecord.VAL_DATE, '>', DateUtils.startOfDay(this.dateStart!) ])
      if (!DateUtils.isEmpty(this.dateEnd)) condition.push([ EquipmentRecord.VAL_DATE, '<', DateUtils.endOfDay(this.dateEnd!) ])
      
      this.isLoading = true
      this.$dm.getEntriesWhere(EquipmentRecord.MODEL, condition)
        .then(records => {

          const groups = new ArrayMaster(records)
            .map(it => { return { equipmentId: it.equipmentId, inventory: it.inventory, records: it, sum: it.sum }})
            .group(['equipmentId', 'inventory'], ['sum'], ['records'])
            .get()
          
          const equipmentIds = new Set<string>()
          groups.forEach(gr => {
            equipmentIds.add(gr.equipmentId)
          })

          const request = new DataRequest()
          request.addGetAllEntries(Place.MODEL, Place.MODEL)
          request.addGetEntriesByIds(Equipment.MODEL, Array.from(equipmentIds), Equipment.MODEL)

          return this.loader.load(request)
            .then(() => {

              this.tableRows = groups.map(gr => {
                const equipment = this.loader.data.getEntryById(Equipment.MODEL, gr.equipmentId)
                return {
                  place: this.loader.data.getDisplayName(Place.MODEL, equipment.placeId),
                  equipment: equipment.name,
                  inventory: gr.inventory,
                  sum: NumberUtils.formatedCurrency(gr.sum),
                  records: gr.records
                }
              }).sort((a, b) => {
                return a.place.localeCompare(b.place) ||
                  a.equipment.localeCompare(b.equipment) || 
                  a.inventory.localeCompare(b.inventory)
              })
            })
        })
        .finally(() => this.isLoading = false)
    },
  },
  created() {
    this.reloadData()
  },
})
