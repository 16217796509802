import DocumentRow from "./DocumentRow"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, NumberProperty, StringProperty } from "../Property"
import Equipment from "../unit/Equipment"

export default class PurchaseOrderRow extends DocumentRow {
  static MODEL = 'ewo0'

  static VAL_INVENTORY_NAME = 's8qq'
  static VAL_INVENTORY_DETAILS = 'e9gp'
  static VAL_EQUIPMENT_ID = 'jo3g'
  static VAL_MEASURE = 'o7hm'
  static VAL_AMOUNT = 'u6jr'
  static VAL_COMMENT = 'w1ic'
  // static VAL_MEASURE_ID = 'bq9q' // Удалить

  static PROPS: Property[] = DocumentRow.PROPS.concat([
    new StringProperty(this.VAL_INVENTORY_NAME), new StringProperty(this.VAL_INVENTORY_DETAILS),
    new IDProperty(this.VAL_EQUIPMENT_ID, Equipment.MODEL), new StringProperty(this.VAL_MEASURE),
    new NumberProperty(this.VAL_AMOUNT), new StringProperty(this.VAL_COMMENT),
    // new IDProperty(this.VAL_MEASURE_ID, Measure.MODEL)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get name(): string {
    return (this.inventoryDetails) ? `${this.inventoryName} / ${this.inventoryDetails}` : this.inventoryName
  }

  get inventoryName(): string { return this.getValue(PurchaseOrderRow.VAL_INVENTORY_NAME) }
  get inventoryDetails(): string { return this.getValue(PurchaseOrderRow.VAL_INVENTORY_DETAILS) }
  get equipmentId(): string { return this.getValue(PurchaseOrderRow.VAL_EQUIPMENT_ID) }
  get measure(): string { return this.getValue(PurchaseOrderRow.VAL_MEASURE) }
  get amount(): number { return this.getValue(PurchaseOrderRow.VAL_AMOUNT) }
  get comment(): string { return this.getValue(PurchaseOrderRow.VAL_COMMENT) }
  // get measureId(): string { return this.getValue(PurchaseOrderRow.VAL_MEASURE_ID) }

  static new(value: any): PurchaseOrderRow {
    return EntryHelper.fillEntry(new PurchaseOrderRow(), value)
  }
}