
import { defineComponent } from 'vue';
import DefaultPage from "@/use/components/DefaultPage.vue";
import { UIPanel, UIReadTable } from "@/use/base/Types";
import { HistoryRecord, Employee } from "@/core/model/Types";
import EntryLoader from '@/lib/data/EntryLoader';
import { DateUtils, TableUtils } from '@/lib/util/Utils';

export default defineComponent({
  components: {
    DefaultPage,
    UIPanel, UIReadTable,
  },
  data() {
    return {
      loader: new EntryLoader(),
      tableHeaders: TableUtils.headers(m => [
        { key: 'date', name: 'Дата', width: m.DATE }, 
        { key: 'employee', name: 'Пользователь', width: m.fixed(250) },
        { key: 'details', name: 'Описание', width: m.flex(1) },
      ]),
      tableRows: new Array(),
      isLoading: false,
    }
  },
  methods: {
    reloadData() {
      this.isLoading = true

      let model = this.$sm.getCurrentPathName()
      let id = this.$sm.getCurrentParam('id')
      let condition = [
        [ HistoryRecord.VAL_OBJECT_MODEL, '==', model ],
        [ HistoryRecord.VAL_OBJECT_ID, '==', id ]
      ]

      this.loader.loadEntriesWhere<HistoryRecord>(HistoryRecord.MODEL, condition)
        .then(entries => {
          const sorted = entries.sort((a: HistoryRecord, b: HistoryRecord) => a.date.getTime() - b.date.getTime())
          return this.loader.loadDetails(sorted, [
            { key: HistoryRecord.VAL_EMPLOYEE_ID, model: Employee.MODEL }
          ])
            .then(details => {
              this.tableRows = sorted.map(it => ({
                id: it.id,
                date: DateUtils.formatedDate(it.date),
                employee: details.getDisplayName(Employee.MODEL, it.employeeId),
                details: it.details
              }))
            })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  created() {
    this.reloadData()
  },
})
