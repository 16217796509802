import AppContext from '@/core/AppContext';
import { HistoryRecord, Party, PartyRecord, Storage, TransferInventory, TransferInventoryRow } from "@/core/model/Types";
import DataRequest from "@/core/data/DataRequest";
import HistoryBuilder from "@/lib/data/HistoryBuilder";
import EntryMap from '@/lib/data/EntryMap';
import DataManager from '@/core/data/DataManager';
import { DateUtils } from '@/lib/util/Utils';

export default class TransferInventoryHelper {

  private static get dm(): DataManager { return AppContext.getDataManager() }

  static save(doc: TransferInventory, rows: TransferInventoryRow[], entryMap: EntryMap): Promise<boolean> {
    return this.hold(doc, rows, entryMap)
  }

  private static hold(doc: TransferInventory, rows: TransferInventoryRow[], entryMap: EntryMap): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const emptyValues = []

      if (DateUtils.isEmpty(doc.date)) emptyValues.push('Дата')
      if (!doc.number) emptyValues.push('Номер')        
      if (!doc.sourceStorageId) emptyValues.push('Склад списания')
      if (!doc.targetStorageId) emptyValues.push('Склад назначения')
  
      rows.forEach((row, index) => {
        if (!row.partyId) emptyValues.push(`Партия (Строка ${index + 1})`)
        if (Number(row.amount) <= 0) emptyValues.push(`Количество (Строка ${index + 1})`)
      })
  
      if (emptyValues.length > 0) {
        reject(`Не указаны значения: "${emptyValues.join('", "')}"`)
        return
      }

      this.holdPrepare(doc, rows, entryMap)
        .then(v => resolve(v))
        .catch(msg => reject(msg))
    })
  }

  private static async holdPrepare(doc: TransferInventory, rows: TransferInventoryRow[], entryMap: EntryMap): Promise<boolean> {

    const employeeId = AppContext.getSafeManager().getCurrentEmployeeId()!
    const history = new HistoryBuilder(employeeId, TransferInventory.MODEL, doc.id)

    const request = new DataRequest()
    request.addGetEntriesByIds(TransferInventory.MODEL, [doc.id], 'doc')
    request.addGetEntriesWhere(TransferInventoryRow.MODEL, [
      [ TransferInventoryRow.VAL_DOC_ID, '==', doc.id ]
    ], 'rows')

    const results = await this.dm.send(request)

    const oldDoc = results.getValue('doc').find(() => true) as TransferInventory | undefined
    const oldRows = ((results.getValue('rows') || []) as TransferInventoryRow[]).sort((a, b) => a.index - b.index)
    
    // Создадим историю изменений

    if (oldDoc) {

      if (DateUtils.getTime(oldDoc.date) !== DateUtils.getTime(doc.date)) {
        history.pushChange('Дата', DateUtils.formatedDate(oldDoc.date), DateUtils.formatedDate(doc.date))
      }
      if ((oldDoc.number || '') !== (doc.number || '')) {
        history.pushChange('Номер', oldDoc.number, doc.number)
      }
      if ((oldDoc.sourceStorageId || '') !== (doc.sourceStorageId || '')) {
        history.pushChange('Склад списания', entryMap.getDisplayName(Storage.MODEL, oldDoc.sourceStorageId), entryMap.getDisplayName(Storage.MODEL, doc.sourceStorageId))
      }
      if ((oldDoc.targetStorageId || '') !== (doc.targetStorageId || '')) {
        history.pushChange('Склад назначения', entryMap.getDisplayName(Storage.MODEL, oldDoc.targetStorageId), entryMap.getDisplayName(Storage.MODEL, doc.targetStorageId))
      }
      if ((oldDoc.comment || '') !== (doc.comment || '')) {
        history.pushChange('Коментарий', oldDoc.comment, doc.comment)
      }
      
      const rowsCount = Math.max(oldRows.length, rows.length)
      for (let i = 0; i < rowsCount; i++) {
        const oldRow = (i < oldRows.length) ? oldRows[i] : undefined
        const newRow = (i < rows.length) ? rows[i] : undefined
        history.pushTest(`Строка ${i + 1}`, this.toHistoryString(oldRow, entryMap), this.toHistoryString(newRow, entryMap))
      }
    } 
    else {
      history.pushDetails('Создание документа')
    }

    // Поправим данные документа

    rows.forEach((row, index) => {
      row.setValue(TransferInventoryRow.VAL_DOC_ID, doc.id)
      row.setValue(TransferInventoryRow.VAL_INDEX, index)
    })

    if (history.isEmpty()) {
      doc.setValue(TransferInventory.VAL_AUTHOR_ID, oldDoc?.authorId || employeeId)
    } else {
      doc.setValue(TransferInventory.VAL_AUTHOR_ID, employeeId)
    }

    // Запишем в базу данных

    return this.holdSave(doc, rows, history)
  }

  private static async holdSave(doc: TransferInventory, rows: TransferInventoryRow[], history: HistoryBuilder): Promise<boolean> {

    // Обработка

    const createActions = !doc.toDelete
    const request = new DataRequest()

    // Сам документ

    request.addPutEntries(TransferInventory.MODEL, [doc])

    request.addDelEntriesWhere(TransferInventoryRow.MODEL, [
      [ TransferInventoryRow.VAL_DOC_ID, '==', doc.id ]
    ])
    request.addPutEntries(TransferInventoryRow.MODEL, rows)

    // Регистр партий

    request.addDelEntriesWhere(PartyRecord.MODEL, [
      [ PartyRecord.VAL_DOC_MODEL, '==', TransferInventory.MODEL ],
      [ PartyRecord.VAL_DOC_ID, '==', doc.id ]
    ])
    if (createActions) {
      request.addPutEntries(PartyRecord.MODEL, rows.map(row => PartyRecord.new({
        [PartyRecord.VAL_DATE]: doc.date,
        [PartyRecord.VAL_DOC_MODEL]: TransferInventory.MODEL,
        [PartyRecord.VAL_DOC_ID]: doc.id,
        [PartyRecord.VAL_STORAGE_ID]: doc.sourceStorageId,
        [PartyRecord.VAL_PARTY_ID]: row.partyId,
        [PartyRecord.VAL_AMOUNT]: -row.amount
      })))
      request.addPutEntries(PartyRecord.MODEL, rows.map(row => PartyRecord.new({
        [PartyRecord.VAL_DATE]: doc.date,
        [PartyRecord.VAL_DOC_MODEL]: TransferInventory.MODEL,
        [PartyRecord.VAL_DOC_ID]: doc.id,
        [PartyRecord.VAL_STORAGE_ID]: doc.targetStorageId,
        [PartyRecord.VAL_PARTY_ID]: row.partyId,
        [PartyRecord.VAL_AMOUNT]: row.amount
      })))
    }

    // История

    request.addPutEntries(HistoryRecord.MODEL, history.getList())

    // Запись
    
    await this.dm.send(request)
    return true
  }

  private static toHistoryString(row: TransferInventoryRow | undefined, entryMap: EntryMap) {
    if (row) {
      const vals = []
      vals.push(entryMap.getDisplayName(Party.MODEL, row.partyId))
      vals.push(row.amount || 0)
      return vals.join(', ')
    } else {
      return ''
    }
  }
}