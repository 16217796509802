import RegistryRecord from "./RegistryRecord"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, NumberProperty, StringProperty } from "../Property"
import PurchaseOrder from "../doc/PurchaseOrder"
import PurchaseOrderRow from "../doc/PurchaseOrderRow"

export default class OrderRecord extends RegistryRecord {
  static MODEL = 'i6as'

  static VAL_DOC_MODEL = 'aw1r'
  static VAL_DOC_ID = 'mn6u'
  static VAL_ORDER_ID = 'fo3d'
  static VAL_ORDER_ROW_ID = 'x2ei'
  static VAL_AMOUNT = 'l2sg'
  
  static PROPS: Property[] = RegistryRecord.PROPS.concat([
    new StringProperty(this.VAL_DOC_MODEL), new StringProperty(this.VAL_DOC_ID),
    new IDProperty(this.VAL_ORDER_ID, PurchaseOrder.MODEL), new IDProperty(this.VAL_ORDER_ROW_ID, PurchaseOrderRow.MODEL),
    new NumberProperty(this.VAL_AMOUNT)
  ])

  // ---

  constructor(data?: any) {
    super(data)
  }

  get docModel(): string { return this.getValue(OrderRecord.VAL_DOC_MODEL) }
  get docId(): string { return this.getValue(OrderRecord.VAL_DOC_ID) }
  get orderId(): string { return this.getValue(OrderRecord.VAL_ORDER_ID) }
  get orderRowId(): string { return this.getValue(OrderRecord.VAL_ORDER_ROW_ID) }
  get amount(): number { return this.getValue(OrderRecord.VAL_AMOUNT) }

  static new(value: any): OrderRecord {
    return EntryHelper.fillEntry(new OrderRecord(), value)
  }
}