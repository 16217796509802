
import { defineComponent } from 'vue';
import DefaultPage from "@/use/components/DefaultPage.vue";
import { UIPanel, UIReadTable, UIButton } from "@/use/base/Types";
import { Measure, OrderRecord, PurchaseOrder, PurchaseOrderRow } from '@/core/model/Types';
import DataRequest from '@/core/data/DataRequest';
import ListOfDocsView from './ListOfDocsView.vue';
import { PDFBuilder, PDFTable } from '@/lib/pdf/PDFTypes';
import EntryLoader from '@/lib/data/EntryLoader';
import { NumberUtils, TableUtils } from '@/lib/util/Utils';

export default defineComponent({
  components: {
    DefaultPage,
    UIPanel, UIReadTable,
    UIButton,
    ListOfDocsView
  },
  data() {
    return {
      loader: new EntryLoader(),
      tableHeaders: TableUtils.headers(m => [
        { key: 'order', name: 'Заявка', width: m.flex(1) }, 
        { key: 'item', name: 'Позиция', width: m.flex(2) }, 
        { key: 'measure', name: 'Ед.', width: m.MEASURE },
        { key: 'amount', name: 'Кол-во', width: m.AMOUNT, alignment: 'right' },
      ]),
      tableRows: new Array<any>(),
      isLoading: false,
      popupListOfDocs: []
    }
  },
  methods: {
    handlePrintClick() {

      const table = new PDFTable('lightHorizontalLines')
      table.setColumnWidths(this.tableHeaders.map(it => it.width))
      table.setColumnAligment(this.tableHeaders.map(it => it.alignment || 'left'))
      table.setHeaders(this.tableHeaders.map(it => it.name))
      for (const row of this.tableRows) {
        table.addRow(this.tableHeaders.map(it => row[it.key]))
      }

      const builder = new PDFBuilder('landscape')
      builder.addTitle(
        [ 'Очередь заказов' ],
        { margin: [ 0, 0, 0, 20 ] }
      )
      builder.addTable(table)
      builder.build()
    },
    handleRowDoubleClick(row: any) {
      this.popupListOfDocs = row.records
    },
    load_addRecord(r: any, groups: Map<string, any>) {
      const key = `${r.orderRowId}`
      const group = groups.get(key) || {
        date: r.date,
        orderId: r.orderId,
        orderRowId: r.orderRowId,
        amount: 0,
        records: []
      }
      group.amount += r.amount
      group.records.push(r)
      groups.set(key, group)
    },
    load_createGroups(records: any[]) {

      const map = new Map<string, any>()
      for (const r of records) {
        this.load_addRecord(r, map)
      }

      const groups = new Array<any>()
      map.forEach(group => {
        if (group.amount !== 0) groups.push(group)
      })

      return groups
    },
    load_createRows(groups: any[]) {
      return groups.map(gr => {
        const order = this.loader.data.getEntryById(PurchaseOrder.MODEL, gr.orderId)
        const row = this.loader.data.getEntryById(PurchaseOrderRow.MODEL, gr.orderRowId)
        return {
          date: gr.date,
          order: order.name,
          item: (row) ? row.name : `<(${gr.orderRowId})>`,
          measure: (row) ? row.measure : '',
          amount: NumberUtils.formatedNumber(gr.amount),
          records: gr.records,
          orderDate: order.date,
        }
      }).sort((a, b) => (a.orderDate.getTime() - b.orderDate.getTime() || a.item.localeCompare(b.item)))
    },
    reloadData() {

      this.isLoading = true
      this.$dm.getAllEntries(OrderRecord.MODEL)
        .then(records => {

          const groups = this.load_createGroups(records)

          const orderIds = new Array<string>()
          const orderRowIds = new Array<string>()
          groups.forEach(gr => {
            if (!orderIds.includes(gr.orderId)) orderIds.push(gr.orderId)
            if (!orderRowIds.includes(gr.orderRowId)) orderRowIds.push(gr.orderRowId)
          })

          const request = new DataRequest()
          request.addGetAllEntries(Measure.MODEL, Measure.MODEL)
          request.addGetEntriesByIds(PurchaseOrder.MODEL, orderIds, PurchaseOrder.MODEL)
          request.addGetEntriesByIds(PurchaseOrderRow.MODEL, orderRowIds, PurchaseOrderRow.MODEL)

          return this.loader.load(request)
            .then(() => {
              this.tableRows = this.load_createRows(groups)
            })
        })
        .finally(() => this.isLoading = false)
    },
  },
  created() {
    this.reloadData()
  }
})
