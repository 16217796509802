import EntryHelper from "../EntryHelper"
import RegistryRecord from "./RegistryRecord"
import Property, { IDProperty, NumberProperty, StringProperty } from "../Property"
import Storage from "../unit/Storage"
import Party from "../unit/Party"

export default class PartyRecord extends RegistryRecord {
  static MODEL = 'c4rt'

  static VAL_DOC_MODEL = 'nx1q'
  static VAL_DOC_ID = 'a3zz'
  static VAL_STORAGE_ID = 'jrm7'
  static VAL_PARTY_ID = 'ea5a'
  static VAL_AMOUNT = 'v6dz'

  static PROPS: Property[] = RegistryRecord.PROPS.concat([
    new StringProperty(this.VAL_DOC_MODEL), new StringProperty(this.VAL_DOC_ID),
    new IDProperty(this.VAL_STORAGE_ID, Storage.MODEL), new IDProperty(this.VAL_PARTY_ID, Party.MODEL),
    new NumberProperty(this.VAL_AMOUNT)
  ])

  // ---

  constructor(data?: any) {
    super(data)
  }

  get docModel(): string { return this.getValue(PartyRecord.VAL_DOC_MODEL) }
  get docId(): string { return this.getValue(PartyRecord.VAL_DOC_ID) }
  get storageId(): string { return this.getValue(PartyRecord.VAL_STORAGE_ID) }
  get partyId(): string { return this.getValue(PartyRecord.VAL_PARTY_ID) }
  get amount(): number { return this.getValue(PartyRecord.VAL_AMOUNT) }

  static new(value: any): PartyRecord {
    return EntryHelper.fillEntry(new PartyRecord(), value)
  }
}