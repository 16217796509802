import UIWidth, { UIFixedWidth, UIFlexWidth, UIPercentWidth } from "@/use/base/h/UIWidth"

export default class PDFHelper {

  static A4_WIDTH: number = 576.3 // 595.35
  static A4_HEIGHT: number = 786.9 // 841.995

  static getTableWidths(widths: UIWidth[], tableWidth: number): number[] {

    const k = 0.525

    // Вычтем fixed и percent значения
    
    let leftTableWidth = tableWidth
    for (const w of widths) {
      if (w instanceof UIFixedWidth) {
        leftTableWidth -= w.value * k
      } else if (w instanceof UIPercentWidth) {
        leftTableWidth -= tableWidth * (w.value / 100)
      }
    }

    // Посчитаем elastic

    let elasticSum = 0

    for (const w of widths) {
      if (w instanceof UIFlexWidth) {
        elasticSum += w.value
      }
    }

    const elasticWidth = Math.max(0, leftTableWidth / elasticSum)

    // Получим результат

    return widths.map(w => {
      if (w instanceof UIFixedWidth) {
        return w.value * k
      } else if (w instanceof UIPercentWidth) {
        return tableWidth * (w.value / 100)
      } else if (w instanceof UIFlexWidth) {
        return elasticWidth * w.value
      } else {
        return 0
      }
    })
  }
}
