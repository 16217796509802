
import { defineComponent } from 'vue';
import AppContext from "@/core/AppContext";
import { Counterparty, Employee, Equipment, Measure, Place, Storage } from "@/core/model/Types";
import { PurchaseOrder, ClosingOrder, DeliveryInventory, TransferInventory, WriteOffInventory } from "@/core/model/Types";
import { ReportName } from '../RouteKeys';
import * as Permission from '@/core/safe/Permissions'

export default defineComponent({
  data() {
    return {
      unitModels: [Equipment.MODEL, Place.MODEL, Storage.MODEL, Counterparty.MODEL, Employee.MODEL, Measure.MODEL],
      docModels: [PurchaseOrder.MODEL, ClosingOrder.MODEL, DeliveryInventory.MODEL, TransferInventory.MODEL, WriteOffInventory.MODEL],
      reports: [ReportName.ORDER_QUEUE, ReportName.STORAGE_PARTIONS, ReportName.STORAGE_INVENTORIES, ReportName.EQUIPMENT_COST],
      menuVisible: [false, false, false],
      userName: '',
      showSettingsButton: false,
      showOperationsButton: false,
    }
  },
  methods: {
    pathToModel(model: string) { return this.$sm.pathToListOfObjects(model) },
    nameOfModel(model: string) { return this.$sm.nameOfListOfObjects(model) },
    handleModelClick(model: string) { this.$sm.goToListOfObjects(model) },
    pathToReport(report: string) { return this.$sm.pathToReport(report) },
    nameOfReport(report: string) { return this.$sm.nameOfReport(report) },
    handleReportClick(report: string) { this.$sm.goToReport(report) },
    handleMouseLeave() {
      this.menuVisible = [false, false, false];
    },
    handleMenuClick(index: number) {
      this.menuVisible = this.menuVisible.map(
        (visible, i) => (i === index ? !visible : false)
      )
    },
    handleSettingsClick() {
      this.$sm.goToSettings()
    },
    handleOperationsClick() {
      this.$sm.goToOperations()
    },
    handleLogoutClick() {
      AppContext.getSafeManager().logout()
      this.$sm.goToLogin()
    },
  },
  created() {
    const sm = AppContext.getSafeManager()
    sm.getCurrentEmployee(em => this.userName = (em) ? em.name : 'Undefined')
    sm.getCurrentPermissions(perms => {
      this.showSettingsButton = perms.includes(Permission.SETTINGS)
      this.showOperationsButton = perms.includes(Permission.OPERATIONS)
    })
  }
})
