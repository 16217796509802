
export default class ArrayMaster {

  private _array: Array<any>

  constructor(array: any[]) {
    this._array = array || []
  }

  get(): any[] {
    return this._array
  }

  getFirst(): any {
    return this._array.find(() => true)
  }

  getSum(): number {
    return this._array.reduce((s, it) => s + it, 0)
  }

  map(handler: (_: any) => any): ArrayMaster {
    this._array = this._array.map(handler)
    return this
  }

  group(groupableKeys: string[], summableKeys?: string[], joinableKeys?: string[]): ArrayMaster {
    const map = new Map()
    this._array.forEach(it => {
      const groupKey = 'key_' + groupableKeys.map(key => '' + it[key]).join('_')
      const group = map.get(groupKey)
      if (group) {
        if (summableKeys) summableKeys.forEach(key => { group[key] += it[key] })
        if (joinableKeys) joinableKeys.forEach(key => { group[key].push(it[key]) })
      } else {
        const group: { [name: string]: any } = {}
        groupableKeys.forEach(key => { group[key] = it[key] })
        if (summableKeys) summableKeys.forEach(key => { group[key] = it[key] })
        if (joinableKeys) joinableKeys.forEach(key => { group[key] = [it[key]] })
        map.set(groupKey, group)
      }
    })
    const newArray = new Array<any>()
    map.forEach(group => newArray.push(group))
    this._array = newArray
    return this
  }

  unique(): ArrayMaster {
    this._array = [ ...new Set(this._array) ]
    return this
  }

  uniqueEntries(): ArrayMaster {
    const map = new Map()
    this._array.forEach(it => map.set(it.id, it))
    this._array = Array.from(map.values())
    return this
  }

  filter(predicate: (_: any) => boolean): ArrayMaster {
    this._array = this._array.filter(predicate)
    return this
  }

  sort(comparer: any): ArrayMaster {
    this._array.sort(comparer)
    return this
  }

  sortEntries(): ArrayMaster {
    this._array.sort((a, b) => a.name.localeCompare(b.name))
    return this
  }
}