import EntryHelper from "./EntryHelper"
import Property, { StringProperty } from "./Property"

export default class StoredEntry {
  static MODEL = '----'

  static VAL_ID = 'id'

  static PROPS: Property[] = [new StringProperty(this.VAL_ID)]

  static getProperty(key: string): Property {
    return this.PROPS.find(p => p.key === key)!
  }

  // ---

  private rawData: any

  constructor(rawData: any) {

    this.rawData = {}

    if (rawData) {
      const keys = (this.constructor as typeof StoredEntry).PROPS.map(p => p.key)
      for (const key of keys) {
        if (rawData[key]) {
          this.rawData[key] = rawData[key]
        }
      }
    }

    if (!this.rawData[StoredEntry.VAL_ID]) {
      this.rawData[StoredEntry.VAL_ID] = EntryHelper.newId()
    }
  }

  get model(): string { return (this.constructor as typeof StoredEntry).MODEL }
  get id(): string { return this.getValue(StoredEntry.VAL_ID) }
  get name(): string { return `<(${this.getSubclassMODEL}:${this.id})>` }

  getProperty(key: string): Property | undefined {
    return (this.constructor as typeof StoredEntry).PROPS.find(p => p.key === key)
  }

  getValue(key: string): any { 
    const rawValue = this.rawData[key]

    const property = this.getProperty(key)
    if (!property) {
      console.warn(`z62k, ${key}`)
      return rawValue
    }

    return property.decode(rawValue)
  }

  setValue(key: string, value: any) {

    const property = this.getProperty(key)
    if (!property) {
      console.warn(`m8w7, ${key}`)
      this.rawData[key] = value
      return
    }

    if (key === StoredEntry.VAL_ID) {
      // Отдельная обработка для id
      // Не устанавливаем пустое значение для идентификатора
      if (!value) return
    }

    this.rawData[key] = property.encode(value)
  }

  getValues(keys: string[]): { [name: string]: any } {
    const out: { [name: string]: any } = {}
    for (const key of keys) {
      out[key] = this.getValue(key)
    }
    return out
  }

  setValues(values: any[]) {
    for (const key in values) {
      this.setValue(key, values[key])
    }
  }

  getRawData(): any { return this.rawData }

  // Private

  private getSubclassMODEL(): string {
    return (this.constructor as typeof StoredEntry).MODEL
  }
}