
import { defineComponent } from 'vue';
import UniversalUnitsPage from "./UniversalUnitsPage.vue";
import { Employee } from "@/core/model/Types";
import { TableUtils } from '@/lib/util/Utils';
import * as Permission from '@/core/safe/Permissions'

export default defineComponent({
  components: { UniversalUnitsPage },
  data() {
    return {
      unitModel: Employee.MODEL,
      tableHeaders: TableUtils.headers(m => [
        { key: 'name', name: 'Фамилия Имя Отчество', width: m.flex(1) },
        { key: 'login', name: 'Логин', width: m.fixed(200) },
      ]),
      loadConfig: [
        { name: 'id', key: Employee.VAL_ID },
        { name: 'name', handler: (_: any, o: Employee) => o.getFullName() },
        { name: 'login', key: Employee.VAL_LOGIN },
        { name: 'toDelete', key: Employee.VAL_TODELETE }
      ],
      editPermission: Permission.EMPLOYEE_EDIT,
    }
  },
  methods: {
    getTableRow(entry: Employee) {
      return {
        id: entry.id,
        name: entry.getFullName(),
        login: entry.login
      }
    },
  },
})
