import StoredEntry from "../StoredEntry"
import Property, { BoolProperty, DateProperty, StringProperty } from "../Property"

export default class DocumentEntry extends StoredEntry {

  static VAL_DATE = 'pq0n'
  static VAL_NUMBER = 'q6dt'
  static VAL_TODELETE = 'a3yq'

  static PROPS: Property[] = StoredEntry.PROPS.concat([
    new DateProperty(this.VAL_DATE), new StringProperty(this.VAL_NUMBER),
    new BoolProperty(this.VAL_TODELETE)
  ])

  // ---

  constructor(rawData: any) {
    super(rawData)
  }

  get date(): Date { return this.getValue(DocumentEntry.VAL_DATE) }
  get number(): string { return this.getValue(DocumentEntry.VAL_NUMBER) }
  get toDelete(): boolean { return this.getValue(DocumentEntry.VAL_TODELETE) }
}