
import { defineComponent } from 'vue';
import UniversalDocsPage from "./UniversalDocsPage.vue";
import { DeliveryInventory, Counterparty, Storage } from "@/core/model/Types";
import * as Permission from '@/core/safe/Permissions'
import { DateUtils, NumberUtils, TableUtils } from '@/lib/util/Utils';

export default defineComponent({
  components: { UniversalDocsPage },
  data() {
    return {
      docModel: DeliveryInventory.MODEL,
      tableHeaders: TableUtils.headers(m => [
        { key: 'date', name: 'Дата', width: m.DATE }, 
        { key: 'number', name: 'Номер', width: m.DOC_NUMBER },
        { key: 'provider', name: 'Поставщик', width: m.flex(1) },
        { key: 'storage', name: 'Склад', width: m.flex(1) },
        { key: 'total', name: 'Сумма', width: m.CURRENCY, alignment: 'right' },
      ]),
      loadConfig: [
        { name: 'id', key: DeliveryInventory.VAL_ID },
        { name: 'date', key: DeliveryInventory.VAL_DATE, handler: (v: Date) => DateUtils.formatedDate(v) },
        { name: 'number', key: DeliveryInventory.VAL_NUMBER },
        { name: 'provider', key: DeliveryInventory.VAL_PROVIDER_ID, model: Counterparty.MODEL, handler: (v?: Counterparty) => v?.name },
        { name: 'storage', key: DeliveryInventory.VAL_STORAGE_ID, model: Storage.MODEL, handler: (v?: Storage) => v?.name },
        { name: 'total', key: DeliveryInventory.VAL_TOTAL, handler: (v: number) => NumberUtils.formatedCurrency(v) },
        { name: 'toDelete', key: DeliveryInventory.VAL_TODELETE }
      ],
      editPermission: Permission.DELIVERY_INVENTORY_EDIT,
      dateKey: DeliveryInventory.VAL_DATE,
    }
  },
})
