
import { defineComponent, ref, watch } from 'vue'
import UITextField from './UITextField.vue'

export default defineComponent({
  components: { UITextField },
  props: { 
    elementId: String,
    readonly: Boolean,
    alignment: String,
    value: String,
    variants: Function,
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    
    const inputValue = ref(props.value)

    watch(() => props.value, newValue => {
      inputValue.value = newValue
    })

    const outputValue = (newValue: any) => {
      inputValue.value = newValue
      emit('update:value', newValue)
    }

    return {
      inputValue,
      outputValue
    }
  }
})
