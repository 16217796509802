
import { defineComponent } from 'vue';
import UIPopupContent from './UIPopupContent.vue'
import UIButton from './UIButton.vue'

export default defineComponent({
  components: { UIPopupContent, UIButton },
  props: ['title', 'text'],
  computed: {
    displayTexts() {
      return Array.isArray(this.text) ? this.text : [this.text]
    }
  },
  methods: {
    handleDoneClick() {
      this.$emit('doneClick');
    },
  },
})
