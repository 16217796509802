
import { defineComponent } from 'vue';
import { DeliveryInventory, PurchaseOrder, TransferInventory, WriteOffInventory } from '@/core/model/Types';
import DefaultPage from "@/use/components/DefaultPage.vue";
import { ReportName } from '../RouteKeys';

export default defineComponent({
  components: {
    DefaultPage,
  },
  computed: {
    pathToOrderList() { 
      return this.$sm.pathToListOfObjects(PurchaseOrder.MODEL)
    },
    pathToNewOrder() {
      return this.$sm.pathToObjectEditor(PurchaseOrder.MODEL, 'new')
    },
    pathToOrderQueue() { 
      return this.$sm.pathToReport(ReportName.ORDER_QUEUE)
    },
    pathToEquipmentCost() { 
      return this.$sm.pathToReport(ReportName.EQUIPMENT_COST)
    },
    pathToDeliveryList() { 
      return this.$sm.pathToListOfObjects(DeliveryInventory.MODEL)
    },
    pathToTransferList() {
      return this.$sm.pathToListOfObjects(TransferInventory.MODEL)
    },
    pathToWriteOffList() { 
      return this.$sm.pathToListOfObjects(WriteOffInventory.MODEL)
    },
    pathToStoragePartions() { 
      return this.$sm.pathToReport(ReportName.STORAGE_PARTIONS)
    },
  }
})
