
export default class NumberUtils {

  static correctNumber(value: any): number { 
    if (typeof value === 'number') {
      return isNaN(value) ? 0 : value
    } else if (typeof value === 'string') {
      const num = Number(value)
      return isNaN(num) ? 0 : num
    } else if (typeof value === 'boolean') {
      return Number(value)
    } else {
      return 0
    }
  }

  static isEmpty(value: any): boolean {
    return this.correctNumber(value) === 0
  }

  static isPositive(value: any): boolean { 
    return this.correctNumber(value) > 0 
  }
  
  static formatedNumber(value: any): string { 
    return new Intl.NumberFormat("ru-RU").format(this.correctNumber(value)) 
  }

  static formatedCurrency(value: any): string {
    return new Intl.NumberFormat("ru-RU", { style: 'currency', currency: 'KZT' }).format(this.correctNumber(value))
  }
}
