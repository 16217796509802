import AppContext from '@/core/AppContext';
import { Equipment, EquipmentRecord, HistoryRecord, Party, PartyRecord, Storage, WriteOffInventory, WriteOffInventoryRow } from "@/core/model/Types";
import DataRequest from "@/core/data/DataRequest";
import HistoryBuilder from "@/lib/data/HistoryBuilder";
import EntryMap from '@/lib/data/EntryMap';
import DataManager from '@/core/data/DataManager';
import { DateUtils } from '@/lib/util/Utils';

export default class WriteOffInventoryHelper {

  private static get dm(): DataManager { return AppContext.getDataManager() }

  static save(doc: WriteOffInventory, rows: WriteOffInventoryRow[], entryMap: EntryMap): Promise<boolean> {
    return this.hold(doc, rows, entryMap)
  }

  private static hold(doc: WriteOffInventory, rows: WriteOffInventoryRow[], entryMap: EntryMap): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const emptyValues = []

      if (DateUtils.isEmpty(doc.date)) emptyValues.push('Дата')
      if (!doc.number) emptyValues.push('Номер')        
      if (!doc.storageId) emptyValues.push('Склад')
  
      rows.forEach((row, index) => {
        if (!row.partyId) emptyValues.push(`Партия (Строка ${index + 1})`)
        if (Number(row.amount) <= 0) emptyValues.push(`Количество (Строка ${index + 1})`)
      })
  
      if (emptyValues.length > 0) {
        reject(`Не указаны значения: "${emptyValues.join('", "')}"`)
        return
      }

      this.holdPrepare(doc, rows, entryMap)
        .then(v => resolve(v))
        .catch(msg => reject(msg))
    })
  }

  private static async holdPrepare(doc: WriteOffInventory, rows: WriteOffInventoryRow[], entryMap: EntryMap): Promise<boolean> {

    const employeeId = AppContext.getSafeManager().getCurrentEmployeeId()!
    const history = new HistoryBuilder(employeeId, WriteOffInventory.MODEL, doc.id)

    const request = new DataRequest()
    request.addGetEntriesByIds(WriteOffInventory.MODEL, [doc.id], 'doc')
    request.addGetEntriesWhere(WriteOffInventoryRow.MODEL, [
      [ WriteOffInventoryRow.VAL_DOC_ID, '==', doc.id ]
    ], 'rows')

    const results = await this.dm.send(request)

      const oldDoc = results.getValue('doc').find(() => true) as WriteOffInventory | undefined
      const oldRows = ((results.getValue('rows') || []) as WriteOffInventoryRow[]).sort((a, b) => a.index - b.index)
      
      // Создадим историю изменений

      if (oldDoc) {

        if (DateUtils.getTime(oldDoc.date) !== DateUtils.getTime(doc.date)) {
          history.pushChange('Дата', DateUtils.formatedDate(oldDoc.date), DateUtils.formatedDate(doc.date))
        }
        if ((oldDoc.number || '') !== (doc.number || '')) {
          history.pushChange('Номер', oldDoc.number, doc.number)
        }
        if ((oldDoc.storageId || '') !== (doc.storageId || '')) {
          history.pushChange('Склад', entryMap.getDisplayName(Storage.MODEL, oldDoc.storageId), entryMap.getDisplayName(Storage.MODEL, doc.storageId))
        }
        if ((oldDoc.comment || '') !== (doc.comment || '')) {
          history.pushChange('Коментарий', oldDoc.comment, doc.comment)
        }
        
        const rowsCount = Math.max(oldRows.length, rows.length)
        for (let i = 0; i < rowsCount; i++) {
          const oldRow = (i < oldRows.length) ? oldRows[i] : undefined
          const newRow = (i < rows.length) ? rows[i] : undefined
          history.pushTest(`Строка ${i + 1}`, this.toHistoryString(oldRow, entryMap), this.toHistoryString(newRow, entryMap))
        }
      } 
      else {
        history.pushDetails('Создание документа')
      }

      // Поправим данные документа

      rows.forEach((row, index) => {
        row.setValue(WriteOffInventoryRow.VAL_DOC_ID, doc.id)
        row.setValue(WriteOffInventoryRow.VAL_INDEX, index)
      })

      const total = rows.reduce((s, r) => s + this.getRowSum(r, entryMap), 0)
      doc.setValue(WriteOffInventory.VAL_TOTAL, total)

      if (history.isEmpty()) {
        doc.setValue(WriteOffInventory.VAL_AUTHOR_ID, oldDoc?.authorId || employeeId)
      } else {
        doc.setValue(WriteOffInventory.VAL_AUTHOR_ID, employeeId)
      }

      // Запишем в базу данных

      return this.holdSave(doc, rows, history, entryMap)
  }

  private static async holdSave(doc: WriteOffInventory, rows: WriteOffInventoryRow[], history: HistoryBuilder, entryMap: EntryMap): Promise<boolean> {

    // Обработка

    const createActions = !doc.toDelete
    const request = new DataRequest()

    // Сам документ

    request.addPutEntries(WriteOffInventory.MODEL, [doc])

    request.addDelEntriesWhere(WriteOffInventoryRow.MODEL, [
      [ WriteOffInventoryRow.VAL_DOC_ID, '==', doc.id ]
    ])
    request.addPutEntries(WriteOffInventoryRow.MODEL, rows)

    // Регистр партий

    request.addDelEntriesWhere(PartyRecord.MODEL, [
      [ PartyRecord.VAL_DOC_MODEL, '==', WriteOffInventory.MODEL ],
      [ PartyRecord.VAL_DOC_ID, '==', doc.id ]
    ])
    if (createActions) {
      request.addPutEntries(PartyRecord.MODEL, rows.map(row => PartyRecord.new({
        [PartyRecord.VAL_DATE]: doc.date,
        [PartyRecord.VAL_DOC_MODEL]: WriteOffInventory.MODEL,
        [PartyRecord.VAL_DOC_ID]: doc.id,
        [PartyRecord.VAL_STORAGE_ID]: doc.storageId,
        [PartyRecord.VAL_PARTY_ID]: row.partyId,
        [PartyRecord.VAL_AMOUNT]: -row.amount
      })))
    }

    // Регистр оборудования

    request.addDelEntriesWhere(EquipmentRecord.MODEL, [
      [ EquipmentRecord.VAL_DOC_MODEL, '==', WriteOffInventory.MODEL ],
      [ EquipmentRecord.VAL_DOC_ID, '==', doc.id ]
    ])
    if (createActions) {
      request.addPutEntries(EquipmentRecord.MODEL, rows.filter(row => (row.equipmentId)).map(row => EquipmentRecord.new({
        [EquipmentRecord.VAL_DATE]: doc.date,
        [EquipmentRecord.VAL_DOC_MODEL]: WriteOffInventory.MODEL,
        [EquipmentRecord.VAL_DOC_ID]: doc.id,
        [EquipmentRecord.VAL_EQUIPMENT_ID]: row.equipmentId,
        [EquipmentRecord.VAL_INVENTORY]: entryMap.getEntryById(Party.MODEL, row.partyId).inventory,
        [EquipmentRecord.VAL_MEASURE]: entryMap.getEntryById(Party.MODEL, row.partyId).measure,
        [EquipmentRecord.VAL_AMOUNT]: row.amount,
        [EquipmentRecord.VAL_SUM]: this.getRowSum(row, entryMap),
      })))
    }

    // История

    request.addPutEntries(HistoryRecord.MODEL, history.getList())

    // Запись

    await this.dm.send(request)
    return true
  }

  private static getRowSum(row: WriteOffInventoryRow, entryMap: EntryMap): number {
    const party = entryMap.getEntryById(Party.MODEL, row.partyId)
    return Number(party.sum) / Number(party.amount) * row.amount
  }

  private static toHistoryString(row: WriteOffInventoryRow | undefined, entryMap: EntryMap) {
    if (row) {
      const vals = []
      vals.push(entryMap.getDisplayName(Party.MODEL, row.partyId))
      vals.push(entryMap.getDisplayName(Equipment.MODEL, row.equipmentId))
      vals.push(row.amount || 0)
      return vals.join(', ')
    } else {
      return ''
    }
  }
}