import StoredEntry from "@/core/model/StoredEntry";

export default class EntryMap {

  private modelMap: Map<string, Map<string, StoredEntry>>

  constructor() {
    this.modelMap = new Map()
  }

  add(map: EntryMap) {
    for (const model of map.modelMap.keys()) {
      const curIdMap = this.modelMap.get(model) || new Map()
      const inpIdMap = map.modelMap.get(model)!
      for (const id of inpIdMap.keys()) {
        curIdMap.set(id, inpIdMap.get(id))
      }
      this.modelMap.set(model, curIdMap)
    }
  }

  addEntries(model: string, entries: StoredEntry[]) {
    const idMap = this.modelMap.get(model) || new Map()
    for (const entry of entries) {
      idMap.set(entry.id, entry)
    }
    this.modelMap.set(model, idMap)
  }

  addEntry(model: string, entry: StoredEntry) {
    const idMap = this.modelMap.get(model) || new Map()
    idMap.set(entry.id, entry)
    this.modelMap.set(model, idMap)
  }

  get models(): IterableIterator<string> { return this.modelMap.keys() }

  containsModel(model: string): boolean {
    return this.modelMap.has(model)
  }

  containsId(model: string, id: string): boolean {
    const idMap = this.modelMap.get(model)
    return (idMap) ? idMap.has(id) : false
  }

  //

  getEntries(model: string, condition?: (_: any) => boolean, handler?: (_: any) => any): any[] {
    const idMap = this.modelMap.get(model)
    if (idMap) {
      const entries = Array.from(idMap.values())
      const filtred = (condition) ? entries.filter(condition) : entries
      return (handler) ? filtred.map(handler) : filtred
    } else {
      return []
    }
  }

  //

  getEntry(model: string, condition: (_: any) => boolean, handler?: (_: any) => any, nullValue?: any): any {
    const idMap = this.modelMap.get(model)
    if (idMap) {
      const entry = Array.from(idMap.values()).find(condition)
      if (entry) {
        return (handler) ? handler(entry) : entry
      } else {
        return nullValue
      }
    } else {
      return undefined
    }
  }

  getEntryById(model: string, id: string, handler?: (_: any) => any, nullValue?: any): any {
    const entry = this.modelMap.get(model)?.get(id)
    if (entry) {
      return (handler) ? handler(entry) : entry
    } else {
      return nullValue
    }
  }

  getEntryByName(model: string, name: string, handler?: (_: any) => any, nullValue?: any): any {
    return (name) ? this.getEntry(model, it => it.name === name, handler, nullValue) : undefined
  }

  //

  getDisplayItems(model: string, condition?: (_: any) => boolean): any[] {
    return this.getEntries(model, condition, it => this.toDisplayItem(it))
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
  }

  getDisplayNames(model: string, condition?: (_: any) => boolean): string[] {
    return this.getEntries(model, condition, it => it.name)
      .sort()
  }

  //

  getDisplayItem(model: string, id: string): any {
    return (id) ? this.getEntryById(model, id, it => this.toDisplayItem(it), { id: id, name: `<(${model}:${id})>` }) : undefined
  }

  getDisplayName(model: string, id: string): string {
    return (id) ? this.getEntryById(model, id, it => it.name, `<(${model}:${id})>`) : ''
  }

  //

  private toDisplayItem(entry: StoredEntry): any {
    return { id: entry.id, name: entry.name }
  }
}