import StoredEntry from "../StoredEntry"
import EntryHelper from "../EntryHelper"
import Property, { BoolProperty, IDProperty, StringProperty } from "../Property"
import Place from "./Place"

export default class Equipment extends StoredEntry {
  static MODEL = 'kr2v'

  static VAL_NAME = 'o96o'
  static VAL_PLACE_ID = 'on1w'
  static VAL_TODELETE = 'a3yq'
  
  static PROPS: Property[] = StoredEntry.PROPS.concat([
    new StringProperty(this.VAL_NAME), new IDProperty(this.VAL_PLACE_ID, Place.MODEL),
    new BoolProperty(this.VAL_TODELETE)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get name(): string { return this.getValue(Equipment.VAL_NAME) }
  get placeId(): string { return this.getValue(Equipment.VAL_PLACE_ID) }
  get toDelete(): boolean { return this.getValue(Equipment.VAL_TODELETE) }

  static new(value: any): Equipment {
    return EntryHelper.fillEntry(new Equipment(), value)
  }
}