import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-616076f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-base-input" }
const _hoisted_2 = ["id", "autocomplete", "spellcheck", "value"]
const _hoisted_3 = ["id", "autocomplete", "spellcheck"]
const _hoisted_4 = { class: "picker" }
const _hoisted_5 = ["onMousedown"]
const _hoisted_6 = { class: "picker-load-indicator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.readonly)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          id: _ctx.elementId,
          type: "text",
          autocomplete: _ctx.autocomplete,
          spellcheck: _ctx.spellcheck,
          class: "value-input",
          style: _normalizeStyle([_ctx.alignmentStyle, _ctx.fieldStyle]),
          value: _ctx.valueText,
          readonly: ""
        }, null, 12, _hoisted_2))
      : _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 1,
          id: _ctx.elementId,
          type: "text",
          autocomplete: _ctx.autocomplete,
          spellcheck: _ctx.spellcheck,
          class: "value-input",
          style: _normalizeStyle([_ctx.alignmentStyle, _ctx.fieldStyle]),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueText) = $event)),
          onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
          onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMouseDown && _ctx.onMouseDown(...args))),
          onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onInputText && _ctx.onInputText(...args))),
          onKeydown: [
            _cache[5] || (_cache[5] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onKeyboardDown && _ctx.onKeyboardDown(...args)), ["prevent"]), ["down"])),
            _cache[6] || (_cache[6] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onKeyboardUp && _ctx.onKeyboardUp(...args)), ["prevent"]), ["up"])),
            _cache[7] || (_cache[7] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onKeyboardEnter && _ctx.onKeyboardEnter(...args)), ["prevent"]), ["enter"])),
            _cache[8] || (_cache[8] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onKeyboardEsc && _ctx.onKeyboardEsc(...args)), ["esc"]))
          ]
        }, null, 44, _hoisted_3)), [
          [_vModelText, _ctx.valueText]
        ]),
    _withDirectives(_createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pickerItems, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          style: _normalizeStyle(_ctx.alignmentStyle),
          onMousedown: _withModifiers(($event: any) => (_ctx.onMouseSelect(item)), ["prevent"])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["picker-item", { 'selected': index === _ctx.pickerSelectedIndex }])
          }, _toDisplayString(item.text), 3)
        ], 44, _hoisted_5))
      }), 128))
    ], 512), [
      [_vShow, _ctx.pickerVisible && !_ctx.pickerIsLoading && _ctx.pickerItems.length > 0]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, "Загрузка...", 512), [
      [_vShow, _ctx.pickerVisible && _ctx.pickerIsLoading]
    ])
  ]))
}