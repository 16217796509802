
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    buttonStyle: {
      type: String,
      default: 'bordered',
      validator: (value: string) => ['plain', 'bordered', 'submit'].includes(value)
    },
    role: {
      type: String,
      default: 'default',
      validator: (value: string) => ['default', 'destructive'].includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit('onClick');
      }
    }
  }
})
