
export default class PDFText {

  text: string
  style: string[]

  constructor(text: string, style?: string | string[]) {
    this.text = text
    if (style) {
      if (Array.isArray(style)) {
        this.style = style
      } else {
        this.style = [style]
      }
    } else {
      this.style = []
    }
  }

  static getContent(value: any, style?: string[]): { [name: string]: any } {
    if (value) {
      if (Array.isArray(value)) {
        return { stack: value.map(it => this.getContent(it)), style: style }
      } else if (value instanceof PDFText) {
        const finalStyle = value.style
        if (style) finalStyle.concat(style)
        return { text: value.text, style: finalStyle }
      } else if (typeof value === 'string') {
        return { text: value, style: style }
      } else {
        return { text: '<Unknown>', style: style }
      }
    } else {
      return { text: '', style: style }
    }
  }
}