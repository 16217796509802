import UIWidth from "@/use/base/h/UIWidth"

class TableHeaderMaster {

  fixed(value: number): UIWidth { return UIWidth.fixed(value) }
  flex(value: number): UIWidth { return UIWidth.flex(value) }
  pct(value: number): UIWidth { return UIWidth.pct(value) }

  INDEX = UIWidth.fixed(40) // найти вхождения
  DATE = UIWidth.fixed(120)
  DOC_NUMBER = UIWidth.fixed(100)
  AMOUNT = UIWidth.fixed(100)
  SUM = UIWidth.fixed(140)
  CURRENCY = UIWidth.fixed(220)
  MEASURE = UIWidth.fixed(80)
  ACTION = UIWidth.fixed(40) // найти вхождения
}

export default class TableUtils {

  private static thm = new TableHeaderMaster()
  static headers(block: (_: TableHeaderMaster) => any[]): any[] { return block(this.thm) }

  static isEmptyRow(row: any) {
    for (const key in row) {
      if (key.charAt(0) != '_' && (row[key])) return false
    }
    return true
  }

  static isContainsEmptyRow(source: any[]) {
    if (source.length === 0) {
      return false
    } else {
      return this.isEmptyRow(source[source.length - 1])
    }
  }

  static getFilledRows(source: any[]): any[] {
    if (source.length > 0 && this.isEmptyRow(source[source.length - 1])) {
      return source.slice(0, -1)
    } else {
      return source
    }
  }
}