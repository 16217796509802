import StoredEntry from '../model/StoredEntry';
import * as ServerKey from '../server/ServerKeys';

export default class DataRequest {

  private _ops: any[]

  constructor() {
    this._ops = []
  }

  addOperation(name: string, query: any, key: string = '') {
    const op = (query) ? { ...query } : { }
    op[ServerKey.OPERATION_NAME] = name
    op[ServerKey.OPERATION_KEY] = key
    this._ops.push(op)
  }

  addGetAllEntries(model: string, key: string = '') {
    this._ops.push({
      [ServerKey.OPERATION_NAME]: ServerKey.OPERATION_GET_ENTRIES,
      [ServerKey.OPERATION_KEY]: key,
      [ServerKey.QUERY_MODEL]: model,
      [ServerKey.QUERY_TYPE]: ServerKey.QUERY_TYPE_ALL
    })
  }

  addGetEntriesByIds(model: string, ids: string[], key: string = '') {
    this._ops.push({
      [ServerKey.OPERATION_NAME]: ServerKey.OPERATION_GET_ENTRIES,
      [ServerKey.OPERATION_KEY]: key,
      [ServerKey.QUERY_MODEL]: model,
      [ServerKey.QUERY_TYPE]: ServerKey.QUERY_TYPE_IDS,
      [ServerKey.QUERY_VALUE]: ids
    })
  }

  addGetEntriesWhere(model: string, condition: any[], key: string = '') {
    this._ops.push({
      [ServerKey.OPERATION_NAME]: ServerKey.OPERATION_GET_ENTRIES,
      [ServerKey.OPERATION_KEY]: key,
      [ServerKey.QUERY_MODEL]: model,
      [ServerKey.QUERY_TYPE]: ServerKey.QUERY_TYPE_CONDITION,
      [ServerKey.QUERY_VALUE]: condition
    })
  }

  addPutEntries(model: string, entries: StoredEntry[], key: string = '') {
    this._ops.push({
      [ServerKey.OPERATION_NAME]: ServerKey.OPERATION_PUT_ENTRIES,
      [ServerKey.OPERATION_KEY]: key,
      [ServerKey.QUERY_MODEL]: model,
      [ServerKey.QUERY_VALUE]: entries.map(it => it.getRawData())
    })
  }

  addDelEntriesByIds(model: string, ids: string[], key: string = '') {
    this._ops.push({
      [ServerKey.OPERATION_NAME]: ServerKey.OPERATION_DEL_ENTRIES,
      [ServerKey.OPERATION_KEY]: key,
      [ServerKey.QUERY_MODEL]: model,
      [ServerKey.QUERY_TYPE]: ServerKey.QUERY_TYPE_IDS,
      [ServerKey.QUERY_VALUE]: ids
    })
  }

  addDelEntriesWhere(model: string, condition: any[], key: string = '') {
    this._ops.push({
      [ServerKey.OPERATION_NAME]: ServerKey.OPERATION_DEL_ENTRIES,
      [ServerKey.OPERATION_KEY]: key,
      [ServerKey.QUERY_MODEL]: model,
      [ServerKey.QUERY_TYPE]: ServerKey.QUERY_TYPE_CONDITION,
      [ServerKey.QUERY_VALUE]: condition
    })
  }

  toRawObject(): any  {
    return {
      [ServerKey.OPERATIONS]: this._ops
    }
  }
}