import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UniversalUnitsPage = _resolveComponent("UniversalUnitsPage")!

  return (_openBlock(), _createBlock(_component_UniversalUnitsPage, {
    model: _ctx.unitModel,
    "panel-title": "Контрагенты",
    "table-headers": _ctx.tableHeaders,
    "load-config": _ctx.loadConfig,
    "edit-permission": _ctx.editPermission
  }, null, 8, ["model", "table-headers", "load-config", "edit-permission"]))
}