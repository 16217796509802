import { PurchaseOrderStatus } from "@/core/model/Types";

const order_status_items = [
  { id: PurchaseOrderStatus.NEW, name: 'Новая' },
  { id: PurchaseOrderStatus.AGREED, name: 'Согласована' },
  { id: PurchaseOrderStatus.APPROVED, name: 'Утверждена' },
  { id: PurchaseOrderStatus.CANCELED, name: 'Отменена' },
]

class PurchaseOrderStatusM {

  getNames(): string[] {
    return order_status_items.map(it => it.name)
  }

  getName(value: string): string | undefined {
    const pair = order_status_items.find(it => it.id === value )
    return (pair) ? pair.name : undefined
  }

  getDisplayItem(value: string): any {
    return order_status_items.find(it => it.id === value )
  }

  getDisplayItems(): any[] {
    return order_status_items
  }
}

export default new PurchaseOrderStatusM()