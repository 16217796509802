
export const VAL_LAST_NUMBERS = 'ej6i'
export const VAL_PARAMS = 't5tr'

export const LAST_NUMBERS = 'p7nc'
export const LAST_NUMBER_YEAR = 'tv8s'
export const LAST_NUMBER_VALUE = 'bf9g'
export const COMPANY_FULL_NAME = 'i9db'
export const DIRECTOR_SHORT_NAME = 'bm3n'
export const CHIEF_ENGINEER_SHORT_NAME = 'g1tn'
