
import { defineComponent } from 'vue';

function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(d.getTime())
}

export default defineComponent({
  props: {
    elementId: String,
    value: Date,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value'],
  computed: {
    displayDate() { 
      if (this.value && isValidDate(this.value)) {
        return this.value.toISOString().substring(0, 10)
      } else {
        return undefined
      }
    }
  },
  methods: {
    handleInput(e: any) {
      let date = new Date(e.target.value)
      if (isValidDate(date)) {
        this.$emit('update:value', date)
      } else {
        this.$emit('update:value', undefined)
      }
    },
  },
})
