import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIReadTable = _resolveComponent("UIReadTable")!
  const _component_UIPanel = _resolveComponent("UIPanel")!
  const _component_DefaultPage = _resolveComponent("DefaultPage")!

  return (_openBlock(), _createBlock(_component_DefaultPage, null, {
    default: _withCtx(() => [
      _createVNode(_component_UIPanel, {
        title: "История",
        isLoading: _ctx.isLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_UIReadTable, {
            headers: _ctx.tableHeaders,
            rows: _ctx.tableRows
          }, null, 8, ["headers", "rows"])
        ]),
        _: 1
      }, 8, ["isLoading"])
    ]),
    _: 1
  }))
}