import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c46f86a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-read-table" }
const _hoisted_2 = { ref: "table" }
const _hoisted_3 = ["onDblclick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("colgroup", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, idx) => {
          return (_openBlock(), _createElementBlock("col", {
            key: header.key,
            style: _normalizeStyle({ width: _ctx.tableWidths[idx] })
          }, null, 4))
        }), 128))
      ]),
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
            return (_openBlock(), _createElementBlock("th", {
              key: header.key
            }, _toDisplayString(header.name), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayRows, (row, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: `row_${index}`
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, idx) => {
              return (_openBlock(), _createElementBlock("td", {
                key: header.key,
                style: _normalizeStyle({ width: `${_ctx.tableWidths[idx]}`, textAlign: header.alignment }),
                onDblclick: ($event: any) => (_ctx.handleDoubleClick(row, header.key))
              }, _toDisplayString(row[header.key]), 45, _hoisted_3))
            }), 128))
          ]))
        }), 128))
      ])
    ], 512)
  ]))
}