import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ba9dd84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-alert-view" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_UIPopupContent = _resolveComponent("UIPopupContent")!

  return (_openBlock(), _createBlock(_component_UIPopupContent, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayTexts, (text) => {
          return (_openBlock(), _createElementBlock("p", {
            key: text,
            class: "text"
          }, _toDisplayString(text), 1))
        }), 128)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UIButton, { onClick: _ctx.handleDoneClick }, {
            default: _withCtx(() => [
              _createTextVNode("Ок")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }))
}