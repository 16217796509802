
import { defineComponent } from 'vue';
import DefaultPage from "@/use/components/DefaultPage.vue";
import { UIPanel, UIReadTable } from "@/use/base/Types";
import { OrderRecord, StorageRecord, EquipmentRecord, PurchaseOrder, PurchaseOrderRow, Storage, Equipment, PartyRecord, Party } from "@/core/model/Types";
import DataRequest from '@/core/data/DataRequest';
import EntryLoader from '@/lib/data/EntryLoader';
import EntryMap from '@/lib/data/EntryMap';
import { NumberUtils, TableUtils } from '@/lib/util/Utils';

const REG_CONFIG = {
  [OrderRecord.MODEL]: {
    tableHeaders: TableUtils.headers(m => [
      { key: 'order', name: 'Заказ', width: m.flex(1) },
      { key: 'item', name: 'Позиция', width: m.flex(2) },
      { key: 'amount', name: 'Кол-во', width: m.AMOUNT, alignment: 'right' },
    ]),
    getCondition: (model: string, id: string) => ([
      [ OrderRecord.VAL_DOC_MODEL, '==', model ],
      [ OrderRecord.VAL_DOC_ID, '==', id ]
    ]),
    loadTableRows: (loader: EntryLoader, records: OrderRecord[]): Promise<any> => {
      return loader.loadDetails(records, [
        { key: OrderRecord.VAL_ORDER_ID, model: PurchaseOrder.MODEL },
        { key: OrderRecord.VAL_ORDER_ROW_ID, model: PurchaseOrderRow.MODEL },
      ])
        .then(details => {
          return records.map(it => ({
            order: details.getDisplayName(PurchaseOrder.MODEL, it.orderId),
            item: details.getDisplayName(PurchaseOrderRow.MODEL, it.orderRowId),
            amount: NumberUtils.formatedNumber(it.amount)
          }))
            .sort((a: any, b: any) => a.item.localeCompare(b.item))
        })
    }
  },
  [PartyRecord.MODEL]: {
    tableHeaders: TableUtils.headers(m => [
      { key: 'storage', name: 'Склад', width: m.flex(1) },
      { key: 'party', name: 'Партия', width: m.flex(3) },
      { key: 'amount', name: 'Кол-во', width: m.AMOUNT, alignment: 'right' },
    ]),
    getCondition: (model: string, id: string) => ([
      [ PartyRecord.VAL_DOC_MODEL, '==', model ],
      [ PartyRecord.VAL_DOC_ID, '==', id ]
    ]),
    loadTableRows: (loader: EntryLoader, records: PartyRecord[]): Promise<any> => {
      return loader.loadDetails(records, [
        { key: PartyRecord.VAL_STORAGE_ID, model: Storage.MODEL },
        { key: PartyRecord.VAL_PARTY_ID, model: Party.MODEL },
      ])
        .then(details => {
          const orderIds = [ ...new Set(details.getEntries(Party.MODEL).map(it => it.orderId)) ]
          return loader.loadEntriesByIds<PurchaseOrder>(PurchaseOrder.MODEL, orderIds)
            .then(orders => {
              details.addEntries(PurchaseOrder.MODEL, orders)
              return records.map(it => {
                const party = details.getEntryById(Party.MODEL, it.partyId)!
                const nameArr = [
                  party.inventory,
                  `${NumberUtils.formatedNumber(party.amount)} ${party.measure}`,
                  NumberUtils.formatedCurrency(party.sum),
                  details.getDisplayName(PurchaseOrder.MODEL, party.orderId)
                ]
                return {
                  storage: details.getDisplayName(Storage.MODEL, it.storageId),
                  party: nameArr.join(' / '),
                  amount: NumberUtils.formatedNumber(it.amount),
                }
              })
                .sort((a: any, b: any) => a.storage.localeCompare(b.storage) || a.party.localeCompare(b.party))
            })
        })
    }
  },
  [EquipmentRecord.MODEL]: {
    tableHeaders: TableUtils.headers(m => [
      { key: 'equipment', name: 'Оборудование', width: m.flex(1) },
      { key: 'inventory', name: 'Инвентарь', width: m.flex(1) },
      { key: 'measure', name: 'Ед.', width: m.MEASURE },
      { key: 'amount', name: 'Кол-во', width: m.AMOUNT, alignment: 'right' },
      { key: 'sum', name: 'Сумма', width: m.CURRENCY, alignment: 'right' },
    ]),
    getCondition: (model: string, id: string) => ([
      [ EquipmentRecord.VAL_DOC_MODEL, '==', model ],
      [ EquipmentRecord.VAL_DOC_ID, '==', id ]
    ]),
    loadTableRows: (loader: EntryLoader, records: EquipmentRecord[]): Promise<any> => {
      return loader.loadDetails(records, [
        { key: EquipmentRecord.VAL_EQUIPMENT_ID, model: Equipment.MODEL },
      ])
        .then(details => {
          return records.map(it => ({
            equipment: details.getDisplayName(Equipment.MODEL, it.equipmentId),
            inventory: it.inventory,
            measure: it.measure,
            amount: NumberUtils.formatedNumber(it.amount),
            sum: NumberUtils.formatedCurrency(it.sum),
          }))
            .sort((a: any, b: any) => a.equipment.localeCompare(b.equipment) || a.inventory.localeCompare(b.inventory))
        })
    }
  }
}

export default defineComponent({
  components: {
    DefaultPage,
    UIPanel, UIReadTable,
  },
  data() {
    return {
      loader: new EntryLoader(),
      tableRows: new Map<string, any[]>(),
      isLoading: false,
    }
  },
  computed: {
    models(): string[] { return Object.keys(REG_CONFIG) },
  },
  methods: {
    getTableTitle(model: string): string {
      switch (model) {
        case OrderRecord.MODEL: return 'Регистр заказов'
        case StorageRecord.MODEL: return 'Регистр склада'
        case PartyRecord.MODEL: return 'Регистр партий'
        case EquipmentRecord.MODEL: return 'Регистр оборудования'
        default:
          return 'Undefined'
      }
    },
    getTableHeaders(model: string): any[] { return REG_CONFIG[model].tableHeaders },
    getTableRows(model: string): any[] { return this.tableRows.get(model) || [] },
    handleResuls(map: EntryMap, callback: any) {

      const self = this
      let models = Object.keys(REG_CONFIG)

      function done() {
        const model = models.shift()
        if (model) {
          const records = map.getEntries(model)
          if (records.length > 0) {
            REG_CONFIG[model].loadTableRows(self.loader as EntryLoader, records)
              .then(rows => { self.tableRows.set(model, rows) })
              .finally(() => { done() })
          } else {
            done()
          }
        } else {
          callback()
        }
      }
      done()
    },
    reloadData() {
      this.isLoading = true

      const docModel = this.$sm.getCurrentPathName()
      const docId = this.$sm.getCurrentParam('id')

      const request = new DataRequest()
      for (const regModel in REG_CONFIG) {
        request.addGetEntriesWhere(regModel, REG_CONFIG[regModel].getCondition(docModel, docId), regModel)
      }

      this.loader.load(request)
        .then(map => {
          this.handleResuls(map, () => {
            this.isLoading = false
          })
        })
        .catch(() => {
          this.isLoading = false
        })
    },
  },
  created() {
    this.reloadData()
  },
})
