import * as ServerKey from './ServerKeys'

function localizedObject(obj: any): any {
  const lobj: { [name: string]: any } = {}
  for (const key in obj) {
    lobj[localizedKey(key)] = localizedValue(obj[key])
  }
  return lobj
}

function localizedArray(arr: any[]): any[] {
  const larr = []
  for (const value of arr) {
    larr.push(localizedValue(value))
  }
  return larr
}

function localizedKey(key: string): string {
  switch (key) {
    case ServerKey.OPERATIONS: return 'OPERATIONS'
    case ServerKey.OPERATION_NAME: return 'NAME'
    case ServerKey.OPERATION_KEY: return 'KEY'
    case ServerKey.OPERATION_VALUE: return 'VALUE'
    case ServerKey.OPERATION_GET_ENTRIES: return 'GET_OBJECTS'
    case ServerKey.OPERATION_PUT_ENTRIES: return 'PUT_OBJECTS'
    case ServerKey.OPERATION_DEL_ENTRIES: return 'DEL_OBJECTS'
    case ServerKey.OPERATION_GET_VALUE: return 'GET_VALUE'
    case ServerKey.OPERATION_SET_VALUE: return 'SET_VALUE'
    case ServerKey.QUERY_TYPE: return 'TYPE'
    case ServerKey.QUERY_TYPE_ALL: return 'ALL'
    case ServerKey.QUERY_TYPE_IDS: return 'IDS'
    case ServerKey.QUERY_TYPE_CONDITION: return 'CONDITION'
    case ServerKey.QUERY_MODEL: return 'MODEL'
    case ServerKey.QUERY_KEY: return 'KEY'
    case ServerKey.QUERY_VALUE: return 'VALUE'
    case ServerKey.SUCCESS: return 'SUCCESS'
    case ServerKey.RESULTS: return 'RESULTS'
    case ServerKey.ERROR: return 'ERROR'
    case ServerKey.DB_NAME: return 'DB'
    default: return key
  }
}

function localizedValue(value: any): any {
  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return localizedArray(value)
    } else {
      return localizedObject(value)
    }
  } else {
    return localizedKey(value)
  }
}

function errorAnswer(message: string): any {
  return {
    [ServerKey.SUCCESS]: false,
    [ServerKey.ERROR]: message
  }
}

export default class ServerClient {

  send(request: any, callback: ((_: any) => void) | undefined) {

    const xhr = new XMLHttpRequest()
    
    xhr.open('POST', 'https://api-tmc-miz.persapps.com', true)
    xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8')
    xhr.timeout = 30 * 1000
    xhr.responseType = 'json'

    xhr.onload = () => {
      if (xhr.status !== 200) {
        if (callback) callback(errorAnswer(`HTTP error: ${xhr.status} - ${xhr.statusText}`))
        this.__printError(`HTTP error: ${xhr.status} - ${xhr.statusText}`, request)
      } else {
        if (callback) callback(xhr.response)
        this.__printAnswer(xhr.response, request)
      }
    }
    xhr.onerror = () => {
      if (callback) callback(errorAnswer(`HTTP Unknown error!`))
      this.__printError(`${xhr.responseText}`, request)
    }
    xhr.ontimeout = () => {
      if (callback) callback(errorAnswer(`HTTP Timeout!`))
      this.__printError('HTTP Timeout!', request)
    }    
    xhr.send(JSON.stringify(request))
  }

  __handleAnswer(request: any, answer: any) {
    if (answer) {
      if (answer[ServerKey.SUCCESS]) {
        this.__printAnswer(answer, request)
      } else {
        this.__printError(localizedObject(answer), request)
      }
    } else {
      this.__printError('unknown', request)
    }
  }

  __printAnswer(answer: any, request: any) {
    console.log('SERVER =>', localizedObject(answer), 'request', localizedObject(request))
  }

  __printError(error: any, request: any) {
    console.error('SERVER => ', error, 'request', localizedObject(request))
  }
}