
import { defineComponent } from 'vue';
import DefaultPage from '@/use/components/DefaultPage.vue';
import { UIPanel, UIToolbar, UIButton, UITextField, UIEntryField, UIAlertView } from '@/use/base/Types';
import ToDeleteButton from '@/use/components/ToDeleteButton.vue';
import { Equipment, Place } from '@/core/model/Types';
import AppContext from '@/core/AppContext'
import EntryLoader from '@/lib/data/EntryLoader';
import * as Permission from '@/core/safe/Permissions'
import { EntryUtils } from '@/lib/util/Utils';

export default defineComponent({
  components: {
    DefaultPage, 
    UIPanel, UIToolbar, UIButton, UITextField, UIEntryField, UIAlertView,
    ToDeleteButton
  },
  data() {
    return {
      grantedEdit: false,
      loader: new EntryLoader(),
      name: '',
      place: undefined,
      displayName: '',
      displayPlace: '',
      toDelete: false,
      isLoading: false,
      alert: undefined as any,
    }
  },
  computed: {
    objId(): string { return this.$sm.getCurrentParam('id') },
  },
  methods: {
    configureView(entry: Equipment) {
      this.name = entry.name
      this.place = this.loader.data.getDisplayItem(Place.MODEL, entry.placeId)
      this.toDelete = Boolean(entry.toDelete)
    },
    placeVariantsGetter(callback: any) {
      return callback(this.loader.data.getDisplayItems(Place.MODEL))
    },
    handleSaveClick() {

      const emptyValues = []

      if (!this.name) emptyValues.push('Название')
      if (EntryUtils.isEmpty(this.place)) emptyValues.push('Участок')

      if (emptyValues.length > 0) {
        this.alert = { title: 'Ошибка', text: ['Заполните поля:', `"${emptyValues.join('", "')}"`] }
        return
      }

      this.$dm.getEntryById(Equipment.MODEL, this.objId)
        .then(storedEntry => {
          let entry = storedEntry || Equipment.new(this.objId)
          // Обновляем объект
          entry.setValues({
            [Equipment.VAL_NAME]: this.name,
            [Equipment.VAL_PLACE_ID]: EntryUtils.getId(this.place),
            [Equipment.VAL_TODELETE]: this.toDelete
          })
          // Сохраняем объект
          return this.$dm.putEntry(Equipment.MODEL, entry)
            .then(() => this.$sm.goBack())
        })
    },
  },
  created() {

    AppContext.getSafeManager().getCurrentPermissions(perms => {
      this.grantedEdit = perms.includes(Permission.EQUIPMENT_EDIT)
    })

    this.isLoading = true

    this.loader.loadAllEntries(Place.MODEL)
      .then(() => {
        return this.$dm.getEntryById(Equipment.MODEL, this.objId)
          .then(storedEntry => {
            let entry = storedEntry || Equipment.new(this.objId)
            this.configureView(entry)
          })
      })
      .catch(() => this.$sm.goBack())
      .finally(() => this.isLoading = false)
  }
})
