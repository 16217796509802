import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bba47b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions-table" }
const _hoisted_2 = { class: "action-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_UIPanel = _resolveComponent("UIPanel")!
  const _component_DefaultPage = _resolveComponent("DefaultPage")!

  return (_openBlock(), _createBlock(_component_DefaultPage, null, {
    default: _withCtx(() => [
      (_ctx.granted)
        ? (_openBlock(), _createBlock(_component_UIPanel, {
            key: 0,
            title: "Операции"
          }, {
            default: _withCtx(() => [
              _createElementVNode("table", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.operations, (op) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: op.name,
                    class: "artion-row"
                  }, [
                    _createElementVNode("td", _hoisted_2, _toDisplayString(op.name), 1),
                    _createElementVNode("td", null, [
                      _createVNode(_component_UIButton, {
                        onClick: ($event: any) => (_ctx.performOperation(op)),
                        disabled: op.actived || !op.enabled || !_ctx.enabled
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Выполнить")
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"])
                    ]),
                    _createElementVNode("td", null, _toDisplayString(op.state), 1)
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}