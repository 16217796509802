
import { defineComponent } from 'vue';
import UniversalDocsPage from "./UniversalDocsPage.vue";
import { ClosingOrder } from "@/core/model/Types";
import * as Permission from '@/core/safe/Permissions'
import { DateUtils, TableUtils } from '@/lib/util/Utils';

export default defineComponent({
  components: { UniversalDocsPage },
  data() {
    return {
      docModel: ClosingOrder.MODEL,
      tableHeaders: TableUtils.headers(m => [
        { key: 'date', name: 'Дата', width: m.DATE }, 
        { key: 'number', name: 'Номер', width: m.DOC_NUMBER },
        { key: 'comment', name: 'Коментарий', width: m.flex(1) },
      ]),
      loadConfig: [
        { name: 'id', key: ClosingOrder.VAL_ID },
        { name: 'date', key: ClosingOrder.VAL_DATE, handler: (v: Date) => DateUtils.formatedDate(v) },
        { name: 'number', key: ClosingOrder.VAL_NUMBER },
        { name: 'comment', key: ClosingOrder.VAL_COMMENT },
        { name: 'toDelete', key: ClosingOrder.VAL_TODELETE }
      ],
      editPermission: Permission.CLOSING_ORDER_EDIT,
      dateKey: ClosingOrder.VAL_DATE,
    }
  },
})
