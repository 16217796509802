
import { defineComponent } from 'vue';
import TopMenu from './TopMenu.vue';
import Breadcrumb from './Breadcrumb.vue';

export default defineComponent({
  components: {
    TopMenu, Breadcrumb
  },
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.$sm.getNameOfPath(this.$sm.getCurrentPath(), name => {
      document.title = name
    })
  }
})
