import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import PDFTable from './PDFTable'
import PDFHelper from './PDFHelper'
import PDFText from './PDFText'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default class PDFBuilder {

  private mOrientation: string = '' // 'portrait', 'landscape'
  private mContent: Array<any> = new Array()
  private mStyles: { [name: string]: any } = {}

  constructor(orientation: string) {
    this.mOrientation = orientation
  }

  // Content

  addCustomContent(content: any) {
    this.mContent.push(content)
  }

  // Style

  private mStyleIndex: number = 0

  private applyStyle(style: any): any {
    if (style) {
      if (Array.isArray(style)) { // Список имен стилей
        return style
      } else if (typeof style === 'object') { // Описание стиля
        this.mStyleIndex += 1
        const name = `v7ku_style_${this.mStyleIndex}`
        this.mStyles[name] = style
        return name
      } else if (typeof style === 'string') { // Название стиля
        return style
      } else { // Неизвестный тип значения
        console.warn('Undefined type of style', style)
        return undefined
      }
    } else { // Отсутствует значение
      return undefined
    }
  }

  setStyle(name: string, style: { [name: string]: any }) {
    this.mStyles[name] = style
  }

  // Text

  addText(value: any, style?: any) {
    const styles = this.applyStyle(style)?.map((it: any) => [it])
    this.mContent.push(PDFText.getContent(value, styles))
  }

  // Header

  addOppositeColumns(leftColumn: any, rightColumn: any, style?: any) {

    const lCont: { [name: string]: any } = { width: '*' }
    if (Array.isArray(leftColumn)) {
      lCont['stack'] = leftColumn
    } else {
      lCont['text'] = leftColumn
    }

    const rCont: { [name: string]: any } = { width: 'auto' }
    if (Array.isArray(rightColumn)) {
      rCont['stack'] = rightColumn
    } else {
      rCont['text'] = rightColumn
    }

    this.mContent.push({
      columns: [lCont, rCont],
      style: this.applyStyle(style)
    })
  }

  // Title

  addTitle(value: any, style?: any) {
    
    const styles = new Array<string>()
    styles.push(this.applyStyle(this.defaultTitleStyle()))
    const styleName = this.applyStyle(style)
    if (styleName) styles.push(styleName)

    this.mContent.push(PDFText.getContent(value, styles))
  }

  // Table

  addTable(table: PDFTable, style?: any) {

    // this.mContent.push({
    //   table: {
    //     widths: [10, '*', 10],
    //     headerRows: 1,
    //     body: [['A', 'B', 'C']]
    //   }
    // })

    const pageWidth = (this.mOrientation === 'landscape') ? PDFHelper.A4_HEIGHT : PDFHelper.A4_WIDTH
    const content = table.getContent(pageWidth - 80)
    content['style'] = this.applyStyle(style)

    this.mContent.push(content)

    const tableStyles = table.getStyles()
    for (const style in tableStyles) {
      this.mStyles[style] = tableStyles[style]
    }
  }

  // Build

  private createDefinition(): any {
    return {
      pageSize: 'A4',
      pageOrientation: this.mOrientation,
      pageMargins: [ 40, 40, 40, 40 ], // [left, top, right, bottom]
      content: this.mContent,
      styles: this.mStyles
    }
  }

  build() {
    const definition = this.createDefinition()
    // console.error(definition)
    pdfMake.createPdf(definition).print()
  }

  // Styles

  private defaultTitleStyle(): any {
    return {
      fontSize: 14,
      bold: true,
      alignment: 'center'
    }
  }
}
