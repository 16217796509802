
import { defineComponent } from 'vue';

export default defineComponent({
  props: ['elementId', 'value', 'placeholder'],
  emits: ['update:value'],
  methods: {
    handleInput(e: any) {
      this.$emit('update:value', e.target?.value)
    },
    handleClearClick() {
      this.$emit('update:value', '');
    },
  },
})
