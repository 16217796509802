import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7345566e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "ui-multiple-button",
  ref: "component"
}
const _hoisted_2 = { class: "picker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UIButton, {
      "button-style": _ctx.buttonStyle,
      disabled: _ctx.disabled,
      onClick: _ctx.onClick
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }, 8, ["button-style", "disabled", "onClick"]),
    _withDirectives(_createElementVNode("ul", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 512), [
      [_vShow, _ctx.pickerVisible]
    ])
  ], 512))
}