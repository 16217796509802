import { uuid } from 'vue-uuid'

export default class StringUtils {

  static correctString(value: any): string { 
    if (typeof value === 'string') {
      return value
    } else if (typeof value === 'number') {
      return String(value)
    } else if (typeof value === 'boolean') {
      return String(value)
    } else {
      return ''
    }
  }

  static isEmpty(value: any): boolean {
    return this.correctString(value) === ''
  }

  static getUUID(): string {
    return uuid.v4()
  }
}