import StoredEntry from "../StoredEntry"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, NumberProperty, StringProperty } from "../Property"
import PurchaseOrder from "../doc/PurchaseOrder"

export default class Party extends StoredEntry {
  static MODEL = 'lg3u'

  static VAL_DOC_MODEL = 'g1my'
  static VAL_DOC_ID = 'eh2r'
  static VAL_INVENTORY = 'ar3e'
  static VAL_MEASURE = 'wb0h'
  static VAL_AMOUNT = 'mi9z'
  static VAL_SUM = 'y1dy'
  static VAL_ORDER_ID = 'j4ey'
  // static VAL_INVENTORY_ID = 'pt8h'
  
  static PROPS: Property[] = StoredEntry.PROPS.concat([
    new StringProperty(this.VAL_DOC_MODEL), new StringProperty(this.VAL_DOC_ID),
    new StringProperty(this.VAL_INVENTORY), new StringProperty(this.VAL_MEASURE),
    new NumberProperty(this.VAL_AMOUNT), new NumberProperty(this.VAL_SUM),
    new IDProperty(this.VAL_ORDER_ID, PurchaseOrder.MODEL),
    // new IDProperty(this.VAL_INVENTORY_ID, Inventory.MODEL)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get name(): string { return `${this.inventory} / ${this.amount} ${this.measure}` }
  
  get docModel(): string { return this.getValue(Party.VAL_DOC_MODEL) }
  get docId(): string { return this.getValue(Party.VAL_DOC_ID) }
  get inventory(): string { return this.getValue(Party.VAL_INVENTORY) }
  get measure(): string { return this.getValue(Party.VAL_MEASURE) }
  get amount(): number { return this.getValue(Party.VAL_AMOUNT) }
  get sum(): number { return this.getValue(Party.VAL_SUM) }
  get orderId(): string { return this.getValue(Party.VAL_ORDER_ID) }
  // get inventoryId(): string { return this.getValue(Party.VAL_INVENTORY_ID) }

  static new(value: any): Party {
    return EntryHelper.fillEntry(new Party(), value)
  }
}