
import { defineComponent } from 'vue';
import DefaultPage from '@/use/components/DefaultPage.vue';
import { UIPanel, UIButton } from '@/use/base/Types';
import AppContext from "@/core/AppContext"
import DMOOperation from './DeletingMarkedObjectsOperation'
import * as Permission from '@/core/safe/Permissions'
import { StringUtils } from '@/lib/util/Utils';

class Operation {
  key: string
  name: string
  constructor(key: string, name: string) {
    this.key = key
    this.name = name
  }
}

class LogItem {
  id: string = StringUtils.getUUID()
  text: string
  warning: boolean
  constructor(text: string, warning: boolean) {
    this.text = text
    this.warning = warning
  } 
}

const OPERATION_DMO = "q0gw"

const OPERATIONS = [
  new Operation(OPERATION_DMO, "Удаление помеченных объектов")
]

export default defineComponent({
  components: {
    DefaultPage, 
    UIPanel, UIButton
  },
  data() {
    return {
      granted: false,
      operations: OPERATIONS,
      states: new Map<string, string>(),
      logs: new Array<LogItem>()
    }
  },
  methods: {
    performOperation(key: string) {

      this.logs = []

      switch (key) {
        case OPERATION_DMO:
          this.performDMOOperation(key)
          break
        default:
          break
      }
    },
    performDMOOperation(key: string) {
      
      const op = new DMOOperation()

      op.onChangeState(state => {
        this.states.set(key, state)
      })

      op.onDeletion(entry => {
        this.logs.unshift(new LogItem(`Удаление: ${entry.name}`, false))
      })

      op.onCancelDeletion((entry, links) => {
        this.logs.unshift(new LogItem(`Отмена удаления: ${entry.name}, из-за ссылок в: ${links.map(it => it.name).join(", ")}`, true))
      })

      op.perform(this, (_) => {
        //
      })
    }
  },
  created() {
    AppContext.getSafeManager().getCurrentPermissions(perms => {
      this.granted = perms.includes(Permission.OPERATIONS)
    })
  }
})
