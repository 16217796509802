import UIWidth from "@/use/base/h/UIWidth"
import PDFHelper from './PDFHelper'
import PDFText from './PDFText'

export default class PDFTable {

  private mLayout: string | undefined
  private mColumnWidths: Array<UIWidth> = []
  private mColumnAligments: Array<string> = []
  private mHeaders: Array<any> = []
  private mRows: Array<any> = []

  constructor(layout?: string) {
    this.mLayout = layout
  }

  setColumnWidths(widths: UIWidth[]) {
    this.mColumnWidths = widths
  }

  setColumnAligment(alignments: string[]) {
    this.mColumnAligments = alignments
  }

  setHeaders(headers: any[]) {
    this.mHeaders = headers
  }

  addRow(row: any[]) {
    this.mRows.push(row)
  }

  getContent(width: number): any {

    const tableWidths = PDFHelper.getTableWidths(this.mColumnWidths, width)
    const tableBody = new Array<any>()

    const addColumnStyle = this.mLayout === 'lightHorizontalLines'

    tableBody.push(
      this.mHeaders.map((it: any, i: number) => {
        if (addColumnStyle) {
          return PDFText.getContent(it, ['c2ve_header', `c2ve_column_${i}`])
        } else {
          return PDFText.getContent(it, ['c2ve_header'])
        }
      })
    )
    for (const row of this.mRows) {
      tableBody.push(
        row.map((it: any, i: number) => {
          return PDFText.getContent(it, [`c2ve_column_${i}`])
        })
      )
    }

    return {
      layout: this.mLayout,
      table: {
        widths: tableWidths,
        headerRows: 1,
        body: tableBody
      }
    }
  }

  getStyles(): any {
    const styles: { [name: string]: any } = { }
    styles['c2ve_header'] = { bold: true, alignment: 'center' }
    for (let i = 0; i < this.mColumnWidths.length; i++) {
      const alignment = (this.mColumnAligments.length > i) ? this.mColumnAligments[i] : undefined
      styles[`c2ve_column_${i}`] = { alignment: alignment || 'left' }
    }
    return styles
  }
}
