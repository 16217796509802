
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    elementId: String,
    name: String,
    value: String,
    checked: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleCheckbox(event: any) {
      this.$emit('update', event.target.value, event.target.checked);
    }
  },
})
