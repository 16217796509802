import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3521f3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIReadTable = _resolveComponent("UIReadTable")!
  const _component_UIPopupContent = _resolveComponent("UIPopupContent")!

  return (_openBlock(), _createBlock(_component_UIPopupContent, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_UIReadTable, {
          headers: _ctx.tableHeaders,
          rows: _ctx.tableRows,
          onOnDoubleClick: _ctx.handleRowDoubleClick
        }, null, 8, ["headers", "rows", "onOnDoubleClick"])
      ])
    ]),
    _: 1
  }))
}