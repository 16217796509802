
function getCookie(name: string): string | undefined {
  const matches = document.cookie.match(new RegExp("(?:^|; )" + name + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name: string, value: string, options: any = {}) {

  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options
  }

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = name + "=" + encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += "; " + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

class Cookies {

  get(name: string): string | undefined {
    return getCookie(name)
  }

  set(name: string, value: string, maxAgeDays: number) {
    setCookie(name, value, { 'max-age': 60*60*24*maxAgeDays })
  }

  del(name: string) {
    setCookie(name, '', { 'max-age': -1 })
  }

  getDate(name: string): Date | undefined {
    const value = this.get(name)
    return (value) ? new Date(Number(value)) : undefined
  }

  setDate(name: string, date: Date, maxAgeDays: number) {
    this.set(name, (date) ? '' + date.getTime() : '', maxAgeDays)
  }

  getObject(name: string): any {
    const value = this.get(name)
    return (value) ? JSON.parse(value) : undefined
  }

  setObject(name: string, value: any, maxAgeDays: number) {
    this.set(name, (value) ? JSON.stringify(value) : '', maxAgeDays)
  }
}

export default new Cookies()