import StoredEntry from "./StoredEntry"
import { uuid } from 'vue-uuid'

export default class EntryHelper {

  static newId(): string {
    return uuid.v4()
  }

  static fillEntry(entry: StoredEntry, value: any): any {
    if (typeof value === 'object') {
      for (const key in value) {
        entry.setValue(key, value[key])
      }
    } else {
      entry.setValue(StoredEntry.VAL_ID, value || this.newId())
    }
    return entry
  }

  static toDateString(date: Date): string {
    return (date) ? date.toLocaleDateString('ru-RU', { year: 'numeric', month: '2-digit', day: '2-digit' }) : 'undefined'
  }
}