import CryptoJS from 'crypto-js';
import { Counterparty, Employee, Equipment, Measure, Place, Inventory, Storage, OrderRecord, PartyRecord, Party } from '../model/Types';
import { PurchaseOrder, PurchaseOrderRow, ClosingOrder, ClosingOrderRow } from '../model/Types';
import { DeliveryInventory, DeliveryInventoryRow, TransferInventory, TransferInventoryRow } from '../model/Types';
import { WriteOffInventory, WriteOffInventoryRow } from '../model/Types';
import { EquipmentRecord, HistoryRecord, StorageRecord } from '../model/Types';
import EntryHelper from '../model/EntryHelper'
import ServerClient from '../server/ServerClient';
import * as ServerKey from '../server/ServerKeys'
import DataRequest from './DataRequest'
import DataResults from './DataResults'
import StoredEntry from '../model/StoredEntry';

// Cache

const CACHE_TIME = 60 * 1000
const cache_base = new Map<string, any>()

function cache_get(key: string): any {
  const item = cache_base.get(key)
  if (item && item[1] > Date.now()) {
    return item[0]
  } else {
    return undefined
  }
}

function cache_set(key: string, value: any) {
  cache_base.set(key, [ value, Date.now() + CACHE_TIME ])
}

function cache_clean() {
  const keys = Array.from(cache_base.keys())
  for (const key of keys) {
    const item = cache_base.get(key)
    if (item && item[1] < Date.now()) {
      cache_base.delete(key)
    }
  }
}

function cache_clear() {
  cache_base.clear()
}

setInterval(() => { cache_clean() }, 67 * 1000)

// Queue

const queue_base = new Map<string, any>()

function queue_get(key: string): any[] {
  return queue_base.get(key)
}

function queue_set(key: string, arr: any[]) {
  queue_base.set(key, arr)
}

function queue_del(key: string) {
  queue_base.delete(key)
}

// Converting

function toEntry(model: string, data: any): any {
  switch (model) {
    // Units
    case Counterparty.MODEL:           return new Counterparty(data)
    case Employee.MODEL:               return new Employee(data)
    case Equipment.MODEL:              return new Equipment(data)
    case Inventory.MODEL:              return new Inventory(data)
    case Measure.MODEL:                return new Measure(data)
    case Party.MODEL:                  return new Party(data)
    case Place.MODEL:                  return new Place(data)
    case Storage.MODEL:                return new Storage(data)
    // Docs
    case PurchaseOrder.MODEL:          return new PurchaseOrder(data)
    case PurchaseOrderRow.MODEL:       return new PurchaseOrderRow(data)
    case ClosingOrder.MODEL:           return new ClosingOrder(data)
    case ClosingOrderRow.MODEL:        return new ClosingOrderRow(data)
    case DeliveryInventory.MODEL:      return new DeliveryInventory(data)
    case DeliveryInventoryRow.MODEL:   return new DeliveryInventoryRow(data)
    case TransferInventory.MODEL:      return new TransferInventory(data)
    case TransferInventoryRow.MODEL:   return new TransferInventoryRow(data)
    case WriteOffInventory.MODEL:      return new WriteOffInventory(data)
    case WriteOffInventoryRow.MODEL:   return new WriteOffInventoryRow(data)
    // Registry
    case EquipmentRecord.MODEL:        return new EquipmentRecord(data)
    case HistoryRecord.MODEL:          return new HistoryRecord(data)
    case OrderRecord.MODEL:            return new OrderRecord(data)
    case PartyRecord.MODEL:            return new PartyRecord(data)
    case StorageRecord.MODEL:          return new StorageRecord(data)
    default:
      throw new Error('bo2n, ' + model)
  }
}

function toEntries(model: string, list: any): any[] {
  return list.map((it: any) => toEntry(model, it))
}

// Helper

function toLocalResults(extResults: any, extRequest: any): any[] {
  if (!extResults) return []

  const locResults = new Array<any>()
  for (let i = 0; i < extResults.length; i++) {
    const op = extRequest[ServerKey.OPERATIONS][i]
    const res = extResults[i]
    switch (op[ServerKey.OPERATION_NAME]) {
      case ServerKey.OPERATION_GET_ENTRIES:
        locResults.push({
          [ServerKey.OPERATION_VALUE]: toEntries(op[ServerKey.QUERY_MODEL], res[ServerKey.OPERATION_VALUE]),
          [ServerKey.OPERATION_KEY]: res[ServerKey.OPERATION_KEY]
        })
        break
      default:
        locResults.push(res)
        break
    }
  }
  return locResults
}

// Manager

export default class DataManager {

  private __server: ServerClient
  private __errorObserver?: any = undefined

  constructor(server: ServerClient) {
    this.__server = server
  }
  
  newEntryId(): string {
    return EntryHelper.newId()
  }

  setErrorObserver(observer: any) {
    this.__errorObserver = observer
  }

  private __handleError(error: string) {
    console.error(error)
    if (this.__errorObserver) this.__errorObserver(error)
  }

  private __getCacheMode(request: any): number {
    const names = request[ServerKey.OPERATIONS].map((op: any) => op[ServerKey.OPERATION_NAME])
    if (names.includes(ServerKey.OPERATION_DEL_ENTRIES)) return -1
    if (names.includes(ServerKey.OPERATION_PUT_ENTRIES)) return -1
    if (names.find((name: string) => name !== ServerKey.OPERATION_GET_ENTRIES)) return 0
    return 1
  }

  private __nativeSend(request: any, callback: (_: any) => void) {

    const requestHash = CryptoJS.MD5(JSON.stringify(request)).toString()
    // console.log(`DATA: request_${requestHash} start`)
    
    const cacheMode = this.__getCacheMode(request)

    if (cacheMode === -1) {
      cache_clear()
    }

    if (cacheMode === 1) {

      const cachedValue = cache_get(requestHash)
      if (cachedValue) {
        // console.log(`DATA: request_${requestHash} return cached value`)
        callback(cachedValue)
        return
      }

      const callbacks = queue_get(requestHash)
      if (callbacks) {
        // console.log(`DATA: request_${requestHash} append to queue`)
        callbacks.push(callback)
        return
      }
      queue_set(requestHash, [callback])

      this.__server.send(request, (answer: any) => {
        // console.log(`DATA: request_${requestHash} completed`)
  
        if (answer && answer[ServerKey.SUCCESS]) {
          cache_set(requestHash, answer)
        }
  
        const callbacks = queue_get(requestHash)
        queue_del(requestHash)
        callbacks.forEach((block: any) => block(answer))
      })
    }
    else {
      this.__server.send(request, (answer: any) => {
        // console.log(`DATA: request_${requestHash} completed`)
        callback(answer)
      })
    }
  }

  private __performSend(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.__nativeSend(request, (answer: any) => {
        if (answer) {
          if (answer[ServerKey.SUCCESS]) {
            resolve(answer[ServerKey.RESULTS])
          } else {
            const msg = 'Server error: ' + answer[ServerKey.ERROR]
            this.__handleError(msg)
            reject(msg)
          }
        } else {
          this.__handleError('Server error: Unknown error!')
          reject('Unknown error!')
        }
      })
    })
  }
  
  async send(dataRequest: DataRequest): Promise<DataResults> {
    const request = dataRequest.toRawObject()
    const results = await this.__performSend(request)
    try {
      return new DataResults(toLocalResults(results, request))
    } catch (error: any) {
      this.__handleError('Internal error: ' + error.message)
      throw error
    }
  }

  async putEntries(model: string, entries: StoredEntry[]): Promise<number> {
    const request = new DataRequest()
    request.addPutEntries(model, entries)
    const results = await this.send(request)
    return results.getValue()
  }

  async putEntry(model: string, entry: StoredEntry): Promise<number> {
    return this.putEntries(model, [entry])
  }

  async delEntriesByIds(model: string, ids: string[]): Promise<number> {
    const request = new DataRequest()
    request.addDelEntriesByIds(model, ids)
    const results = await this.send(request)
    return results.getValue()
  }

  async delEntryById(model: string, id: string): Promise<number> {
    return this.delEntriesByIds(model, [id])
  }

  async getAllEntries(model: string): Promise<any[]> {
    const request = new DataRequest()
    request.addGetAllEntries(model)
    const results = await this.send(request)
    return results.getValue() || []
  }

  async getEntriesByIds(model: string, ids: string[]): Promise<any[]> {
    const request = new DataRequest()
    request.addGetEntriesByIds(model, ids)
    const results = await this.send(request)
    return results.getValue() || []
  }

  async getEntryById(model: string, id: string): Promise<any> {
    const list = await this.getEntriesByIds(model, [id])
    return list.find(() => true)
  }

  async getEntriesWhere(model: string, condition: any): Promise<any[]> {
    const request = new DataRequest()
    request.addGetEntriesWhere(model, condition)
    const results = await this.send(request)
    return results.getValue() || []
  }
}