
export default interface Property {
  key: string
  // eslint-disable-next-line no-unused-vars
  decode(source: any): any
  // eslint-disable-next-line no-unused-vars
  encode(source: any): any
}

export class StringProperty implements Property {

  readonly key: string

  constructor(key: string) {
    this.key = key
  }

  decode(source: any): string {
    if (!source) return ''
    if (typeof source != 'string') {
      console.error(`p28e, ${this.key}, ${typeof source}`)
      return String(source)
    }
    return source
  }

  encode(source: any): string {
    if (!source) return ''
    if (typeof source != 'string') {
      console.error(`x0xq, ${this.key}, ${typeof source}`)
      return String(source)
    }
    return source
  }
}

export class StringArrayProperty implements Property {

  readonly key: string

  constructor(key: string) {
    this.key = key
  }

  decode(source: any): string[] {
    if (!source) return []
    if (typeof source != 'string') {
      console.error(`pq21, ${this.key}, ${typeof source}`)
      return []
    }
    return source.split(',')
  }

  encode(source: any): string {
    if (!source) return ''
    if (typeof source != 'object') {
      console.error(`p0sn, ${this.key}, ${typeof source}`)
      return ''
    }
    return source.join(',')
  }
}

export class NumberProperty implements Property {

  readonly key: string

  constructor(key: string) {
    this.key = key
  }

  decode(source: any): number {
    if (!source) return 0
    if (typeof source != 'number') {
      console.error(`q8r0, ${this.key}, ${typeof source}`)
      return Number(source)
    }
    return isNaN(source) ? 0 : source
  }

  encode(source: any): number {
    if (!source) return 0
    if (typeof source != 'number') {
      console.error(`y8z7, ${this.key}, ${typeof source}`)
      return Number(source)
    }
    return isNaN(source) ? 0 : source
  }
}

export class DateProperty implements Property {

  readonly key: string

  constructor(key: string) {
    this.key = key
  }

  decode(source: any): Date | null {
    if (!source) return null
    if (typeof source != 'string') {
      console.error(`v3w2, ${this.key}, ${typeof source}`)
      return null
    }
    return new Date(source)
  }

  encode(source: any): string | null {
    if (!source) return null
    if (!(source instanceof Date)) {
      console.error(`djx2, ${this.key}, ${typeof source}`)
      return ''
    }
    return isNaN(source.getTime()) ? null : source.toJSON()
  }
}

export class BoolProperty implements Property {

  readonly key: string

  constructor(key: string) {
    this.key = key
  }

  decode(source: any): boolean {
    if (!source) return false
    if (typeof source != 'number') {
      console.error(`m43v, ${this.key}, ${typeof source}`)
      return Boolean(source)
    }
    return Boolean(source)
  }

  encode(source: any): number {
    if (!source) return 0
    if (typeof source != 'boolean') {
      console.error(`cn5r, ${this.key}, ${typeof source}`)
      return Number(source)
    }
    return Number(source)
  }
}

export class IDProperty implements Property {

  readonly key: string
  readonly model: string

  constructor(key: string, model: string) {
    this.key = key
    this.model = model
  }

  decode(source: any): string | null {
    if (!source) return null
    if (typeof source != 'string') {
      console.error(`myk9, ${this.key}, ${typeof source}`)
      return String(source)
    }
    return source
  }

  encode(source: any): string | null {
    if (!source) return null
    if (typeof source != 'string') {
      console.error(`u4wp, ${this.key}, ${typeof source}`)
      return String(source)
    }
    return source
  }
}
