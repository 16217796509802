
export default class DateUtils {

  static correctDate(value: any): Date | undefined {
    if (value instanceof Date) {
      return isNaN(value.getTime()) ? undefined : value
    } if (typeof value === 'string') {
      const dat = new Date(value)
      return isNaN(dat.getTime()) ? undefined : dat
    } else {
      return undefined
    }
  }

  static isEmpty(value: any): boolean {
    return this.correctDate(value) === undefined
  }

  static getTime(value: any): number {
    return this.correctDate(value)?.getTime() || 0
  }

  static formatedDate(value: any, defValue: string = ''): string {
    return this.correctDate(value)?.toLocaleDateString('ru-RU', { year: 'numeric', month: '2-digit', day: '2-digit' }) || defValue
  }

  static formatedDateTime(value: any, defValue: string = ''): string {
    return this.correctDate(value)?.toLocaleDateString('ru-RU', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) || defValue
  }

  static startOfDay(date: Date): Date {
    const d = new Date(date.getTime())
    d.setUTCHours(0, 0, 0, 0)
    return d
  }

  static endOfDay(date: Date): Date {
    const d = new Date(date.getTime())
    d.setUTCHours(23, 59, 59, 999)
    return d
  }
}