import DocumentEntry from "./DocumentEntry"
import EntryHelper from "../EntryHelper"
import Property, { DateProperty, IDProperty, StringProperty } from "../Property"
import Place from "../unit/Place"
import Employee from "../unit/Employee"

export default class PurchaseOrder extends DocumentEntry {
  static MODEL = 'zkc7'

  static VAL_PLACE_ID = 'x5vx'
  static VAL_DECLARER_ID = 'ct2t'
  static VAL_EXECUTOR_ID = 'd0ry'
  static VAL_EXECUTOR_DATE = 't9ol'
  static VAL_STATUS = 'a7ko'
  static VAL_STATUS_AUTHOR_ID = 't3ia'
  static VAL_COMMENT = 'lc9e'
  static VAL_AUTHOR_ID = 'ui0e'

  static PROPS: Property[] = DocumentEntry.PROPS.concat([
    new IDProperty(this.VAL_PLACE_ID, Place.MODEL), new IDProperty(this.VAL_DECLARER_ID, Employee.MODEL),
    new IDProperty(this.VAL_EXECUTOR_ID, Employee.MODEL), new DateProperty(this.VAL_EXECUTOR_DATE),
    new StringProperty(this.VAL_STATUS), new IDProperty(this.VAL_STATUS_AUTHOR_ID, Employee.MODEL),
    new StringProperty(this.VAL_COMMENT), new IDProperty(this.VAL_AUTHOR_ID, Employee.MODEL)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get name(): string { return `Заявка №${this.number} от ${EntryHelper.toDateString(this.date)}` }
  
  get placeId(): string { return this.getValue(PurchaseOrder.VAL_PLACE_ID) }
  get declarerId(): string { return this.getValue(PurchaseOrder.VAL_DECLARER_ID) }
  get executorId(): string { return this.getValue(PurchaseOrder.VAL_EXECUTOR_ID) }
  get executorDate(): Date { return this.getValue(PurchaseOrder.VAL_EXECUTOR_DATE) }
  get status(): string { return this.getValue(PurchaseOrder.VAL_STATUS) }
  get statusAuthorId(): string { return this.getValue(PurchaseOrder.VAL_STATUS_AUTHOR_ID) }
  get comment(): string { return this.getValue(PurchaseOrder.VAL_COMMENT) }
  get authorId(): string { return this.getValue(PurchaseOrder.VAL_AUTHOR_ID) }

  static new(value: any): PurchaseOrder {
    return EntryHelper.fillEntry(new PurchaseOrder(), value)
  }
}