import { createRouter, createWebHistory } from "vue-router";
import AppContext from "@/core/AppContext";
import { Counterparty, Employee, Equipment, Measure, Place, Storage } from "./core/model/Types";
import { PurchaseOrder, ClosingOrder, DeliveryInventory, TransferInventory, WriteOffInventory } from "./core/model/Types";
import { RouteFamily, ReportName } from "./use/RouteKeys";

import HomePage from '@/use/scene/HomePage.vue'
import LoginPage from '@/use/scene/LoginPage.vue'

import UniversalHistoryPage from '@/use/scene/doc/UniversalHistoryPage.vue'
import UniversalRecordsPage from '@/use/scene/doc/UniversalRecordsPage.vue'

import CounterpartiesPage from '@/use/scene/unit/CounterpartiesPage.vue'
import CounterpartyPage from '@/use/scene/unit/CounterpartyPage.vue'
import EmployeesPage from '@/use/scene/unit/EmployeesPage.vue'
import EmployeePage from '@/use/scene/unit/EmployeePage.vue'
import EquipmentsPage from '@/use/scene/unit/EquipmentsPage.vue'
import EquipmentPage from '@/use/scene/unit/EquipmentPage.vue'
import MeasuresPage from '@/use/scene/unit/MeasuresPage.vue'
import MeasurePage from '@/use/scene/unit/MeasurePage.vue'
import PlacesPage from '@/use/scene/unit/PlacesPage.vue'
import PlacePage from '@/use/scene/unit/PlacePage.vue'
import StoragesPage from '@/use/scene/unit/StoragesPage.vue'
import StoragePage from '@/use/scene/unit/StoragePage.vue'

import PurchaseOrdersPage from '@/use/scene/doc/PurchaseOrdersPage.vue'
import PurchaseOrderPage from '@/use/scene/doc/PurchaseOrderPage.vue'
import ClosingOrdersPage from '@/use/scene/doc/ClosingOrdersPage.vue'
import ClosingOrderPage from '@/use/scene/doc/ClosingOrderPage.vue'
import DeliveryInventoriesPage from '@/use/scene/doc/DeliveryInventoriesPage.vue'
import DeliveryInventoryPage from '@/use/scene/doc/DeliveryInventoryPage.vue'
import TransferInventoriesPage from '@/use/scene/doc/TransferInventoriesPage.vue'
import TransferInventoryPage from '@/use/scene/doc/TransferInventoryPage.vue'
import WriteOffInventoriesPage from '@/use/scene/doc/WriteOffInventoriesPage.vue'
import WriteOffInventoryPage from '@/use/scene/doc/WriteOffInventoryPage.vue'

import OrderQueuePage from '@/use/scene/report/OrderQueuePage.vue'
import StoragePartionsPage from '@/use/scene/report/StoragePartionsPage.vue'
import StorageInventoriesPage from '@/use/scene/report/StorageInventoriesPage.vue'
import EquipmentCostPage from '@/use/scene/report/EquipmentCostPage.vue'

import SettingsPage from '@/use/scene/other/SettingsPage.vue'
import OperationsPage from '@/use/scene/other/OperationsPage.vue'
import DeveloperPage from '@/use/scene/other/DeveloperPage.vue'

import NotFoundPage from '@/use/scene/other/NotFoundPage.vue'

const routes = [

  { path: '/', component: HomePage, meta: { family: RouteFamily.HOME } },
  { path: '/login', component: LoginPage, meta: { family: RouteFamily.LOGIN } },
  
  { path: '/counterparty', component: CounterpartiesPage, meta: { family: RouteFamily.LIST_OF_OBJECTS, name: Counterparty.MODEL } },
  { path: '/counterparty/:id', component: CounterpartyPage, meta: { family: RouteFamily.OBJECT_EDITOR, name: Counterparty.MODEL } },
  { path: '/employee', component: EmployeesPage, meta: { family: RouteFamily.LIST_OF_OBJECTS, name: Employee.MODEL } },
  { path: '/employee/:id', component: EmployeePage, meta: { family: RouteFamily.OBJECT_EDITOR, name: Employee.MODEL } },
  { path: '/equipment', component: EquipmentsPage, meta: { family: RouteFamily.LIST_OF_OBJECTS, name: Equipment.MODEL } },
  { path: '/equipment/:id', component: EquipmentPage, meta: { family: RouteFamily.OBJECT_EDITOR, name: Equipment.MODEL } },
  { path: '/measure', component: MeasuresPage, meta: { family: RouteFamily.LIST_OF_OBJECTS, name: Measure.MODEL } },
  { path: '/measure/:id', component: MeasurePage, meta: { family: RouteFamily.OBJECT_EDITOR, name: Measure.MODEL } },
  { path: '/place', component: PlacesPage, meta: { family: RouteFamily.LIST_OF_OBJECTS, name: Place.MODEL } },
  { path: '/place/:id', component: PlacePage, meta: { family: RouteFamily.OBJECT_EDITOR, name: Place.MODEL } },
  { path: '/storage', component: StoragesPage, meta: { family: RouteFamily.LIST_OF_OBJECTS, name: Storage.MODEL } },
  { path: '/storage/:id', component: StoragePage, meta: { family: RouteFamily.OBJECT_EDITOR, name: Storage.MODEL } },
  
  { path: '/purchase-order', component: PurchaseOrdersPage, meta: { family: RouteFamily.LIST_OF_OBJECTS, name: PurchaseOrder.MODEL } },
  { path: '/purchase-order/:id', component: PurchaseOrderPage, meta: { family: RouteFamily.OBJECT_EDITOR, name: PurchaseOrder.MODEL } },
  { path: '/purchase-order/:id/history', component: UniversalHistoryPage, meta: { family: RouteFamily.DOC_HISTORY, name: PurchaseOrder.MODEL } },
  { path: '/purchase-order/:id/records', component: UniversalRecordsPage, meta: { family: RouteFamily.DOC_RECORDS, name: PurchaseOrder.MODEL } },
  { path: '/closing-order', component: ClosingOrdersPage, meta: { family: RouteFamily.LIST_OF_OBJECTS, name: ClosingOrder.MODEL } },
  { path: '/closing-order/:id', component: ClosingOrderPage, meta: { family: RouteFamily.OBJECT_EDITOR, name: ClosingOrder.MODEL } },
  { path: '/closing-order/:id/history', component: UniversalHistoryPage, meta: { family: RouteFamily.DOC_HISTORY, name: ClosingOrder.MODEL } },
  { path: '/closing-order/:id/records', component: UniversalRecordsPage, meta: { family: RouteFamily.DOC_RECORDS, name: ClosingOrder.MODEL } },
  { path: '/delivery-inventory', component: DeliveryInventoriesPage, meta: { family: RouteFamily.LIST_OF_OBJECTS, name: DeliveryInventory.MODEL } },
  { path: '/delivery-inventory/:id', component: DeliveryInventoryPage, meta: { family: RouteFamily.OBJECT_EDITOR, name: DeliveryInventory.MODEL } },
  { path: '/delivery-inventory/:id/history', component: UniversalHistoryPage, meta: { family: RouteFamily.DOC_HISTORY, name: DeliveryInventory.MODEL } },
  { path: '/delivery-inventory/:id/records', component: UniversalRecordsPage, meta: { family: RouteFamily.DOC_RECORDS, name: DeliveryInventory.MODEL } },
  { path: '/transfer-inventory', component: TransferInventoriesPage, meta: { family: RouteFamily.LIST_OF_OBJECTS, name: TransferInventory.MODEL } },
  { path: '/transfer-inventory/:id', component: TransferInventoryPage, meta: { family: RouteFamily.OBJECT_EDITOR, name: TransferInventory.MODEL } },
  { path: '/transfer-inventory/:id/history', component: UniversalHistoryPage, meta: { family: RouteFamily.DOC_HISTORY, name: TransferInventory.MODEL } },
  { path: '/transfer-inventory/:id/records', component: UniversalRecordsPage, meta: { family: RouteFamily.DOC_RECORDS, name: TransferInventory.MODEL } },
  { path: '/writeoff-inventory', component: WriteOffInventoriesPage, meta: { family: RouteFamily.LIST_OF_OBJECTS, name: WriteOffInventory.MODEL } },
  { path: '/writeoff-inventory/:id', component: WriteOffInventoryPage, meta: { family: RouteFamily.OBJECT_EDITOR, name: WriteOffInventory.MODEL } },
  { path: '/writeoff-inventory/:id/history', component: UniversalHistoryPage, meta: { family: RouteFamily.DOC_HISTORY, name: WriteOffInventory.MODEL } },
  { path: '/writeoff-inventory/:id/records', component: UniversalRecordsPage, meta: { family: RouteFamily.DOC_RECORDS, name: WriteOffInventory.MODEL } },

  { path: '/order-queue', component: OrderQueuePage, meta: { family: RouteFamily.REPORT, name: ReportName.ORDER_QUEUE } },
  { path: '/storage-partions', component: StoragePartionsPage, meta: { family: RouteFamily.REPORT, name: ReportName.STORAGE_PARTIONS } },
  { path: '/storage-inventories', component: StorageInventoriesPage, meta: { family: RouteFamily.REPORT, name: ReportName.STORAGE_INVENTORIES } },
  { path: '/equipment-cost', component: EquipmentCostPage, meta: { family: RouteFamily.REPORT, name: ReportName.EQUIPMENT_COST } },

  { path: '/settings', component: SettingsPage, meta: { family: RouteFamily.SETTINGS } },
  { path: '/operations', component: OperationsPage, meta: { family: RouteFamily.OPERATIONS } },
  { path: '/y25e', component: DeveloperPage },

  { path: '/404', component: NotFoundPage, meta: { family: RouteFamily.NOT_FOUND } },
  { path: '/:pathMatch(.*)*', component: NotFoundPage, meta: { family: RouteFamily.NOT_FOUND } },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const am = AppContext.getSafeManager()
  if (am.isLoggedIn()) {
    if (to.path === '/login') {
      next('/')
    } else {
      next()
    }
  } else {
    if (to.path === '/login') {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
