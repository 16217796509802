import DocumentRow from "./DocumentRow"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, NumberProperty } from "../Property"
import Party from "../unit/Party"
import Equipment from "../unit/Equipment"

export default class WriteOffInventoryRow extends DocumentRow {
  static MODEL = 'd7bn'

  static VAL_PARTY_ID = 'sn5b'
  static VAL_EQUIPMENT_ID = 'jo1z'
  static VAL_AMOUNT = 'ax7q'
  // static VAL_INVENTORY_ID = 'ns7p' // Удалить
  // static VAL_ORDER_ID = 'w5sa' // Удалить
  // static VAL_MEASURE_ID = 'e7vt' // Удалить
  // static VAL_SUM = 'd7fj' // Удалить

  static PROPS: Property[] = DocumentRow.PROPS.concat([
    new IDProperty(this.VAL_PARTY_ID, Party.MODEL), new IDProperty(this.VAL_EQUIPMENT_ID, Equipment.MODEL),
    new NumberProperty(this.VAL_AMOUNT),
    // new IDProperty(this.VAL_INVENTORY_ID, Inventory.MODEL), new IDProperty(this.VAL_ORDER_ID, PurchaseOrder.MODEL),
    // new IDProperty(this.VAL_MEASURE_ID, Measure.MODEL), new NumberProperty(this.VAL_SUM)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get partyId(): string { return this.getValue(WriteOffInventoryRow.VAL_PARTY_ID) }
  get equipmentId(): string { return this.getValue(WriteOffInventoryRow.VAL_EQUIPMENT_ID) }
  get amount(): number { return this.getValue(WriteOffInventoryRow.VAL_AMOUNT) }
  // get inventoryId(): string { return this.getValue(WriteOffInventoryRow.VAL_INVENTORY_ID) }
  // get orderId(): string { return this.getValue(WriteOffInventoryRow.VAL_ORDER_ID) }
  // get measureId(): string { return this.getValue(WriteOffInventoryRow.VAL_MEASURE_ID) }
  // get sum(): number { return this.getValue(WriteOffInventoryRow.VAL_SUM) }

  static new(value: any): WriteOffInventoryRow {
    return EntryHelper.fillEntry(new WriteOffInventoryRow(), value)
  }
}