
import { defineComponent } from 'vue';
import DefaultPage from '@/use/components/DefaultPage.vue';
import { UIPanel, UITextField, UIButton } from '@/use/base/Types';
import AppContext from '@/core/AppContext'
import { PurchaseOrder, ClosingOrder, DeliveryInventory, TransferInventory, WriteOffInventory } from '@/core/model/Types'
import * as Permission from '@/core/safe/Permissions'
import * as SettingsKey from '@/core/settings/SettingsKeys'

export default defineComponent({
  components: {
    DefaultPage, 
    UIPanel, UIButton, UITextField
  },
  data() {
    return {
      granted: false,
      displayLastNumbers: {} as any,
      displayCompanyFullName: '',
      displayDirectorShortName: '',
      displayChiefEngineerShortName: '',
    }
  },
  methods: {
    configureView() {
      const settings = AppContext.getSettingsManager().getSettings()
      const lastNumbers = settings[SettingsKey.LAST_NUMBERS] || {}
      this.displayLastNumbers['order'] = '' + (lastNumbers[PurchaseOrder.MODEL] || 0)
      this.displayLastNumbers['closing'] = '' + (lastNumbers[ClosingOrder.MODEL] || 0)
      this.displayLastNumbers['delivery'] = '' + (lastNumbers[DeliveryInventory.MODEL] || 0)
      this.displayLastNumbers['transfer'] = '' + (lastNumbers[TransferInventory.MODEL] || 0)
      this.displayLastNumbers['writeoff'] = '' + (lastNumbers[WriteOffInventory.MODEL] || 0)
      this.displayCompanyFullName = settings[SettingsKey.COMPANY_FULL_NAME]
      this.displayDirectorShortName = settings[SettingsKey.DIRECTOR_SHORT_NAME]
      this.displayChiefEngineerShortName = settings[SettingsKey.CHIEF_ENGINEER_SHORT_NAME]
    },
    handleSaveClick() {
      AppContext.getSettingsManager().setSettings({
        [SettingsKey.LAST_NUMBERS]: {
          [PurchaseOrder.MODEL]: Number(this.displayLastNumbers['order']),
          [ClosingOrder.MODEL]: Number(this.displayLastNumbers['closing']),
          [DeliveryInventory.MODEL]: Number(this.displayLastNumbers['delivery']),
          [TransferInventory.MODEL]: Number(this.displayLastNumbers['transfer']),
          [WriteOffInventory.MODEL]: Number(this.displayLastNumbers['writeoff']),
        },
        [SettingsKey.COMPANY_FULL_NAME]: this.displayCompanyFullName,
        [SettingsKey.DIRECTOR_SHORT_NAME]: this.displayDirectorShortName,
        [SettingsKey.CHIEF_ENGINEER_SHORT_NAME]: this.displayChiefEngineerShortName
      })
      this.$sm.goBack()
    },
  },
  created() {
    AppContext.getSafeManager().getCurrentPermissions(perms => {
      this.granted = perms.includes(Permission.SETTINGS)
    })
    this.configureView()
  }
})
