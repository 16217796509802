import RegistryRecord from "./RegistryRecord"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, StringProperty } from "../Property"
import Employee from "../unit/Employee"

export default class HistoryRecord extends RegistryRecord {
  static MODEL = 'e2us'

  static VAL_EMPLOYEE_ID = 'vh9s'
  static VAL_OBJECT_MODEL = 'p4qq'
  static VAL_OBJECT_ID = 's7pv'
  static VAL_DETAILS = 'i2cr'

  static PROPS: Property[] = RegistryRecord.PROPS.concat([
    new IDProperty(this.VAL_EMPLOYEE_ID, Employee.MODEL), new StringProperty(this.VAL_OBJECT_MODEL),
    new StringProperty(this.VAL_OBJECT_ID), new StringProperty(this.VAL_DETAILS)
  ])

  // ---

  constructor(data?: any) {
    super(data)
  }

  get employeeId(): string { return this.getValue(HistoryRecord.VAL_EMPLOYEE_ID) }
  get objectModel(): string { return this.getValue(HistoryRecord.VAL_OBJECT_MODEL) }
  get objectId(): string { return this.getValue(HistoryRecord.VAL_OBJECT_ID) }
  get details(): string { return this.getValue(HistoryRecord.VAL_DETAILS) }

  static new(value: any): HistoryRecord {
    return EntryHelper.fillEntry(new HistoryRecord(), value)
  }
}