
import { defineComponent } from 'vue';
import DefaultPage from '@/use/components/DefaultPage.vue';
import { UIPanel, UIToolbar, UIButton, UIDateField, UITextField, UITextArea, UIAlertView } from '@/use/base/Types';
import { UITable, UITableRow, UITableCell, UITextCell, UIEntryCell, UIImageButton } from '@/use/base/Types';
import ToDeleteButton from '@/use/components/ToDeleteButton.vue';
import AppContext from '@/core/AppContext';
import * as Permission from '@/core/safe/Permissions'
import { ClosingOrder, ClosingOrderRow, OrderRecord, PurchaseOrderRow } from '@/core/model/Types';
import { PurchaseOrder } from '@/core/model/Types';
import DataRequest from '@/core/data/DataRequest';
import ArrayMaster from '@/lib/util/ArrayMaster';
import SessionCache from '@/lib/util/SessionCache';
import { PDFBuilder, PDFTable } from '@/lib/pdf/PDFTypes';
import ClosingOrderHelper from './ClosingOrderHelper';
import EntryLoader from '@/lib/data/EntryLoader';
import EntryMap from '@/lib/data/EntryMap';
import { DateUtils, EntryUtils, NumberUtils, StringUtils, TableUtils } from '@/lib/util/Utils';

export default defineComponent({
  components: {
    DefaultPage, 
    UIPanel, UIToolbar, UIButton, UIDateField, UITextField, UITextArea, UIAlertView,
    UITable, UITableRow, UITableCell, UITextCell, UIEntryCell, UIImageButton,
    ToDeleteButton
  },
  data() {
    return {
      grantedEdit: false,
      loader: new EntryLoader(),
      docId: '',
      date: new Date(),
      number: '',
      comment: '',
      tableWidths: TableUtils.headers(m => [m.INDEX, m.flex(1), m.flex(1.5), m.AMOUNT, m.flex(1), m.ACTION]),
      tableHeaders: ['№', 'Заявка', 'Позиция', 'Кол-во', 'Коментарий', 'X'],
      tableRows: new Array<any>(),
      toDelete: false,
      isLoading: false,
      alert: undefined as any,
    }
  },
  watch: {
    tableRows: {
      deep: true,
      handler(newValue) {
        if (!TableUtils.isContainsEmptyRow(newValue)) {
          this.tableRows.push({ _key: StringUtils.getUUID() })
        }
      },
    }
  },
  methods: {
    table_handleRemoveRow(rowIndex: number) {
      this.tableRows.splice(rowIndex, 1)
    },
    configureView(doc: ClosingOrder | undefined, rows: ClosingOrderRow[]) {
      this.date = (doc) ? doc.date : new Date()
      this.number = (doc) ? doc.number : AppContext.getSettingsManager().getNextNumber(ClosingOrder.MODEL)
      this.comment = (doc) ? doc.comment : ''
      this.tableRows = rows.sort((a, b) => a.index - b.index).map(row => {
        return {
          _key: StringUtils.getUUID(),
          id: row.id,
          order: this.loader.data.getDisplayItem(PurchaseOrder.MODEL, row.orderId),
          orderRow: this.loader.data.getDisplayItem(PurchaseOrderRow.MODEL, row.orderRowId),
          amount: `${row.amount || 0}`,
          comment: row.comment,
        }
      })
      this.toDelete = (doc) ? Boolean(doc.toDelete) : false
    },
    reloadView() {

      const subOptions = [
        { key: ClosingOrderRow.VAL_ORDER_ID, model: PurchaseOrder.MODEL },
        { key: ClosingOrderRow.VAL_ORDER_ROW_ID, model: PurchaseOrderRow.MODEL },
      ]
          
      if (this.docId === 'new') {

        var rows = SessionCache.extract('i2ze') as any[] // Для автозаполнения
        if (rows) {
          this.isLoading = true
          return this.loader.loadDetails(rows, subOptions)
            .then(() => { this.configureView(undefined, rows) })
            .finally(() => this.isLoading = false)
        } 
        else {
          this.configureView(undefined, [])
        }
      }
      else {

        this.isLoading = true

        const request = new DataRequest()
        request.addGetEntriesByIds(ClosingOrder.MODEL, [this.docId], 'doc')
        request.addGetEntriesWhere(ClosingOrderRow.MODEL, [
          [ ClosingOrderRow.VAL_DOC_ID, '==', this.docId ]
        ], 'rows')

        this.$dm.send(request)
          .then(results => {

            const doc = results.getValue('doc').find(() => true)
            if (!doc) throw new Error(`Документ (${this.docId}) не существует!`)

            const rows = (results.getValue('rows') || []) as ClosingOrderRow[]

            return this.loader.loadDetails(rows, subOptions)
              .then(() => { this.configureView(doc, rows) })
          })
          .catch(msg => {
            console.error(msg)
            this.$sm.goBack()
          })
          .finally(() => this.isLoading = false)
      }
    },
    getVariantsGetter(key: string, rowIndex: number, callback: any) {
      return this.getVariantsPromise(key, rowIndex)
        .then(value => callback(value))
        .catch(() => callback(undefined))
    },
    getPromise(value: any): Promise<any> {
      return new Promise((resolve) => { resolve(value) })
    },
    getVariantsPromise(key: string, rowIndex: number): Promise<any> {

      if (key === 'order') {

        return this.$dm.getEntriesWhere(
          OrderRecord.MODEL,
          [
            [ OrderRecord.VAL_DATE, '<', this.date ]
          ]
        )
          .then(records => {
            const orderIds = new ArrayMaster(records)
              .map(it => { return { orderId: it.orderId, amount: it.amount }})
              .group(['orderId'], ['amount'])
              .filter(gr => gr.amount > 0)
              .map(gr => gr.orderId).get()
            
            return this.loader.loadEntriesByIds<PurchaseOrder>(PurchaseOrder.MODEL, orderIds)
              .then(entries => {
                return new ArrayMaster(entries)
                  .map(it => ({ id: it.id, name: it.name }))
                  .sortEntries().get()
              })
          })
      }
      else if (key === 'orderRow') {

        if (rowIndex >= this.tableRows.length) return this.getPromise(undefined)
        const row = this.tableRows[rowIndex]

        if (EntryUtils.isEmpty(row.order)) return this.getPromise(undefined)

        return this.$dm.getEntriesWhere(
          OrderRecord.MODEL,
          [
            [ OrderRecord.VAL_DATE, '<' , this.date ],
            [ OrderRecord.VAL_ORDER_ID, '==', EntryUtils.getId(row.order) ]
          ]
        )
          .then(records => {
            const orderRowIds = new ArrayMaster(records)
              .map(it => { return { orderRowId: it.orderRowId, amount: it.amount }})
              .group(['orderRowId'], ['amount'])
              .filter(gr => gr.amount > 0)
              .map(gr => gr.orderRowId).get()
            
            return this.loader.loadEntriesByIds(PurchaseOrderRow.MODEL, orderRowIds)
              .then(entries => {
                return new ArrayMaster(entries)
                  .map(it => ({ id: it.id, name: it.name }))
                  .sortEntries().get()
              })
          })
      } 
      else if (key === 'amount') {

        if (rowIndex >= this.tableRows.length) return this.getPromise(undefined)
        const row = this.tableRows[rowIndex]

        if (EntryUtils.isEmpty(row.orderRow)) return this.getPromise(undefined)

        return this.$dm.getEntriesWhere(
          OrderRecord.MODEL,
          [
            [ OrderRecord.VAL_DATE, '<' , this.date ],
            [ OrderRecord.VAL_ORDER_ROW_ID, '==', EntryUtils.getId(row.orderRow) ]
          ]
        )
          .then(records => {
            const amount = new ArrayMaster(records)
              .map(it => it.amount).getSum()
            return ['' + amount]
          })
      }
      else {
        return this.getPromise(undefined)
      }
    },
    prepareSaveData(): any {

      let docId = (this.docId === 'new') ? this.$dm.newEntryId() : this.docId
      
      const doc = ClosingOrder.new({
        [ClosingOrder.VAL_ID]: docId,
        [ClosingOrder.VAL_DATE]: this.date,
        [ClosingOrder.VAL_NUMBER]: this.number,
        [ClosingOrder.VAL_COMMENT]: this.comment,
        [ClosingOrder.VAL_TODELETE]: this.toDelete
      })
      
      const rows = TableUtils.getFilledRows(this.tableRows).map(row => {
        return ClosingOrderRow.new({
          [ClosingOrderRow.VAL_ID]: row.id,
          [ClosingOrderRow.VAL_ORDER_ID]: EntryUtils.getId(row.order),
          [ClosingOrderRow.VAL_ORDER_ROW_ID]: EntryUtils.getId(row.orderRow),
          [ClosingOrderRow.VAL_AMOUNT]: Number(row.amount),
          [ClosingOrderRow.VAL_COMMENT]: row.comment,
        })
      })

      return { doc: doc, rows: rows }
    },
    handleOkClick() {
      const d = this.prepareSaveData()
      ClosingOrderHelper.save(d.doc, d.rows, this.loader.data as EntryMap)
        .then(() => {
          if (this.docId === 'new') {
            AppContext.getSettingsManager().registryLastNumber(ClosingOrder.MODEL, this.number)
          }
          this.$sm.goBack()
        })
        .catch(msg => this.alert = { title: 'Ошибка', text: msg })
    },
    handleSaveClick() {
      const d = this.prepareSaveData()
      ClosingOrderHelper.save(d.doc, d.rows, this.loader.data as EntryMap)
        .then(() => {
          if (this.docId === 'new') {
            AppContext.getSettingsManager().registryLastNumber(ClosingOrder.MODEL, this.number)
            // Изменим url
            this.docId = d.doc.id
            this.$sm.goToObjectEditor(ClosingOrder.MODEL, d.doc.id)
          }
          this.reloadView()
          this.alert = { text: 'Сохранено!' }
        })
        .catch(msg => this.alert = { title: 'Ошибка', text: msg })
    },
    handlePrintClick() {

      const printTableHeaders = TableUtils.headers(m => [
        { name: '№', width: m.fixed(40), alignment: 'right' },
        { name: 'Заявка', width: m.flex(1) },
        { name: 'Позиция', width: m.flex(1.5) },
        { name: 'Кол-во', width: m.AMOUNT, alignment: 'right' },
        { name: 'Коментарий', width: m.flex(1) },
      ])

      const table = new PDFTable()
      table.setColumnWidths(printTableHeaders.map(it => it.width))
      table.setColumnAligment(printTableHeaders.map(it => it.alignment || 'left'))
      table.setHeaders(printTableHeaders.map(it => it.name))
      
      TableUtils.getFilledRows(this.tableRows).forEach((row, i) => {
        table.addRow([
          `${i + 1}`,
          EntryUtils.getName(row.order),
          EntryUtils.getName(row.orderRow),
          NumberUtils.formatedNumber(row.amount),
          row.comment,
        ])
      })

      const builder = new PDFBuilder('landscape')
      builder.addTitle(
        [ 'ЗАКРЫТИЕ ЗАЯВОК', `№ ${this.number} от ${DateUtils.formatedDate(this.date)}` ],
        { margin: [ 0, 0, 0, 20 ] }
      )
      builder.addTable(table)
      builder.build()
    },
    handleRecordsClick() {
      this.$sm.goToDocRecords(ClosingOrder.MODEL, this.docId)
    },
    handleHistoryClick() {
      this.$sm.goToDocHistory(ClosingOrder.MODEL, this.docId)
    },
  },
  created() {
    
    AppContext.getSafeManager().getCurrentPermissions(perms => {
      this.grantedEdit = perms.includes(Permission.CLOSING_ORDER_EDIT)
    })

    this.docId = this.$sm.getCurrentParam('id')
    this.reloadView()
  },
})
