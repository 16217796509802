
import { PropType, defineComponent } from 'vue';
import UIWidth from './h/UIWidth';

export default defineComponent({
  props: {
    headers: {
      type: Array as PropType<any[]>,
      required: true
    },
    rows: {
      type: Array as PropType<any[]>,
      required: true
    },
    filter: String
  },
  data() {
    return {
      tableWidths: new Array<string>(),
    }
  },
  watch: {
    headers() {
      this.layoutTable()
    },
  },
  computed: {
    displayRows() {
      if (this.filter) {
        let correctText = this.filter.toLowerCase();
        let arr = [];
        for (let i = 0; i < this.rows.length; i++) {
          const item = this.rows[i];
          for (let h = 0; h < this.headers.length; h++) {
            const value = item[this.headers[h].key]
            if (value && value.toLowerCase().includes(correctText)) {
              arr.push(item);
              break;
            }
          }
        }
        return arr;
      } else {
        return this.rows
      }
    },
  },
  methods: {
    layoutTable() {
      const table = this.$refs.table as HTMLElement
      const widths = this.headers.map(it => it.width || UIWidth.flex(1))
      this.tableWidths = UIWidth.getTableWidths(widths, table.offsetWidth)
    },
    handleDoubleClick(row: any, header: string) {
      this.$emit('onDoubleClick', row, header);
    },
  },
  mounted() {
    this.layoutTable()
  },
})
