import DocumentRow from "./DocumentRow"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, NumberProperty, StringProperty } from "../Property"
import PurchaseOrder from "./PurchaseOrder"

export default class DeliveryInventoryRow extends DocumentRow {
  static MODEL = 't0pk'

  static VAL_INVENTORY = 'g2ji'
  static VAL_MEASURE = 's1mt'
  static VAL_AMOUNT = 'oc7y'
  static VAL_SUM = 'ab7g'
  static VAL_ORDER_ID = 'x8ap'
  // static VAL_INVENTORY_ID = 'bm2j' // Удалить
  // static VAL_MEASURE_ID = 'xf5s' // Удалить

  static PROPS: Property[] = DocumentRow.PROPS.concat([
    new StringProperty(this.VAL_INVENTORY), new StringProperty(this.VAL_MEASURE),
    new NumberProperty(this.VAL_AMOUNT), new NumberProperty(this.VAL_SUM),
    new IDProperty(this.VAL_ORDER_ID, PurchaseOrder.MODEL),
    // new IDProperty(this.VAL_INVENTORY_ID, Inventory.MODEL), new IDProperty(this.VAL_MEASURE_ID, Measure.MODEL)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get inventory(): string { return this.getValue(DeliveryInventoryRow.VAL_INVENTORY) }
  get measure(): string { return this.getValue(DeliveryInventoryRow.VAL_MEASURE) }
  get amount(): number { return this.getValue(DeliveryInventoryRow.VAL_AMOUNT) }
  get sum(): number { return this.getValue(DeliveryInventoryRow.VAL_SUM) }
  get orderId(): string { return this.getValue(DeliveryInventoryRow.VAL_ORDER_ID) }
  // get inventoryId(): string { return this.getValue(DeliveryInventoryRow.VAL_INVENTORY_ID) }
  // get measureId(): string { return this.getValue(DeliveryInventoryRow.VAL_MEASURE_ID) }

  static new(value: any): DeliveryInventoryRow {
    return EntryHelper.fillEntry(new DeliveryInventoryRow(), value)
  }
}