import DocumentEntry from "./DocumentEntry"
import EntryHelper from "../EntryHelper"
import Property, { IDProperty, StringProperty } from "../Property"
import Employee from "../unit/Employee"

export default class ClosingOrder extends DocumentEntry {
  static MODEL = 's3qj'

  static VAL_COMMENT = 'yv5c'
  static VAL_AUTHOR_ID = 'x0ar'

  static PROPS: Property[] = DocumentEntry.PROPS.concat([
    new StringProperty(this.VAL_COMMENT), new IDProperty(this.VAL_AUTHOR_ID, Employee.MODEL)
  ])

  // ---

  constructor(data?: any) {
    super(data)
  }

  get name(): string { return `Закрытие заявки №${this.number} от ${EntryHelper.toDateString(this.date)}` }

  get comment(): string { return this.getValue(ClosingOrder.VAL_COMMENT) }
  get authorId(): string { return this.getValue(ClosingOrder.VAL_AUTHOR_ID) }

  static new(value: any): ClosingOrder {
    return EntryHelper.fillEntry(new ClosingOrder(), value)
  }
}