
import { PropType, defineComponent } from 'vue';
import UIWidth from './h/UIWidth';

export default defineComponent({
  props: {
    headers: {
      type: Array as PropType<string[]>,
      required: true
    },
    widths: {
      type: Array as PropType<UIWidth[]>,
      required: true
    },
  },
  data() {
    return {
      tableWidths: new Array<string>(),
    }
  },
  methods: {
    layoutTable() {
      const table = this.$refs.table as HTMLElement
      this.tableWidths = UIWidth.getTableWidths(this.widths, table.offsetWidth)
    },
  },
  mounted() {
    this.layoutTable()
  },
})
