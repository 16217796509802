
let sCurrentPopupControl: any = undefined;

class UIPopupController {

  showPopup(popupControl: any) {
    if (popupControl !== sCurrentPopupControl) {
      if (sCurrentPopupControl) {
        sCurrentPopupControl.show = false
      }
      sCurrentPopupControl = popupControl
    }
    sCurrentPopupControl.show = true
  }

  hidePopup(popupControl: any) {
    if (popupControl === sCurrentPopupControl) {
      sCurrentPopupControl = undefined
    }
    popupControl.show = false
  }

  hideCurrentPopup() {
    if (sCurrentPopupControl) {
      sCurrentPopupControl.show = false
      sCurrentPopupControl = undefined
    }
  }
}

export default new UIPopupController()