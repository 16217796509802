
import { defineComponent } from 'vue';
import AppContext from './core/AppContext';

export default defineComponent({
  name: "App",
  data() {
    return {
      errorMessages: new Array<string>(),
    }
  },
  methods: {
    showError(message: string) {
      if (!this.errorMessages.includes(message)) {
        this.errorMessages.push(message)
        setTimeout(() => this.hideFirstError(), 10 * 1000)
      }
    },
    hideFirstError() {
      this.errorMessages.shift()
    }
  },
  created() {
    AppContext.getDataManager()
      .setErrorObserver((error: string) => this.showError(error))
  }
})
