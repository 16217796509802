import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19116ff6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-text-area" }
const _hoisted_2 = ["id", "value"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.readonly)
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 0,
          ref: "textarea",
          id: _ctx.elementId,
          class: "field",
          type: "text",
          value: _ctx.value,
          onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleOnFocus && _ctx.handleOnFocus(...args))),
          readonly: true
        }, null, 40, _hoisted_2))
      : _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 1,
          ref: "textarea",
          id: _ctx.elementId,
          class: "field",
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentValue) = $event)),
          onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleOnFocus && _ctx.handleOnFocus(...args))),
          onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleOnInput && _ctx.handleOnInput(...args))),
          onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleOnBlur && _ctx.handleOnBlur(...args)))
        }, null, 40, _hoisted_3)), [
          [_vModelText, _ctx.currentValue]
        ])
  ]))
}