import StoredEntry from "../StoredEntry"
import EntryHelper from "../EntryHelper"
import Property, { BoolProperty, StringProperty } from "../Property"

export default class Storage extends StoredEntry {
  static MODEL = 'i2ap'

  static VAL_NAME = 'c5tm'
  static VAL_TODELETE = 'a3yq'

  static PROPS: Property[] = StoredEntry.PROPS.concat([
    new StringProperty(this.VAL_NAME), new BoolProperty(this.VAL_TODELETE)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get name(): string {return this.getValue(Storage.VAL_NAME) }
  get toDelete(): boolean { return this.getValue(Storage.VAL_TODELETE) }

  static new(value: any): Storage {
    return EntryHelper.fillEntry(new Storage(), value)
  }
}