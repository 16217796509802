
import { defineComponent } from 'vue';
import UniversalDocsPage from "./UniversalDocsPage.vue";
import { TransferInventory, Storage } from "@/core/model/Types";
import * as Permission from '@/core/safe/Permissions'
import { DateUtils, TableUtils } from '@/lib/util/Utils';

export default defineComponent({
  components: { UniversalDocsPage },
  data() {
    return {
      docModel: TransferInventory.MODEL,
      tableHeaders: TableUtils.headers(m => [
        { key: 'date', name: 'Дата', width: m.DATE }, 
        { key: 'number', name: 'Номер', width: m.DOC_NUMBER },
        { key: 'sourceStorage', name: 'Из склада', width: m.flex(1) },
        { key: 'targetStorage', name: 'В склад', width: m.flex(1) },
      ]),
      loadConfig: [
        { name: 'id', key: TransferInventory.VAL_ID },
        { name: 'date', key: TransferInventory.VAL_DATE, handler: (v: Date) => DateUtils.formatedDate(v) },
        { name: 'number', key: TransferInventory.VAL_NUMBER },
        { name: 'sourceStorage', key: TransferInventory.VAL_SOURCE_STORAGE_ID, model: Storage.MODEL, handler: (v?: Storage) => v?.name },
        { name: 'targetStorage', key: TransferInventory.VAL_TARGET_STORAGE_ID, model: Storage.MODEL, handler: (v?: Storage) => v?.name },
        { name: 'toDelete', key: TransferInventory.VAL_TODELETE }
      ],
      editPermission: Permission.TRANSFER_INVENTORY_EDIT,
      dateKey: TransferInventory.VAL_DATE,
    }
  },
})
