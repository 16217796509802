
import { PropType, defineComponent } from 'vue';
import { UIPopupContent, UIReadTable } from "@/use/base/Types";
import DataRequest from '@/core/data/DataRequest';
import EntryLoader from '@/lib/data/EntryLoader';
import { DateUtils, NumberUtils, TableUtils } from '@/lib/util/Utils';

export default defineComponent({
  components: { UIPopupContent, UIReadTable },
  props: {
    links: {
      type: Array as PropType<any[]>,
      default: () => []
    },
  },
  data() {
    return {
      loader: new EntryLoader(),
      tableHeaders: TableUtils.headers(m => [
        { key: 'date', name: 'Дата', width: m.DATE }, 
        { key: 'name', name: 'Документ', width: m.fixed(500) },
        { key: 'amount', name: 'Кол-во', width: m.AMOUNT, alignment: 'right' },
        { key: 'sum', name: 'Сумма', width: m.CURRENCY, alignment: 'right' },
      ]),
      tableRows: new Array<any>(),
      isLoading: false,
    }
  },
  watch: {
    records() {
      this.reloadData()
    }
  },
  methods: {
    handleRowDoubleClick(row: any) {
      this.$sm.goToObjectEditor(row.docModel, row.docId, true)
    },
    reloadData() {

      this.isLoading = true

      const sorted = [ ...this.links ].sort((a, b) => a.date.getTime() - b.date.getTime())
      const modelIds: { [name: string]: any } = {}
      for (const link of sorted) {
        const arr = modelIds[link.docModel] || []
        arr.push(link.docId)
        modelIds[link.docModel] = arr
      }

      const request = new DataRequest()
      for (const model in modelIds) {
        request.addGetEntriesByIds(model, modelIds[model], model)
      }

      this.loader.load(request)
        .finally(() => {
          this.tableRows = sorted.map(link => {
            return {
              id: link.id,
              docModel: link.docModel,
              docId: link.docId,
              date: DateUtils.formatedDate(link.date),
              name: this.loader.data.getDisplayName(link.docModel, link.docId),
              amount: NumberUtils.formatedNumber(link.amount),
              sum: NumberUtils.formatedCurrency(link.sum)
            }
          })
          this.isLoading = false
        })
    }
  },
  created() {
    this.reloadData()
  }
})
