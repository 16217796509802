
import { defineComponent, nextTick } from 'vue';

export default defineComponent({
  props: { 
    elementId: String,
    value: {
      type: String,
      default: ''
    },
    alignment: String,
    readonly: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:value'],
  data() {
    return {
      currentValue: '',
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
      nextTick(() => this.resize())
    },
  },
  methods: {
    resize() {
      const textarea = this.$refs.textarea as HTMLElement
      // вначале установим базовую высоту для правильного перерасчета scrollHeight
      textarea.style.height = '40px' 
      // установим вусоту по значению scrollHeight
      textarea.style.height = Math.min(textarea.scrollHeight, 100) + 'px'
    },
    handleOnFocus() {
      this.resize()
    },
    handleOnInput() {
      this.resize()
    },
    handleOnBlur() {
      if (this.currentValue !== this.value) {
        this.$emit('update:value', this.currentValue);
      }
    },
  },
  mounted() {
    const textarea = this.$refs.textarea as HTMLElement
    textarea.style.textAlign = this.alignment || ''
    this.resize()
  },
  created() {
    this.currentValue = this.value || ''
  }
})
