import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleOverlayClick && _ctx.handleOverlayClick(...args)), ["self"]))
  }, [
    _createElementVNode("div", {
      class: "content",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleContentClick && _ctx.handleContentClick(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}