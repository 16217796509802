import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIBaseInput = _resolveComponent("UIBaseInput")!

  return (_openBlock(), _createBlock(_component_UIBaseInput, {
    "element-id": _ctx.elementId,
    readonly: _ctx.readonly,
    alignment: _ctx.alignment,
    value: _ctx.inputValue,
    "onUpdate:value": _ctx.outputValue,
    variants: _ctx.internalVariants,
    decorator: _ctx.decorator
  }, null, 8, ["element-id", "readonly", "alignment", "value", "onUpdate:value", "variants", "decorator"]))
}