
import { defineComponent } from 'vue';
import { UIImageButton } from '@/use/base/Types';

export default defineComponent({
  components: {
    UIImageButton
  },
  props: {
    value: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit('update:value', !this.value)
      }
    },
  }
})
