
import { defineComponent, ref, watch } from 'vue'
import UIBaseInput from './UIBaseInput.vue'

export default defineComponent({
  components: { UIBaseInput },
  props: { 
    elementId: String,
    fieldStyle: {
      type: String,
      default: 'bordered',
      validator: (value: string) => ['plain', 'bordered'].includes(value)
    },
    readonly: Boolean,
    alignment: String,
    value: Object,
    variants: Function,
    options: Object,
  },
  emits: ['update:value'],
  setup(props, { emit }) {

    const toInternalValue = (it: any) => (it && { id: it.id, text: it.name }) || {}
    const toExternalValue = (it: any) => (it && { id: it.id, name: it.text }) || {}

    const inputValue = ref(toInternalValue(props.value))

    watch(() => props.value, newValue => {
      inputValue.value = toInternalValue(newValue)
    })

    const outputValue = (newValue: any) => {
      inputValue.value = newValue
      emit('update:value', toExternalValue(newValue))
    }

    const getValueStyle = (value: any) => {
      if (value && value.text && !value.id) {
        const isCustomAvailable = (props.options) ? props.options['custom'] : false
        return (isCustomAvailable) ? 'blue' : 'red'
      } else {
        return ''
      }
    }

    const decorator = (value: any) => {
      const valueStyle = getValueStyle(value)
      if (props.fieldStyle === 'plain') {
        switch (valueStyle) {
          case 'red':
            return 'color: var(--content-red-color); border: 1px solid var(--content-red-color);'
          case 'blue':
            return 'color: var(--accent-color-dark); border: none;'
          default:
            return 'color: var(--content-primary-color); border: none;'
        }
      } else { // bordered
        switch (valueStyle) {
          case 'red':
            return 'color: var(--content-red-color); border: 1px solid var(--content-red-color); border-radius: 4px;'
          case 'blue':
            return 'color: var(--accent-color-dark); border: 1px solid var(--divider-color); border-radius: 4px;'
          default:
            return 'color: var(--content-primary-color); border: 1px solid var(--divider-color); border-radius: 4px;'
        }
      }
    }

    const internalVariants = (props.variants) ? (callback: any) => {
      props.variants!((vars: string[]) => {
        callback(vars.map(it => toInternalValue(it)))
      })
    } : undefined

    return {
      inputValue,
      outputValue,
      decorator,
      internalVariants,
    }
  }
})
