
import { defineComponent } from 'vue';
import UICloseButton from './UICloseButton.vue';

export default defineComponent({
  components: {
    UICloseButton
  },
  props: {
    title: {
      type: String,
      default: "[Title]"
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleCloseClick() {
      // TODO: Этот объект нельзя здесь вызывать, т.к. этот компонент не должен о нем знать
      this.$sm.goBack()
    },
  },
})
