
export const OPERATIONS = 'vl8y'

export const OPERATION_NAME = 'g4ip'
export const OPERATION_KEY = 'i4wt'
export const OPERATION_VALUE = 'q0ny'
export const OPERATION_GET_ENTRIES = 'pn4e'
export const OPERATION_PUT_ENTRIES = 'k3ub'
export const OPERATION_DEL_ENTRIES = 'b3mo'
export const OPERATION_GET_VALUE = 'b4wu'
export const OPERATION_SET_VALUE = 'o9qh'

export const QUERY_TYPE = 'tq6f'
export const QUERY_TYPE_ALL = 'r9qj'
export const QUERY_TYPE_IDS = 'r0xg'
export const QUERY_TYPE_CONDITION = 'dt6r'
export const QUERY_MODEL = 'b1na'
export const QUERY_KEY = 'j7qv';
export const QUERY_VALUE = 'qe2i'

export const SUCCESS = 'x91o'
export const RESULTS = 'az7q'
export const ERROR = 'm1vr'

export const DB_NAME = 'jt2t'
